import React from 'react'
import { NavBar } from '../../../components/navbar'

import firebase from '../../../config/firebase';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { getCliente, getEstacionamento, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact } from '../../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../../components/common/commons'
import { ComboBoxCollection } from '../../../components/common/commons'
import { sendClickButton, sendLog } from '../../../shared/analytics'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import * as XLSX from 'xlsx';
import { searchCEP } from '../../../shared/cepWorker';
import { changeDueDate, getAllPayedChangesByParkId, registerChagesSequences, removePendingChages } from '../../../store/collections/monthlyChagesWorker';
import { documentMask, exportAndDownloadPDF, exportAndDownloadXLS, goToNewWindow, goToWindow, isNullOrEmpty, isValidDocument, isValidEmail, isValidatePlate, normalizedField, reloadWindow, reloadWithAlert, removeSpecialCharacters, scrolltoTop, takeIfIsNotNullOrEmpty, toastWarning } from '../../../shared/utils';
import { getAllMonthlyPlans } from '../../../store/collections/monthlyPlans';
import { deleteVehicleById, getAllVehiclesByParkId, getAllVeiculosByClientId, getAllVeiculosOfMonthliesByParkId, getVehiclesByMonthlyIdAndClientId, updateVehicleById } from '../../../store/collections/veiculosWorker';
import { getAllMonthlyByParkId } from '../../../store/collections/mensalistaWorker';
import Tooltip from '@mui/material/Tooltip';
import { getParameterByParkId } from '../../../store/collections/parameterWorker';
import { addAccessCard, getAccessCardByParkIdAndNumber, getAllAccessCardsByParkId, getAllAccessCardsByReferenceId, removeAccessCard } from '../../../store/collections/accessCardWorker';
import { AlertInfo, AlertWarning } from '../../../shared/alert-manager';
import { MonthlyReportAccessCardsDocument, MonthlyReportCompletedDocument, MonthlyReportDocument } from '../../../shared/pdf/monthly-report';
import { UserPermissionButton } from '../../operation/permission';
import { docsToItems } from '../../../store/transform.docs';
import { addDoc, deleteDoc, updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';

const initialState = {
    id: null,
    nome: '',
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    userEmail: getUsuario()?.email,
    documento: undefined,
    tipoDocumento: 'CPF',
    email: undefined,
    celular: undefined,
    dataPrimeiraFatura: '',
    mostrarCamposOpcionais: false,
    quantityChanges: 12,
    telefone: '',
    planoId: '',
    planoNome: '',
    diaVencimento: '',
    validadeContrato: (new Moment(new Date())).add(2, 'Y'),
    dataFatura: new Moment(new Date()),
    placa: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    atualDiaVencimento: '',
    endereco: {},
    days: [],
    errorImport: [],
    importingStatus: 'await',
    importingMessage: 'Importar mensalistas',
    importModalIsOpen: false,
    clientsToImport: [],
    changes: [],
    currentImporting: null,
    maxWidth: "md",
    proRata: true
};

class CriarMensalista extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.load()
    }

    load = () => {
        var days = []
        for (var i = 1; i < 32; i++) {
            days.push(i)
        }
        this.setState({ days: days })
        var changes = []
        for (var x = 1; x < 24; x++) {
            changes.push(x)
        }
        this.setState({ changes: changes })
        this._loadParameters()
    }

    reset = () => {
        this.setState({ ...initialState })
        this.load()
    }

    _loadParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ habilitarCartaoParaAcesso: parameters.habilitarCartaoParaAcesso })
    }

    update = async () => {
        if (this.state.atualDiaVencimento !== this.state.diaVencimento) {
            var confirm = window.confirm('Deseja mesmo alterar o dia de vencimento deste cliente?\n\nTodas as cobranças pendentes serão alteradas para este novo dia.');
            if (!confirm) {
                return
            }
        }
        const data = {
            nome: this.state.nome,
            documento: documentMask(this.state.documento),
            tipoDocumento: this.state.tipoDocumento,
            email: this.state.email,
            celular: this.state.celular,
            telefone: this.state.telefone,
            status: this.state.status,
            proRata: Boolean(this.state.proRata),
            vagas: isNaN(parseInt(this.state.vagas)) ? 1 : parseInt(this.state.vagas),
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            alterAt: new Date(),
            alterBy: this.state.userEmail
        }
        await updateDoc({ collection: Collection.MONTHLIES, id: this.state.id, data: data })
        if (this.state.atualDiaVencimento !== this.state.diaVencimento) {
            await changeDueDate({ monthlyId: this.state.id, diaVencimento: this.state.diaVencimento })
        }
        await saveLastUpdate()
        sendClickButton("Mensalistas", "Atualizar")
        reloadWithAlert("Mensalista atualizado com sucesso!")
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    add = async (goToDetail) => {
        if (!this.checkFields(false)) {
            return
        }
        if (!(await this.verifyIfVehicleExist(this.state.placa))) {
            return
        }
        if (!(await this.verifyIfAccessCardExist(this.state.cardNumber))) {
            return
        }
        var data = {
            nome: this.state.nome,
            estacionamentoId: this.state.estacionamentoId,
            documento: documentMask(this.state.documento),
            tipoDocumento: this.state.tipoDocumento,
            proRata: this.state.proRata,
            clienteId: this.state.clienteId,
            email: this.state.email,
            celular: this.state.celular,
            telefone: this.state.telefone,
            status: this.state.status,
            revendaId: getRevendaIdByPark(),
            vagas: isNaN(parseInt(this.state.vagas)) ? 1 : parseInt(this.state.vagas),
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            plano: {
                validadeContrato: this.state.validadeContrato.toDate(),
                dataFatura: this.state.dataFatura.toDate(),
                diaVencimento: this.state.diaVencimento.toString(),
                horarioFim: this.state.horarioFim,
                horarioInicio: this.state.horarioInicio,
                planoId: this.state.planoId,
                nome: this.state.planoNome,
                valor: this.state.planoValor
            },
            createdAt: new Date(),
            createdBy: this.state.userEmail,
            isDeleted: false,
        }
        const doc = await addDoc({ collection: Collection.MONTHLIES, data: data })
        data.id = doc.id
        await this.addVehicle(doc.id, this.state.accessCard, this.state.placa)
        await this.addNewAccessCard({ mensalistaId: doc.id, accessCard: this.state.accessCard })
        await registerChagesSequences({ monthly: data, quantity: this.state.quantityChanges, plano: data.plano, proRata: data.proRata })
        await saveLastUpdate()
        sendClickButton("Mensalistas", "Cadastrar")
        if (goToDetail === true) {
            goToWindow("/visualizar/mensalista/" + doc.id)
        } else {
            reloadWithAlert("Mensalista cadastrado com sucesso!")
        }
    }

    verifyIfVehicleExist = async (placa) => {
        const result = await firebase.firestore().collection('veiculos').where('clienteId', '==', this.state.clienteId).where('placa', '==', placa?.toUpperCase().replace('-', '')).get()
        if (!result.empty) {
            toastWarning('Já existe um veiculo com esta placa cadastrado para este estacionamento.')
            return false
        }
        return true
    }

    verifyIfAccessCardExist = async (cardNumber) => {
        if (cardNumber && cardNumber !== "") {
            const accessCards = await getAccessCardByParkIdAndNumber({ estacionamentoId: this.state.estacionamentoId, accessCard: cardNumber })
            if (accessCards) {
                toastWarning('Já existe um veiculo com este cartão de acesso cadastrado para este estacionamento.')
                return false
            }
        }
        return true
    }

    addVehicle = async (mensalistaId, accessCard, placa, modelo, marca, cor, ano) => {
        const data = {
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            mensalistaId: mensalistaId,
            placa: placa.toUpperCase(),
            revendaId: getRevendaIdByPark(),
            modelo: modelo,
            marca: marca ?? "",
            cartao: (accessCard && accessCard !== "") ? String(accessCard) : null,
            ano: ano ?? "",
            cor: cor ?? "",
            createdAt: new Date(),
            createdBy: this.state.userEmail,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.VEHICLES, data: data })
    }

    addNewAccessCard = async ({ mensalistaId, accessCard }) => {
        if (!isNullOrEmpty(accessCard)) {
            await addAccessCard({
                clienteId: this.state.clienteId,
                estacionamentoId: this.state.estacionamentoId,
                referenceId: mensalistaId,
                type: "monthly",
                cardNumber: accessCard
            })
        }
    }

    onEdit = (item) => {
        this.setState({ random: Math.random() })
        this.setState({ id: item.id })
        this.setState({ nome: item.nome })
        this.setState({ documento: item.documento })
        this.setState({ estacionamentoId: item.estacionamentoId })
        this.setState({ tipoDocumento: item.tipoDocumento })
        this.setState({ celular: item.celular })
        this.setState({ telefone: item.telefone })
        this.setState({ email: item.email })
        this.setState({ status: item.status ?? "ativo" })
        this.setState({ vagas: isNaN(parseInt(item.vagas)) ? 1 : parseInt(item.vagas) })
        this.setState({ cep: item.endereco?.cep ?? "" })
        this.setState({ logradouro: item.endereco?.logradouro ?? "" })
        this.setState({ numero: item.endereco?.numero ?? "" })
        this.setState({ bairro: item.endereco?.bairro ?? "" })
        this.setState({ cidade: item.endereco?.cidade ?? "" })
        this.setState({ estado: item.endereco?.estado ?? "" })
        this.setState({ complemento: item.endereco?.complemento ?? "" })
        this.setState({ mostrarCamposOpcionais: (item.celular !== '' || item.endereco?.cep !== '') })
        scrolltoTop()
    }

    onDuplicate = (item) => {
        item.id = null;
        this.onEdit(item)
        toastWarning("Apenas os dados do mensalista são duplicados. É necessário cadastrar os veículos nos detalhes deste novo mensalista.")
    }

    onPlanoSelected = (item) => {
        this.setState({ planoNome: item.nome })
        this.setState({ planoId: item.id })
        this.setState({ planoValor: item.valorMensal })
        this.setState({ horarioFim: item.horarioFim })
        this.setState({ horarioInicio: item.horarioInicio })
    }

    checkFields = (isEditing) => {
        if (isEditing) {
            var allFilled = (this.state.nome === '' ||
                this.state.planoId === '' ||
                this.state.validadeContrato === '' ||
                this.state.diaVencimento === '' ||
                this.state.estacionamentoId === '')
            if (!allFilled) {
                return true
            }
            toastWarning("Os campos:\n\nNome do cliente;\nPlano;\nValidade contrato;\nDia de vencimento;\nPlaca do primeiro veículo.\n\nSão obrigatórios.")
            return false
        } else {
            var allFilled = (this.state.nome === '' ||
                this.state.planoId === '' ||
                this.state.validadeContrato === '' ||
                this.state.diaVencimento === '' ||
                this.state.placa === '' ||
                this.state.estacionamentoId === '')
            if (!allFilled) {
                if (isValidatePlate(this.state.placa)) {
                    return true
                }
                toastWarning("Informe uma placa válida, exemplo:\nPlaca Antiga: FGR4747\nPlaca Nova: AAA2A22")
                return false
            } else {
                toastWarning("Os campos:\n\nNome do cliente;\nPlano;\nValidade contrato;\nDia de vencimento;\nPlaca do primeiro veículo.\n\nSão obrigatórios.")
                return false
            }
        }
    }

    handleDataFaturatDateChange = (newValue) => {
        this.setState({ dataFatura: newValue })
    }

    handleValidadeContratoDateChange = (newValue) => {
        this.setState({ validadeContrato: newValue })
    }

    handleImportFileChange = (e) => {
        this.setState({ importingStatus: 'validating' })
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const prepareMonthliesF = this.prepareMonthlies
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const json = XLSX.utils.sheet_to_json(ws, { header: 2, cellDates: true });
            prepareMonthliesF(json)
        };
        reader.readAsBinaryString(f)
    }

    prepareMonthlies = async (json) => {
        if (json.length !== 0) {
            const firstRow = json[0]
            if (firstRow.NOME_PLANO === undefined ||
                firstRow.QUANTIDADE_COBRANCAS === undefined ||
                firstRow.PRIMEIRA_FATURA === undefined ||
                firstRow.PLACA === undefined ||
                firstRow.DIA_VENCIMENTO === undefined) {
                this.setState({ importingMessage: "Informe uma planilha válida com todos os dados obrigatórios preenchidos." })
                this.setState({ importingStatus: "empty" })
            } else {
                await this.validateJSONToImport(json)
            }
        } else {
            this.setState({ importingMessage: "Nenhum registro encontrado no arquivo selecionado." })
            this.setState({ importingStatus: "empty" })
        }
    }

    validateJSONToImport = async (json) => {
        var errosToImport = []
        this.setState({ importingMessage: "Verificando planos existentes..." })
        await this.validateMonthlie(json, errosToImport)
        this.setState({ errorImport: errosToImport })
        if (errosToImport.length > 0) {
            if (errosToImport.length === 1) {
                this.setState({ importingMessage: `Existe um alerta na planilha selecionada.` })
            } else {
                this.setState({ importingMessage: `Existem ${errosToImport.length} clientes com alertas na planilha selecionada.` })
            }
        }
    }

    validateMonthlie = async (json, errosToImport) => {
        const plans = await getAllMonthlyPlans({ estacionamentoId: this.state.estacionamentoId })
        const vehicles = await getAllVeiculosByClientId({ clientId: this.state.clienteId })

        var clientValidate = []
        for (const [index, item] of json.entries()) {
            var messages = []
            messages.push(this.verifyNames(item))
            const resultPlan = this.getPlans(item, plans)
            if (resultPlan.message !== undefined) {
                messages.push(resultPlan.message)
            }
            messages.push(this.verifySlots(item))
            messages.push(this.verifyVehicles(item, vehicles))
            messages.push(this.verifyAccessCards(item, vehicles))
            const resultDateValidate = this.verifyDates(item)
            for (let index = 0; index < resultDateValidate.length; index++) {
                const item = resultDateValidate[index];
                messages.push(item)
            }
            messages.push(this.verifyDocument(item))
            messages.push(this.verifyCellphone(item))
            messages.push(this.verifyPhone(item))
            messages.push(this.verifyAddress(item))
            messages.push(this.verifyEmail(item))
            const resultMessages = messages.filter(e => e !== '')
            if (resultMessages.length > 0) {
                errosToImport.push({
                    line: (index + 1),
                    client: item,
                    messages: resultMessages
                })
            } else {
                clientValidate.push({
                    ...item,
                    plano: resultPlan?.plano
                })
            }
        }
        this.setState({ clientsToImport: clientValidate })
        if (errosToImport.length > 0) {
            this.setState({ importingStatus: 'validating' })
            this.setState({ maxWidth: 'md' })
        } else {
            this.setState({ importingStatus: 'no-errors' })
            this.setState({ maxWidth: 'xl' })
            if (clientValidate.length === 1) {
                this.setState({ importingMessage: `Encontramos 1 mensalista para importação.` })
            } else {
                this.setState({ importingMessage: `Encontramos ${clientValidate.length} mensalistas para importação.` })
            }
        }
    }

    verifyNames = (item) => {
        if (!item.NOME_CLIENTE || item.NOME_CLIENTE === "") {
            return "Informe o nome do cliente;"
        }
        return ''
    }

    getPlans = (item, plans) => {
        var plans = plans.filter(f => f.nome.toUpperCase() === item.NOME_PLANO.toUpperCase())
        if (plans.length === 0) {
            return { message: `Plano "${item.NOME_PLANO}" não encontrado;` }
        }
        return { plano: plans[0] }
    }

    verifyVehicles = (item, vehicles) => {
        if (!isValidatePlate(item.PLACA)) {
            return `A placa ${item.PLACA} esta inválida;`
        } else if (vehicles.filter(f => f.placa.toUpperCase() === item.PLACA.toUpperCase()).length !== 0) {
            return `A placa ${item.PLACA} já esta associada a outro mensalista;`
        }
        return ''
    }

    verifyAccessCards = (item, vehicles) => {
        if (vehicles.filter(f => f.cartao && f.cartao === item.CARTAO_ACESSO_OPCIONAL).length !== 0) {
            return `O cartão de acesso ${item.CARTAO_ACESSO_OPCIONAL} já esta associado a outro mensalista;`
        }
        return ''
    }
    verifySlots = (item) => {
        if (!Number.isInteger(parseInt(item.VAGAS))) {
            return `Informe a quantidade de vagas para o mensalista;`
        }
        return ''
    }

    verifyAddress = (item) => {
        if (!isNullOrEmpty(item.CEP_OPCIONAL)) {
            const cep = String(item.CEP_OPCIONAL)?.replaceAll(" ", "").replaceAll("-", "").trim()
            if (cep === 'undefined' || cep === '' || cep.length !== 7) {
                return `Informe um CEP válido quando preenchido;`
            }
        }
        return ''
    }

    verifyDocument = (item) => {
        if (!isNullOrEmpty(item.DOCUMENTO_OPCIONAL)) {
            if (!isValidDocument(item.DOCUMENTO_OPCIONAL)) {
                return `Informe um documento válido quando preenchido;`
            }
        }
        return ''
    }

    verifyEmail = (item) => {
        if (!isNullOrEmpty(item.EMAIL_OPCIONAL)) {
            if (!isValidEmail(item.EMAIL_OPCIONAL)) {
                return `Informe um e-mail válido quando preenchido;`
            }
        }
        return ''
    }

    verifyCellphone = (item) => {
        if (!isNullOrEmpty(item.CELULAR_OPCIONAL)) {
            const cellphone = String(item.CELULAR_OPCIONAL).replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
            if (!Number.isInteger(parseInt(cellphone)) || cellphone.length < 10) {
                return `Informe um número de celular válido quando preenchido;`
            }
        }
        return ''
    }

    verifyPhone = (item) => {
        if (!isNullOrEmpty(item.TELEFONE_OPCIONAL)) {
            const phone = String(item.TELEFONE_OPCIONAL).replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
            if (!Number.isInteger(parseInt(phone)) || phone.length < 10) {
                return `Informe um número de telefone válido quando preenchido;`
            }
        }
        return ''
    }

    verifyDates = (item) => {
        var messages = []
        if (parseInt(item.QUANTIDADE_COBRANCAS) < 1) {
            messages.push(`A quantidade de cobranças deve ser maior que zero;`)
        }
        const dueDate = Moment(item.PRIMEIRA_FATURA, "DD/MM/YYYY")
        const dueDateValid = dueDate.isValid()
        if (!dueDateValid) {
            messages.push(`A data de vencimento da primeira fatura não esta correta;`)
        }
        if (dueDate.isBefore(new Date())) {
            messages.push(`A data da primeira fatura deve ser maior ou igual a data de hoje;`)
        }
        const day = parseInt(item.DIA_VENCIMENTO)
        if (isNaN(day)) {
            messages.push(`O dia de vencimento deve ser um número inteiro (1, 2, 3 e etc ) e não uma data ou um número real ( 1.1, 2.1, 3.2 e etc );`)
        }
        if (day <= 0 || day > 31) {
            messages.push(`O dia de vencimento não pode ser ${day};`)
        }
        return messages
    }

    importAnyway = async () => {
        this.setState({ importingStatus: 'no-errors' })
        this.setState({ maxWidth: 'xl' })
        const clientValidate = this.state.clientsToImport
        if (clientValidate.length === 1) {
            this.setState({ importingMessage: `1 mensalista para importação.` })
        } else {
            this.setState({ importingMessage: `${clientValidate.length} mensalistas para importação.` })
        }
    }

    forceImport = async () => {
        this.setState({ maxWidth: 'md' })
        this.setState({ importingStatus: "importing" })
        const clients = this.state.clientsToImport
        sendLog("Mensalistas", `Importando ${clients.length} mensalistas`)
        const messages = []
        var quantityImported = 0
        for (const [index, item] of clients.entries()) {
            this.setState({ importingMessage: `Importando ${index + 1} de ${clients.length} mensalistas` })
            this.setState({ currentImporting: item.NOME_CLIENTE })
            const result = await this.addImportedMonthly({ item: item })
            if (result.success) {
                quantityImported++
            } else {
                messages.push(result.message)
            }
        }
        if (messages.length === 0) {
            await saveLastUpdate()
            this.setState({ importingMessage: `${quantityImported} importados com sucesso` })
            this.setState({ importingStatus: 'success' })
            sendLog("Mensalistas", `${quantityImported} importados com sucesso`)
        } else {
            this.setState({ importingStatus: 'warnings' })
            this.setState({ importingWarnings: messages.join(", ") })
            sendLog("Mensalistas", `Alguns mensalistas não importados: ${messages.join(", ")}`)
        }
    }

    addImportedMonthly = async ({ item }) => {
        try {
            if (!(await this.verifyIfVehicleExist(item.PLACA))) {
                return {
                    success: false, message: `Já existe um mensalista cadastrado com a placa ${item.PLACA}`
                }
            }
            if (!(await this.verifyIfAccessCardExist(item.CARTAO_ACESSO_OPCIONAL))) {
                return {
                    success: false, message: `Já existe um mensalista cadastrado com o cartão de acesso ${item.CARTAO_ACESSO_OPCIONAL}`
                }
            }
            var address = {}
            if (item.CEP_OPCIONAL !== undefined || item.CEP_OPCIONAL !== 'undefined') {
                address = await searchCEP(String(item.CEP_OPCIONAL).padStart("8", "0"))
                if (address !== null) {
                    if (item.LOGRADOURO_OPCIONAL !== undefined) {
                        address.logradouro = item.LOGRADOURO_OPCIONAL
                    }
                    if (item.BAIRRO_OPCIONAL !== undefined) {
                        address.bairro = item.BAIRRO_OPCIONAL
                    }
                    if (item.CIDADE_OPCIONAL !== undefined) {
                        address.cidade = item.CIDADE_OPCIONAL
                    }
                    if (item.NUMERO_OPCIONAL !== undefined) {
                        address.numero = item.NUMERO_OPCIONAL
                    }
                    if (item.ESTADO_OPCIONAL !== undefined) {
                        address.estado = item.ESTADO_OPCIONAL
                    }
                    if (item.COMPLEMENTO_OPCIONAL !== undefined) {
                        address.complemento = item.COMPLEMENTO_OPCIONAL
                    }
                }
            }
            const data = {
                nome: item.NOME_CLIENTE,
                documento: documentMask(String(item.DOCUMENTO_OPCIONAL ?? "")),
                tipoDocumento: String(item.DOCUMENTO_OPCIONAL ?? "").replaceAll(".", '').replaceAll("-", '').replaceAll("/", '').length === 11 ? "CPF" : "CNPJ",
                status: "ativo",
                proRata: item.proRata,
                revendaId: getRevendaIdByPark(),
                email: item.EMAIL_OPCIONAL,
                celular: item.CELULAR_OPCIONAL === 'undefined' ? "" : String(item.CELULAR_OPCIONAL),
                vagas: parseInt(item.VAGAS ?? "1"),
                telefone: item.TELEFONE_OPCIONAL === 'undefined' ? "" : String(item.TELEFONE_OPCIONAL),
                estacionamentoId: this.state.estacionamentoId,
                clienteId: this.state.clienteId,
                endereco: address,
                plano: {
                    dataFatura: item.PRIMEIRA_FATURA,
                    diaVencimento: item.DIA_VENCIMENTO.toString(),
                    horarioFim: item.plano.horarioFim,
                    horarioInicio: item.plano.horarioInicio,
                    planoId: item.plano.id,
                    nome: item.plano.nome,
                    valor: parseFloat(item.plano.valorMensal)
                },
                createdAt: new Date(),
                createdBy: this.state.userEmail,
                isDeleted: false,
            }
            const doc = await addDoc({ collection: Collection.MONTHLIES, data: data })
            data.id = doc.id
            await registerChagesSequences({ monthly: data, quantity: parseInt(item.QUANTIDADE_COBRANCAS), plano: data.plano, proRata: true })
            if (item.CARTAO_ACESSO_OPCIONAL && item.CARTAO_ACESSO_OPCIONAL !== "") {
                await this.addNewAccessCard({ mensalistaId: doc.id, cardNumber: item.CARTAO_ACESSO_OPCIONAL })
            }
            await this.addVehicle(doc.id,
                item.CARTAO_ACESSO_OPCIONAL,
                item.PLACA,
                item.MODELO_OPCIONAL,
                item.MARCA_OPCIONAL,
                item.COR_OPCIONAL,
                item.ANO_OPCIONAL)
            return { success: true }
        } catch (error) {
            return { success: false, message: `Mensalista não cadastrado: ${item.NOME_CLIENTE}` }
        }
    }

    importAgain = () => {
        this.setState({ importingMessage: "Importar mensalistas" })
        this.setState({ importingStatus: 'await' })
        this.setState({ maxWidth: 'md' })
    }

    resetImport = () => {
        this.setState({ importModalIsOpen: false })
        sendClickButton("Mensalistas", "Fechou modal de importação de mensalistas por excel.")
    }

    closeImportModal = () => {
        reloadWindow()
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Cadastrar mensalista
                            <div className='btn-group'>
                                <button type="button" onClick={(e) => this.setState({ importModalIsOpen: true })} className="btn btn-sm btn-primary">
                                    <i className="fas fa-file-import mx-2" />
                                    Importar
                                </button>
                                <HelpButton />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-lg-2">
                                    <label>Status</label>
                                    {
                                        <select className="form-select" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} >
                                            <option value='ativo'>Ativo</option>
                                            <option value='inativo'>Inativo</option>
                                        </select>
                                    }
                                </div>
                                <div className="col-lg-2">
                                    <ComboBoxCollection key={this.state.estacionamentoId}
                                        id={this.state.planoId}
                                        title='Selecionar plano (*)'
                                        collection='planos'
                                        parentField='estacionamentoId'
                                        parentValue={this.state.estacionamentoId}
                                        onSelected={this.onPlanoSelected} />
                                </div>
                                <div className="col-lg-5">
                                    <label>Nome do cliente</label>
                                    <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                </div>
                                <div className="col-lg-3">
                                    <label>WhatsApp/Celular</label>
                                    <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                </div>
                                <div className="col-lg-2">
                                    <label>Vagas</label>
                                    {
                                        <select className="form-select" value={this.state.vagas} onChange={e => this.setState({ vagas: e.target.value })} >
                                            <option value='1'>1</option>
                                            {
                                                this.state.days.filter(e => e !== 1).map(day => <option key={day} value={day}>{day}</option>)
                                            }
                                        </select>
                                    }
                                </div>
                                {
                                    !this.state.id ?
                                        <>
                                            {
                                                this.state.mostrarCamposOpcionais === false &&
                                                <>
                                                    {
                                                        this.state.habilitarCartaoParaAcesso === true &&
                                                        <div className="col-lg-2">
                                                            <label>Cartão de acesso</label>
                                                            <Tooltip role="button" title="Opcional - Você pode usar para dar entrada no mensalista sem ter que digitar a placa." placement="top">
                                                                <i role='button' className="fas fa-info-circle mx-2" />
                                                            </Tooltip>
                                                            <input type="text" onChange={(e) => this.setState({ cardNumber: e.target.value })} value={this.state.cardNumber} className="form-control" />
                                                        </div>
                                                    }
                                                    <div className="col-lg-2">
                                                        <label>Placa ( 1º veículo )</label>
                                                        <input type="text" maxLength='7' onChange={(e) => this.setState({ placa: e.target.value })} value={this.state.placa?.toUpperCase()} className="form-control" />
                                                    </div>
                                                </>
                                            }
                                            < div className="col-lg-2">
                                                <label>Primeira fatura em</label>
                                                <div>
                                                    <MobileDatePicker
                                                        label="-"
                                                        inputFormat="DD/MM/yyyy"
                                                        value={this.state.dataFatura}
                                                        onChange={this.handleDataFaturatDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label>Cobranças</label>
                                                {
                                                    <select className="form-select" value={this.state.quantityChanges} onChange={e => this.setState({ quantityChanges: e.target.value })} >
                                                        <option value=''>Selecione</option>
                                                        {
                                                            this.state.changes.map(quantity => <option key={quantity} value={quantity}>{quantity}</option>)
                                                        }
                                                    </select>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                <label>Dia de vencimento</label>
                                                {
                                                    <select className="form-select" value={this.state.diaVencimento} onChange={e => this.setState({ diaVencimento: e.target.value })} >
                                                        <option value=''>Selecione</option>
                                                        {
                                                            this.state.days.map(day => <option key={day} value={day}>{day}</option>)
                                                        }
                                                    </select>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                <label>Tipo da primeira cobrança</label>
                                                    <select className="form-select" value={this.state.proRata} onChange={e => this.setState({ proRata: e.target.value === 'true' })} >
                                                        <option value="true">Cobrar valor proporcional</option>
                                                        <option value="false">Cobrar valor integral</option>
                                                    </select>
                                            </div>
                                        </> :
                                        <div className="col-lg-10">
                                            <div className="alert alert-secondary" role="alert">
                                                Edite os dados como placa do veículo, data de vencimento, dia de vencimento nos detalhes do mensalista.
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                this.state.mostrarCamposOpcionais === false &&
                                <div className='col-lg-3 col-md-8 col-sm-12'>
                                    <div className='my-3'>
                                        <button type="button" onClick={(e) => { this.setState({ mostrarCamposOpcionais: true }) }} className="btn btn-secondary btn-sm btn-block">
                                            Mostrar os campos opcionais
                                            <i className="fas fa-plus mx-3" />
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                this.state.mostrarCamposOpcionais === true &&
                                <div>
                                    <div className='row'>
                                        <div className="col-lg-3">
                                            <label>Tipo documento</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} >
                                                    <option value='CPF'>CPF</option>
                                                    <option value='CNPJ'>CNPJ</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <label>Número documento</label>
                                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                        </div>
                                        <div className="col-lg-2">
                                            <label>Telefone/Comercial</label>
                                            <NumberFormat className="form-control" value={this.state.telefone} mask='_' onChange={(e) => this.setState({ telefone: e.target.value })} format="(##)#####-####" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>E-mail</label>
                                            <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {
                                            this.state.mostrarCamposOpcionais === true && !this.state.id &&
                                            <>
                                                <div className='form-group'>
                                                    <h5>Primeiro veículo</h5>
                                                </div>
                                                {
                                                    this.state.habilitarCartaoParaAcesso === true &&
                                                    <div className="col-lg-2">
                                                        <label>Cartão de acesso</label>
                                                        <Tooltip role="button" title="Opcional - Você pode usar para dar entrada no mensalista sem ter que digitar a placa." placement="top">
                                                            <i role='button' className="fas fa-info-circle mx-2" />
                                                        </Tooltip>
                                                        <input type="text" onChange={(e) => this.setState({ accessCard: e.target.value })} value={this.state.accessCard} className="form-control" />
                                                    </div>
                                                }
                                                <div className="col-lg-2">
                                                    <label>Placa</label>
                                                    <input type="text" maxLength='7' onChange={(e) => this.setState({ placa: e.target.value })} value={this.state.placa?.toUpperCase()} className="form-control" />
                                                </div>
                                                <div className="col-lg-2">
                                                    <label>Modelo</label>
                                                    <input type="text" onChange={(e) => this.setState({ modelo: e.target.value })} value={this.state.modelo} className="form-control" />
                                                </div>
                                                <div className="col-lg-2">
                                                    <label>Marca</label>
                                                    <input type="text" onChange={(e) => this.setState({ marca: e.target.value })} value={this.state.marca} className="form-control" />
                                                </div>
                                                <div className="col-lg-2">
                                                    <label>Cor</label>
                                                    <select className='form-select' onChange={(e) => this.setState({ cor: e.target.value })} value={this.state.cor}>
                                                        <option value=''>Selecione</option>
                                                        <option value='Branco'>Branco</option>
                                                        <option value='Prata'>Prata</option>
                                                        <option value='Preto'>Preto</option>
                                                        <option value='Cinza'>Cinza</option>
                                                        <option value='Vermelho'>Vermelho</option>
                                                        <option value='Azul'>Azul</option>
                                                        <option value='Amarelo'>Amarelo</option>
                                                        <option value='Verde'>Verde</option>
                                                        <option value='Vinho'>Vinho</option>
                                                        <option value='Marrom'>Marrom</option>
                                                        <option value='Bege'>Bege</option>
                                                        <option value='Laranja'>Laranja</option>
                                                        <option value='Outros'>Outros</option>
                                                    </select>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className='form-group'>
                                        <h5 className='my-3'>Endereço</h5>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-3">
                                            <label>CEP</label>
                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Logradouro</label>
                                            <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Número</label>
                                            <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Bairro</label>
                                            <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Cidade</label>
                                            <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Estado</label>
                                            <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" />
                                        </div>
                                        <div className="col-lg-12">
                                            <label>Complemento</label>
                                            <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="card-footer">
                            <div className="btn-group">
                                {
                                    this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                                }
                                {
                                    this.state.id === null &&
                                    <button type="button" onClick={(e) => this.add(false)} className="btn btn-success">Cadastrar e criar outro mensalista</button>
                                }
                                <button type="button" onClick={(e) => { this.state.id ? this.update() : this.add(true) }} className="btn btn-primary">{this.state.id ? 'Atualizar informações' : 'Cadastrar e ver detalhes'}</button>
                            </div>
                        </div>
                    </div>
                    <Dialog maxWidth={this.state.maxWidth} fullWidth onClose={this.resetImport} open={this.state.importModalIsOpen}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.importingMessage}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.importingStatus === "importing" &&
                                <>
                                    <h4 className='text-center'>{this.state.currentImporting}...</h4>
                                    <h6 className='text-center my-3'>Isso pode levar alguns segundos.</h6>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.closeImportModal} className="btn btn-danger btn-sm my-3">
                                            Cancelar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === "success" &&
                                <>
                                    <h4 className='text-center'>Todos os mensalistas foram importados com sucesso!</h4>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.closeImportModal} className="btn btn-success btn-sm my-3">
                                            Fechar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === "warning" &&
                                <>
                                    <h6>Alguns mensalistas não foram importados motivo: <p /></h6>
                                    <span>{this.state.resultMessages}</span>
                                </>
                            }
                            {
                                this.state.importingStatus === "no-errors" &&
                                <div className='row'>
                                    <table className="table table-responsive table-striped">
                                        <tr>
                                            <td align='left'><strong>Plano</strong></td>
                                            <td><strong>Nome</strong></td>
                                            <td align='center'><strong>Vagas</strong></td>
                                            <td align='center'><strong>Placa</strong></td>
                                            <td align='center'><strong>Primeira fatura</strong></td>
                                            <td align='center'><strong>Cobranças</strong></td>
                                            <td align='center'><strong>Dia de vencimento</strong></td>
                                            <td align='center'><strong>Documento</strong></td>
                                            <td align='center'><strong>Celular</strong></td>
                                            <td align='center'><strong>Telefone</strong></td>
                                            <td align='center'><strong>CEP</strong></td>
                                            <td align='center'><strong>E-mail</strong></td>
                                        </tr>
                                        <tbody className='table-striped'>
                                            {
                                                this.state.clientsToImport.map((item, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{item.NOME_PLANO ?? "-"}</td>
                                                        <td>{item.NOME_CLIENTE ?? "-"}</td>
                                                        <td align='center'>{item.VAGAS ?? "-"}</td>
                                                        <td align='center'>{item.PLACA ?? "-"}</td>
                                                        <td align='center'>{(item.PRIMEIRA_FATURA === undefined || item.PRIMEIRA_FATURA === "") ? "-" : Moment(item.PRIMEIRA_FATURA).format("DD/MM/YYYY")}</td>
                                                        <td align='center'>{(item.QUANTIDADE_COBRANCAS === undefined || item.QUANTIDADE_COBRANCAS === "") ? "-" : item.QUANTIDADE_COBRANCAS} </td>
                                                        <td align='center'>{item.DIA_VENCIMENTO ?? "-"}</td>
                                                        <td align='center'>{item.DOCUMENTO_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.CELULAR_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.TELEFONE_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.CEP_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.EMAIL_OPCIONAL ?? "-"}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div className="btn-group">
                                            <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                                <i className="fas fa-long-arrow-left mx-2" />
                                                Voltar
                                            </button>
                                            {
                                                this.state.clientsToImport.length > 0 && <>
                                                    <button type="button" onClick={this.forceImport} className="btn btn-sm btn-warning text-white">
                                                        <i className="fas fa-file-import mx-2" />
                                                        {
                                                            this.state.clientsToImport.length === 1 && <span>Importar 1 cliente</span>
                                                        }
                                                        {
                                                            this.state.clientsToImport.length > 1 && <span>Importar os {this.state.clientsToImport.length} clientes</span>
                                                        }
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.importingStatus === "validating" &&
                                <>
                                    {
                                        this.state.errorImport.length === 0 && <Loading />
                                    }
                                    {
                                        this.state.errorImport.length > 0 &&
                                        <>
                                            <table className="table table-responsive">
                                                <tbody className='table-striped'>
                                                    <tr>
                                                        <td align='center'><strong>Linha</strong></td>
                                                        <td><strong>Cliente</strong></td>
                                                        <td width={400}><strong>Mensagem</strong></td>
                                                    </tr>
                                                    {
                                                        this.state.errorImport.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='center'>{item.line}</td>
                                                                <td>{(item.client.NOME_CLIENTE === undefined || item.client.NOME_CLIENTE === "") ? "-" : item.client.NOME_CLIENTE}</td>
                                                                <td>{item.messages.map(e => <><span>{e}</span> <br /></>)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='row'>
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <div className="btn-group">
                                                        <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                                            <i className="fas fa-long-arrow-left mx-2" />
                                                            Voltar
                                                        </button>
                                                        {
                                                            this.state.clientsToImport.length > 0 && <>
                                                                <button type="button" onClick={this.importAnyway} className="btn btn-sm btn-warning text-white">
                                                                    <i className="fas fa-file-import mx-2" />
                                                                    {
                                                                        this.state.clientsToImport.length === 1 && <span>Importar mesmo assim 1 cliente correto</span>
                                                                    }
                                                                    {
                                                                        this.state.clientsToImport.length > 1 && <span>Importar mesmo assim os {this.state.clientsToImport.length} clientes corretos</span>
                                                                    }
                                                                </button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {
                                this.state.importingStatus === "empty" &&
                                <>
                                    <div className="alert alert-secondary text-center" role="alert">
                                        {this.state.importingMessage}
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                            <i className="fas fa-long-arrow-left mx-2" />
                                            Voltar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === 'await' &&
                                <div>
                                    <div className="alert alert-secondary" role="alert">
                                        Antes de começarmos você deve baixar a nossa planilha de exemplo e <br /> preencher com os dados dos seus mensalistas. <p />
                                        <strong><a href="https://firebasestorage.googleapis.com/v0/b/selfparking-prod-26925.appspot.com/o/files%2Fothers%2Fimportar_mensalistas_exemplo.xlsx?alt=media&token=3c986432-a8ae-4c18-9fb3-deddbf916c29" target="_blank" download>Baixar planilha de exemplo</a></strong>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formFile" className="form-label">Selecionar arquivo</label>
                                        <div>
                                            <input type="file" accept=".xlsx, .xls" onChange={this.handleImportFileChange} id="formFile" />
                                        </div>
                                    </div>
                                    {/* <FileUploader handleChange={this.handleDataFaturatDateChange} name="file" types={["xlsx"]} /> */}
                                </div>
                            }
                        </MuiDialogContent>
                    </Dialog>
                    <BuscarMensalistas key={this.state.loading} onEdit={this.onEdit} onDuplicate={this.onDuplicate} showDetail={true} />
                </NavBar >
            </>
        )
    }
}

var inputs = []

class BuscarMensalistas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            list: [],
            auxList: [],
            titleSearch: "Mensalistas encontrados",
            documento: '',
            nome: '',
            loading: 0
        }
    }

    componentDidMount = () => {
        this.load()
        this._loadParameters()
    }

    _loadParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ habilitarCartaoParaAcesso: parameters.habilitarCartaoParaAcesso })
    }

    onEdit = (item) => {
        goToNewWindow(`/visualizar/mensalista/${item.id}?edit=true`)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onRemove = async (item) => {
        var message = `Removendo o mensalista: ${item.nome}\n\nTodas as cobranças pendentes serão removidas.`
        console.log(message);
        const cards = await getAllAccessCardsByReferenceId({ estacionamentoId: this.state.estacionamentoId, referenceId: item.id })
        if (cards.length > 0) {
            message += `\n\nOs cartões de acesso associados serão removidos: \n\n${cards.map(e => e.cardNumber).join(", ")}.`
        }
        const vehicles = await getVehiclesByMonthlyIdAndClientId({ monthlyId: item.id, clienteId: this.state.clienteId })
        if (vehicles.length > 0) {
            message += `\n\nOs veículos associados serão removidos: \n\n${vehicles.map(e => e.placa).join(", ")}.`
        }
        var confirm = window.confirm(message)
        if (confirm) {
            for (const vehicle of vehicles) {
                await deleteVehicleById({ id: vehicle.id })
            }
            for (const card of cards) {
                await removeAccessCard({ id: card.id })
            }
            await this.removeMonthly(item)
        }
    }

    removeMonthly = async (item) => {
        await removePendingChages({ monthlyId: item.id })
        await deleteDoc({ collection: "mensalistas", id: item.id })
        await saveLastUpdate()
        sendClickButton("Mensalistas", `O operador ${getUsuario()?.nome} acabou de remover o mensalista ${item.nome}`)
        const items = this.state.list.filter(e => e.id !== item.id)
        this.setState({ list: items })
        this.setState({ auxList: items })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".").replaceAll("-").replaceAll("/").replaceAll("(").replaceAll(")").replaceAll("-")
        if (value === "") {
            this.setState({ list: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(e => e.nome.toUpperCase().includes(value) ||
                e.documento?.toUpperCase().replaceAll(".").replaceAll("-").replaceAll("/").includes(value) ||
                e.celular?.toUpperCase().replaceAll("(").replaceAll(")").replaceAll("-").includes(value) ||
                e.email?.toUpperCase().includes(value) ||
                e.plano?.nome?.toUpperCase().includes(value))
            this.setState({ list: result })
        }
    }

    load = async () => {
        this.setState({ loading: 1 })
        const monthlies = await getAllMonthlyByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (monthlies.length !== 0) {
            this.setState({ list: monthlies.sort((a, b) => a.nome.localeCompare(b.nome)) })
            this.setState({ auxList: monthlies.sort((a, b) => a.nome.localeCompare(b.nome)) })
        } else {
            this.setState({ titleSearch: "Cadastre seus mensalistas" });
        }
        this.setState({ loading: 0 })
    }

    showAsociateAccessCardModal = async () => {
        this.setState({ importingStatus: "type" })
        this.setState({ importingMessage: "" })
        this.setState({ associateAccessCardModalIsOpen: true })
    }

    hideAsociateAccessCardModal = () => {
        if (this.state.importingStatus === "type") {
            this.setState({ associateAccessCardModalIsOpen: false })
        }
    }

    changeToImportByMonthlies = () => {
        inputs = []
        this.changeImportingStatus("monthlies")
        this.loadAllMonthlies()
    }

    changeToImportByCars = () => {
        inputs = []
        this.changeImportingStatus("cars")
        this.loadAllCars()
    }

    changeImportingStatus = (status) => {
        this.setState({ importingStatus: status })
    }

    loadAllMonthlies = () => {
        this.setState({ items: this.state.list })
    }

    saveAccessCardsInBatch = async () => {
        const items = this.state.items.filter(e => e.cardNumber)
        if (items.length === 0) {
            toastWarning("Preencha ao menos um cartão de acesso.")
            return
        }
        this.changeImportingStatus("importing")
        for (const item of this.state.items) {
            if (item.cardNumber) {
                const card = await getAccessCardByParkIdAndNumber({ estacionamentoId: this.state.estacionamentoId, accessCard: item.cardNumber })
                if (!card) {
                    //Mensalista
                    await addAccessCard({
                        clienteId: this.state.clienteId,
                        estacionamentoId: this.state.estacionamentoId,
                        referenceId: item.id,
                        type: "monthly",
                        cardNumber: item.cardNumber
                    })
                    if (item.placa) {
                        this.setState({ importingMessage: `Salvando cartão de acesso para o veículo ${item.placa} do mensalista\n${item.nome}` })
                        //Veiculo
                        const data = {
                            cartao: String(item.cardNumber),
                            alterAt: new Date(),
                            alterBy: getUsuario().email
                        }
                        await updateVehicleById({ id: item.vehicleId, data: data })
                    } else {
                        this.setState({ importingMessage: `Salvando cartão de acesso para o mensalista\n${item.nome}` })
                    }
                }
            }
        }
        reloadWithAlert("Cartões de acesso associados com sucesso.")
    }

    loadAllCars = async () => {
        this.changeImportingStatus("loading")
        var vehicles = await getAllVeiculosOfMonthliesByParkId({ estacionamentoId: this.state.estacionamentoId })
        var items = []
        for (const vehicle of vehicles) {
            const monthly = this.state.list.filter(e => e.id === vehicle.mensalistaId)[0]
            items.push({
                id: monthly.id,
                vehicleId: vehicle.id,
                nome: monthly.nome,
                documento: monthly.documento,
                placa: vehicle.placa,
                cartao: vehicle.cartao
            })
        }
        this.setState({ items: items.sort((a, b) => a.nome.localeCompare(b.nome)) })
        this.changeImportingStatus("cars")
    }

    registerInputReference = (index, input) => {
        inputs[index] = input
    }

    changeCardNumberValue = (e, index) => {
        this.state.items[index].cardNumber = e.target.value
    }

    verifyEnterAfterFillAccessCard = (event, index) => {
        if (event.key === 'Enter') {
            const value = event.target.value
            this.state.items[index].cardNumber = value
            if (inputs.length > (index + 1)) {
                inputs[(index + 1)].focus()
            } else {
                toastWarning("Campos finalizados.")
            }
        }
    }

    render() {
        return (
            <>
                <div className="col-lg-12 card my-3">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todos os mensalistas
                        <div className='btn-group'>
                            {
                                this.state.list.length !== 0 && this.state.habilitarCartaoParaAcesso &&
                                <button type="button" onClick={this.showAsociateAccessCardModal} className="btn btn-sm btn-secondary">
                                    <i className="fas fa-id-card mx-2" />
                                    Associar cartões
                                </button>
                            }
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='col-lg-12'>
                            <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, celular, email, vencimento ou plano..." className="form-control" />
                        </div>
                    </div>
                    {
                        this.state.list.length === 0 && this.state.loading === 0 ?
                            <div className="card-body">
                                <EmptyContent text={this.state.titleSearch} />
                            </div>
                            :
                            this.state.loading === 0 ?
                                <>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td><strong>Nome</strong></td>
                                                <td align='center' className="d-none d-sm-table-cell"><strong>Vagas</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Celular</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Documento</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Plano</strong></td>
                                                <td width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody className='table-responsive'>
                                            {
                                                this.state.list.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Tooltip title={item.id} placement="top">
                                                                <span>{item.nome}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td align='center' className="d-none d-sm-table-cell">{item.vagas ?? 1}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{!item.celular || item.celular === "" ? "-" : item.celular}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{!item.documento || item.documento === "" ? "-" : item.documento}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{item?.plano?.nome === undefined ? "Não informado" : item.plano.nome}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <Tooltip role="button" title="Duplicar mensalista" placement="top">
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                </Tooltip>
                                                                <Tooltip role="button" title="Visualizar mensalista" placement="top">
                                                                    <Link to={'/visualizar/mensalista/' + item.id} className="btn btn-primary">
                                                                        <i className="fas fa-eye" />
                                                                    </Link>
                                                                </Tooltip>
                                                                <UserPermissionButton className="btn btn-secondary" permission="MONTHLY_EDIT" action={() => { this.onEdit(item) }}>
                                                                    <Tooltip role="button" title="Editar mensalista" placement="top">
                                                                        <i className="fas fa-edit" />
                                                                    </Tooltip>
                                                                </UserPermissionButton>
                                                                <UserPermissionButton className="btn btn-danger" permission="MONTHLY_REMOVE" action={() => { this.onRemove(item) }}>
                                                                    <Tooltip role="button" title="Apagar mensalista" placement="top">
                                                                        <i className="fas fa-trash" />
                                                                    </Tooltip>
                                                                </UserPermissionButton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </>
                                :

                                <div className="card-body">
                                    <Loading />
                                </div>
                    }
                </div>
                <Dialog maxWidth={(this.state.importingStatus === "type" || this.state.importingStatus === "loading") ? "xs" : "sm"} fullWidth onClose={this.hideAsociateAccessCardModal} open={this.state.associateAccessCardModalIsOpen}>
                    {
                        this.state.importingStatus === "loading" && <>
                            <Loading />
                        </>
                    }
                    {
                        this.state.importingStatus === "type" &&
                        <MuiDialogContent>
                            <AlertInfo message="Você pode associar cartões de acessos direto para o mensalista ou para um veículo de mensalista." />
                            <button type="button" className="btn btn-secondary w-100" onClick={this.changeToImportByMonthlies}>
                                Associar por mensalistas
                                <i className="fas fa-arrow-right mx-3" />
                            </button>
                            <button type="button" className="btn btn-secondary w-100 my-3" onClick={this.changeToImportByCars}>
                                Associar por veículos
                                <i className="fas fa-arrow-right mx-3" />
                            </button>
                        </MuiDialogContent>
                    }
                    {
                        this.state.importingStatus === "monthlies" &&
                        <>
                            <MuiDialogContent>
                                <div className="card-header d-flex align-items-center ">
                                    <button type="button" onClick={() => { this.changeImportingStatus("type") }} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-chevron-left mx-2" />
                                    </button>
                                    <h5 className='mt-1 mx-2'>Associar cartões de acesso por mensalistas</h5>
                                </div>
                                <div>
                                    <div className='mt-3'>
                                        <AlertWarning message="Nesta modalidade, o mensalista poderá entrar com qualquer veículo apenas com o cartão de acesso." />
                                    </div>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td><strong>Nome</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Documento</strong></td>
                                                <td align='center' width={200}><strong>Cartão</strong></td>
                                                <td align='center' width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody className='table-responsive'>
                                            {
                                                this.state.items.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Tooltip title={item.id} placement="top">
                                                                <span>{item.nome}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{!item.documento || item.documento === "" ? "-" : item.documento}</td>
                                                        <td align='center' width={200}>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.changeCardNumberValue(e, index)}
                                                                ref={(input) => this.registerInputReference(index, input)}
                                                                onKeyPress={(e) => this.verifyEnterAfterFillAccessCard(e, index)} />
                                                        </td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <Tooltip role="button" title="Visualizar mensalista" placement="top">
                                                                    <Link to={'/visualizar/mensalista/' + item.id} target="_blank" className="btn btn-primary">
                                                                        <i className="fas fa-eye" />
                                                                    </Link>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3'>
                                    <button type="button" className="btn btn-secondary" onClick={this.saveAccessCardsInBatch}>
                                        Salvar associações
                                    </button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                    {
                        this.state.importingStatus === "cars" &&
                        <>
                            <MuiDialogContent>
                                <div className="card-header d-flex align-items-center ">
                                    <button type="button" onClick={() => { this.changeImportingStatus("type") }} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-chevron-left mx-2" />
                                    </button>
                                    <h5 className='mt-1 mx-2'>Associar cartões de acesso por veículos</h5>
                                </div>
                                <div>
                                    <div className='mt-3'>
                                        <AlertWarning message="Cada cartão de acesso será associado a um veículo do mensalista." />
                                    </div>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td><strong>Nome</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Documento</strong></td>
                                                <td align='center'><strong>Placa</strong></td>
                                                <td align='center' width={200}><strong>Cartão</strong></td>
                                                <td align='center' width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody className='table-responsive'>
                                            {
                                                this.state.items.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Tooltip title={item.id} placement="top">
                                                                <span>{item.nome}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{!item.documento || item.documento === "" ? "-" : item.documento}</td>
                                                        <td align='center'>{item.placa}</td>
                                                        <td width={200}>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.changeCardNumberValue(e, index)}
                                                                ref={(input) => this.registerInputReference(index, input)}
                                                                onKeyPress={(e) => this.verifyEnterAfterFillAccessCard(e, index)} />
                                                            {
                                                                item.cartao &&
                                                                <small>Atual: {item.cartao}</small>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <Tooltip role="button" title="Visualizar mensalista" placement="top">
                                                                    <Link to={'/visualizar/mensalista/' + item.id} target="_blank" className="btn btn-primary">
                                                                        <i className="fas fa-eye" />
                                                                    </Link>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3'>
                                    <button type="button" className="btn btn-secondary" onClick={this.saveAccessCardsInBatch}>
                                        Salvar associações
                                    </button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                    {
                        this.state.importingStatus === "importing" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Associando...
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <Loading />
                                <h5 className='text-center'>{this.state.importingMessage}</h5>
                            </MuiDialogContent>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}

class ListarMensalistas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            list: [],
            auxList: [],
            exportFieldStatus: true,
            exportFieldName: true,
            exportFieldCellphone: true,
            exportFieldPlan: true,
            exportFieldPrice: true,
            exportFieldSlots: true,
            exportFieldDueDate: true,
            exportFieldDetail: true,
            exportFieldDelimiter: ","
        }
    }

    componentDidMount = () => {
        this.setState({ list: this.props.monthlies })
        this.setState({ auxList: this.props.monthlies })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ list: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(e => e.nome.toUpperCase().includes(value) ||
                e.documento?.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").includes(value) ||
                e.celular?.toUpperCase().replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").includes(value) ||
                `${e.endereco?.cep?.replaceAll("-", "").toUpperCase() ?? ""}, ${e.endereco?.logradouro?.toUpperCase() ?? ""}, ${e.endereco?.numero?.toUpperCase() ?? ""}`.includes(value) ||
                e.status?.toUpperCase().includes(value))
            this.setState({ list: result })
        }
    }

    showExportMonthlyAsXLS = () => {
        this.setState({ isShowingExportReportAsXLSModal: true })
    }

    showExportMonthlyAsPDF = () => {
        this.setState({ isShowingExportReportAsPDFModal: true })
    }

    exportAsXLSAllMonthly = () => {
        const revenda = getRevenda()
        const rows = this.state.list.map(e => {
            var data = {}
            if (this.state.exportFieldStatus) {
                data["Status"] = removeSpecialCharacters(e.plano?.dataFatura ? Moment(e.plano.dataFatura.seconds * 1000).isAfter(new Date()) ? "Em dia" : "Pendente" : "Expirado")
            }
            if (this.state.exportFieldName) {
                data["Nome"] = removeSpecialCharacters(e.nome)
            }
            if (this.state.exportFieldCellphone) {
                data["Celular"] = removeSpecialCharacters(e.celular)
            }
            if (this.state.exportFieldPlan) {
                data["Plano"] = removeSpecialCharacters(e.plano?.nome ?? "-")
            }
            if (this.state.exportFieldPrice) {
                data["Valor do plano"] = removeSpecialCharacters(e.plano?.valor)
            }
            if (this.state.exportFieldSlots) {
                data["Vagas"] = removeSpecialCharacters(e.vagas ?? "1")
            }
            if (this.state.exportFieldDueDate) {
                data["Vencimento"] = removeSpecialCharacters(Moment(e.plano?.dataFatura.toDate()).format("DD/MM/YYYY"))
            }
            if (this.state.exportFieldDetail) {
                data["Detalhes"] = removeSpecialCharacters(`${revenda.siteAdmin}/visualizar/mensalista/${e.id}`)
            }
            return data
        })
        exportAndDownloadXLS(rows, `MENSALISTAS_EXPORTADOS`, this.state.exportFieldDelimiter)
        const user = getUsuarioCompact()
        const message = `O usuário ${user.nome} exportou em XLS um relatório do caixa em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}.`
        sendClickButton("Buscar", message, null, JSON.stringify(rows))
        this.setState({ isShowingExportReportModal: false })
    }

    exportAsPDFAllMonthly = async (e) => {
        const user = getUsuarioCompact()
        const message = `O usuário ${user.nome} exportou um relatório resumido dos mensalistas em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}.`
        sendLog("Busca", message, true)
        e.preventDefault();
        const doc = <MonthlyReportDocument
            mensalistas={this.state.auxList}
        />
        await exportAndDownloadPDF(doc, 'relatorio_de_mensalistas_resumido.pdf')
        this.setState({ isShowingExportReportModal: false })
    }

    exportAsPDFAllCardsMonthly = async (e) => {
        const accessCards = await getAllAccessCardsByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (accessCards.length > 0) {
            this.setState({ isLoadingCompletedReport: true })

            const vehicles = await getAllVehiclesByParkId({ estacionamentoId: this.state.estacionamentoId })

            const doc = <MonthlyReportAccessCardsDocument
                monthlies={this.state.auxList}
                accessCards={accessCards}
                vehicles={vehicles}
            />
            await exportAndDownloadPDF(doc, 'relatorio_de_cartoes_de_acesso.pdf')

            const user = getUsuarioCompact()
            const message = `O usuário ${user.nome} exportou um relatório do caixa em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}.`
            sendLog("Busca", message, true)
            e.preventDefault()

            this.setState({ isLoadingCompletedReport: false })
            this.setState({ isShowingExportReportAsPDFModal: false })
        } else {
            toastWarning("Este estabelecimento não tem cartões de acessos cadastrados.")
        }
    }

    exportAsPDFCompletedAllMonthly = async (e) => {
        //ASsocia os campos corretos nos veículos
        // const vehicles = await getAllVehiclesByClientId({ clienteId: this.state.clienteId })
        // for (var vehicle of vehicles.filter(e => e.mensalistaId)) {
        //     vehicle.estacionamentoId = this.state.estacionamentoId
        //     vehicle.clienteId = this.state.clienteId
        //     console.log("Atualizando", vehicle)
        //     await updateVehicleById({ id: vehicle.id, data: vehicle })
        // }

        this.setState({ isLoadingCompletedReport: true })
        const user = getUsuarioCompact()
        const changes = await getAllPayedChangesByParkId({ estacionamentoId: this.state.estacionamentoId })
        const vehicles = await getAllVehiclesByParkId({ estacionamentoId: this.state.estacionamentoId })

        const doc = <MonthlyReportCompletedDocument
            mensalistas={this.state.auxList}
            changes={changes}
            vehicles={vehicles}
        />
        await exportAndDownloadPDF(doc, 'relatorio_de_mensalistas_completo.pdf')

        const message = `O usuário ${user.nome} exportou um relatório completo de mensalistas em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}.`
        sendLog("Busca", message, true)
        e.preventDefault()

        this.setState({ isLoadingCompletedReport: false })
        this.setState({ isShowingExportReportAsPDFModal: false })
    }

    render() {
        return (
            <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <span className='mx-2'>Mensalistas </span>
                    <div className='btn-group'>
                        <button onClick={this.showExportMonthlyAsPDF} className='btn btn-sm btn-secondary'>
                            <i className="fas fa-print mx-2" />
                            Imprimir
                        </button>
                        <button type="button" onClick={this.showExportMonthlyAsXLS} className="btn btn-sm btn-success">
                            <i className="fas fa-table mx-2" />
                            Exportar
                        </button>
                        <HelpButton />
                    </div>
                </div>
                <div className='card-body'>
                    <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, celular, placa, setores ou tipos..." className="form-control" />
                </div>
                {
                    (this.state.list.length === 0) ?
                        <div className='card-body'>
                            <EmptyContent text="Nenhum mensalista encontrado com esta busca" />
                        </div>
                        :
                        <div className='row'>
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td width={10}><strong>Status</strong></td>
                                            <td><strong>Nome</strong></td>
                                            <td className="d-none d-sm-table-cell" scope='coll' align='center'><strong>Vagas</strong></td>
                                            <td className="d-none d-sm-table-cell" scope='coll'><strong>Plano</strong></td>
                                            <td className="d-none d-sm-table-cell" scope='coll' align='center'><strong>Vencimento</strong></td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.list.map((item, index) =>
                                                <tr key={index}>
                                                    {
                                                        !item.plano ? <td align='center'><span className="badge text-bg-secondary w-100">Sem plano</span> </td> :
                                                            <>
                                                                {
                                                                    item.plano.dataFatura ? <>
                                                                        {
                                                                            Moment(item.plano.dataFatura.seconds * 1000).isAfter(new Date()) ?
                                                                                <td align='center'><span className="badge text-bg-success w-100">Em dia</span> </td> :
                                                                                <td align='center'><span className="badge text-bg-danger w-100">Atrasado</span></td>
                                                                        }
                                                                    </> :
                                                                        <td align='center'><span className="badge text-bg-secondary w-100">Sem cobrança</span></td>
                                                                }
                                                            </>
                                                    }
                                                    <td>{item.nome}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{item.vagas ?? 1}</td>
                                                    <td className="d-none d-sm-table-cell" >{item.plano?.nome ?? "Sem plano"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center' >
                                                        {
                                                            !item.plano ? <span>-</span> :
                                                                <>
                                                                    {
                                                                        item.plano?.dataFatura ?
                                                                            <span>{Moment(item.plano.dataFatura.toDate()).format("DD/MM/YYYY")}</span> :
                                                                            <span>Sem cobranças</span>
                                                                    }
                                                                </>
                                                        }
                                                    </td>
                                                    {
                                                        <td width='10'>
                                                            <Link to={'/visualizar/mensalista/' + item.id} target="_blank">
                                                                <button type="button" className="btn btn-primary">
                                                                    <i className="fas fa-eye" />
                                                                </button>
                                                            </Link>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
                <Dialog fullWidth maxWidth='xs' open={this.state.isShowingExportReportAsPDFModal}>
                    {
                        !this.state.isLoadingCompletedReport &&
                        <MuiDialogTitle className='text-center'>
                            Exportar relatório
                        </MuiDialogTitle>
                    }
                    <MuiDialogContent>
                        {
                            this.state.isLoadingCompletedReport && <div className='text-center'>
                                <h5>Estamos gerando o seu relatório, isso pode levar até 1 minuto dependendo da quantidade de mensalistas.</h5>
                                <Loading />
                            </div>
                        }
                        {
                            !this.state.isLoadingCompletedReport && <div>
                                <AlertWarning message="Escolha o relatório que deseja exportar." />
                                <button type="button" className="btn btn-secondary w-100" onClick={this.exportAsPDFAllMonthly}>
                                    Relatório resumido
                                </button>
                                <button type="button" className="btn btn-secondary w-100 mt-3" onClick={this.exportAsPDFCompletedAllMonthly}>
                                    Relatório completo
                                </button>
                                <button type="button" className="btn btn-secondary w-100 mt-3" onClick={this.exportAsPDFAllCardsMonthly}>
                                    Cartões associados
                                </button>
                            </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            {
                                !this.state.isLoadingCompletedReport &&
                                <button onClick={(e) => this.setState({ isShowingExportReportAsPDFModal: false })} className='btn btn-secondary'>Cancelar</button>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>

                <Dialog fullWidth maxWidth='xs' open={this.state.isShowingExportReportAsXLSModal}>
                    <MuiDialogTitle className='text-center'>
                        Exportar relatório
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <AlertWarning message="Escolha os campos e configurações para a exportação." />
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldStatus" checked={this.state.exportFieldStatus} onChange={(e) => this.setState({ exportFieldStatus: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldStatus">
                                Status
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldName" checked={this.state.exportFieldName} onChange={(e) => this.setState({ exportFieldName: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldName">
                                Nome
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldCellphone" checked={this.state.exportFieldCellphone} onChange={(e) => this.setState({ exportFieldCellphone: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldCellphone">
                                Celular
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldPlan" checked={this.state.exportFieldPlan} onChange={(e) => this.setState({ exportFieldPlan: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldPlan">
                                Plano
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldPrice" checked={this.state.exportFieldPrice} onChange={(e) => this.setState({ exportFieldPrice: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldPrice">
                                Valor
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldSlots" checked={this.state.exportFieldSlots} onChange={(e) => this.setState({ exportFieldSlots: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldSlots">
                                Vagas
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldDueDate" checked={this.state.exportFieldDueDate} onChange={(e) => this.setState({ exportFieldDueDate: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldDueDate">
                                Vencimento
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" id="exportFieldDetail" checked={this.state.exportFieldDetail} onChange={(e) => this.setState({ exportFieldDetail: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="exportFieldDetail">
                                Link do mensalista
                            </label>
                        </div>
                        <label>Delimitador da planilha</label>
                        <select className="form-select" value={this.state.exportFieldDelimiter} onChange={(e) => this.setState({ exportFieldDelimiter: e.target.value })}>
                            <option value=",">Virgula ( , )</option>
                            <option value=";">Ponto e virgula ( ; )</option>
                        </select>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingExportReportAsXLSModal: false })} className='btn btn-secondary'>Cancelar</button>
                            <button onClick={(e) => this.exportAsXLSAllMonthly(e)} className='btn btn-success'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }
}

class MensalistasSimplyList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className='my-2'>
                    <div className="alert alert-secondary" role="alert">
                        {
                            this.props.monthlies.length === 1 && "Um mensalista pago neste caixa"
                        }
                        {
                            this.props.monthlies.length > 1 && this.props.monthlies.length + " mensalistas pagos neste caixa"
                        }
                    </div>
                </div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width={10}></td>
                            <td><strong>Documento</strong></td>
                            <td align='center'><strong>Pagamento</strong></td>
                            <td align='right'><strong>Valor</strong></td>
                        </tr>
                        {
                            this.props.monthlies.map((item, index) =>
                                <tr key={index}>
                                    <td width='10'>
                                        <Link to={'/visualizar/mensalista/' + item.monthlyId} target="_blank">
                                            <button type="button" className="btn btn-secondary">
                                                <i className="fas fa-eye" />
                                            </button>
                                        </Link>
                                    </td>
                                    <td>{item.clienteNome}</td>
                                    <td align='center'>{item.payment.formaPagamento?.nome ?? "Não disponível"}</td>
                                    <td align='right' className='text-success'>
                                        <strong className='text-success'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.payment.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </strong>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </>
        )
    }
}

class MensalistasPaymentFlow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            estacionamentoId: getEstacionamento()?.id,
            hasMonthies: false
        }
        this.loadData()
    }

    loadData = async () => {
        var monthlies = await getAllMonthlyByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (monthlies.length !== 0) {
            this.setState({ hasMonthies: true })
            const vehicles = await getAllVeiculosOfMonthliesByParkId({ estacionamentoId: this.state.estacionamentoId })
            const accessCards = await getAllAccessCardsByParkId({ estacionamentoId: this.state.estacionamentoId })
            monthlies = monthlies.map(e => {
                return {
                    vehicles: vehicles.filter(f => f.mensalistaId === e.id).map(e => e.placa).join(", "),
                    accessCards: accessCards.filter(d => d.referenceId === e.id).map(e => e.cardNumber).join(", "),
                    ...e
                }
            })
            this.setState({ list: monthlies.sort((a, b) => a.nome.localeCompare(b.nome)) })
            this.setState({ auxList: monthlies.sort((a, b) => a.nome.localeCompare(b.nome)) })
        } else {
            this.setState({ hasMonthies: false })
            this.setState({ list: [] })
            this.setState({ auxList: [] })
            this.setState({ message: "Nenhum mensalista cadastrado" });
        }
        this.setState({ isLoading: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ list: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(
                e => normalizedField(e.nome).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.documento).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.celular).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.vehicles).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.accessCards).replaceAll(" ", "").toUpperCase().includes(value)
            )
            this.setState({ list: result })
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            this.state.isLoading ?
                <Dialog maxWidth={"xs"} onClose={this.onClose} open={true}>
                    <MuiDialogContent>
                        <Loading />
                    </MuiDialogContent>
                </Dialog> :
                <>
                    {
                        this.state.hasMonthies ?
                            <Dialog maxWidth={"md"} fullWidth onClose={this.onClose} open={true}>
                                <MuiDialogTitle className='text-center'>
                                    Busque por nome, placa ou cartão de acesso.
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='card-body'>
                                        <div className='col-lg-12'>
                                            <input type="text" onChange={this.search} className="form-control" />
                                        </div>
                                    </div>
                                    {
                                        this.state.list.length === 0 ?
                                            <EmptyContent text="Nenhum mensalista encontrado com esta busca" />
                                            :
                                            <>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>Nome</strong></td>
                                                            <td className="d-none d-sm-table-cell" align='center'><strong>Plano</strong></td>
                                                            <td className="d-none d-sm-table-cell" align='center'><strong>Veículos</strong></td>
                                                            <td className="d-none d-sm-table-cell" align='center'><strong>Cartões</strong></td>
                                                            <td width={10}></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='table-responsive'>
                                                        {
                                                            this.state.list.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Tooltip title={item.id} placement="top">
                                                                            <span>{item.nome}</span>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td className="d-none d-sm-table-cell" align='center'>{takeIfIsNotNullOrEmpty(item?.plano?.nome) ?? "-"}</td>
                                                                    <td className="d-none d-sm-table-cell" align='center'>{takeIfIsNotNullOrEmpty(item.vehicles) ?? "-"}</td>
                                                                    <td className="d-none d-sm-table-cell" align='center'>{takeIfIsNotNullOrEmpty(item.accessCards) ?? "-"}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <Tooltip role="button" title="Visualizar mensalista" placement="top">
                                                                                <Link to={`/visualizar/mensalista/${item.id}?showCharges=true`} className="btn btn-primary">
                                                                                    <i className="fas fa-eye" />
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                    }
                                </MuiDialogContent>
                            </Dialog>
                            :
                            <Dialog maxWidth={"xs"} fullWidth onClose={this.onClose} open={true}> <MuiDialogContent>
                                <div className="card-body">
                                    <div className='alert alert-warning'>
                                        <span className="badge text-bg-warning text-white">Atenção</span> <br />
                                        Você não tem nenhum mensalista cadastrado<br />
                                        <a href='/cadastrar/mensalista' className='btn btn-warning text-white mt-2'>Cadastrar mensalista</a>
                                    </div>
                                </div>
                            </MuiDialogContent>
                            </Dialog>
                    }
                </>
        )
    }
}

export { CriarMensalista, BuscarMensalistas, ListarMensalistas, MensalistasSimplyList, MensalistasPaymentFlow };