import React from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { getCliente, getEstacionamento, getRevendaIdByPark } from '../../config/auth'

import { NavBar } from '../../components/navbar'
import { Loading, EmptyContent, HelpButton } from '../../components/common/commons'
import { sendClickButton } from '../../shared/analytics'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import { queryString, reloadWindow, reloadWithAlert, setPathWindow, toastWarning } from '../../shared/utils';
import { AlertDanger } from '../../shared/alert-manager';
import { deleteProfile, getAllProfilesByClientId, insertProfile, updateProfile } from '../../store/collections/profileWorker';
import { getUsersByProfileId } from '../../store/collections/userWorker';

const initialState = {
    profileId: null,
    clienteId: getCliente()?.id,
    estacionamentoId: getEstacionamento()?.id,
    id: null,
    key: 'estacionamento',
    nome: '',
    noteIsConfigured: true,
    createClient: false,
    createUser: false,
    createProfile: false,
    createPlan: false,
    createProduct: false,
    createPartnership: false,
    accessWebSite: false,
    createPaymentTable: false,
    createWayPayment: false,
    changeParkOfTicket: false,
    createSectors: false,
    changeTypeVehicleOfTicket: false,
    changePaymentTableOfTicket: false,
    habilitarFecharCaixa: false,
    habilitarAbrirCaixa: false,
    fecharCaixaCego: false,
    habilitarExtornoPagamento: false,
    habilitarCancelarTicket: false,
    notaFiscalVisualizar: false,
    notaFiscalExportar: false,
    notaFiscalConfigurar: false,
    notaPermitirEmitirNoAplicativo: false,
    notaPermitirCancelar: false,
    habilitarDescontoManual: false,
    habilitarPagamentoTicket: false,
    habilitarPagamentoMensalista: false,
    habilitarRemoverSangria: false,
    habilitarRemoverMovimentacaoDeCaixa: false,
    habilitarEmissaoRelatorioCaixasFechados: false,
    habilitarMovimentacoesDeCaixa: false,
    habilitarDataSaida: false,
    habilitarEdicaoPlacaNoTicket: false,
    habilitarDataEntrada: false,
    habilitarTicketBatch: false,
    habilitarZerarPrisma: false,
    habilitarApagarTicket: false,
    habilitarCustomizacaoDoValorDeVenda: false,
    habilitarAutoAtendimento: false,
    habilitarVisualizacaoDeCaixaAbertoPorOutroOperador: false,
    removeMonthly: false,
    habilitarDescontoManualMensalista: false,
    habilitarRotacionarVagaMensalista: false,
    habilitarTicketAvulsoParaMensalista: false,
    habilitarProdutosConsumidosNaProximaFatura: false,
    habilitarPagamentosDeMultiplasMensalidadesDoMensalista: false,
    acessoHabilitarOperarAplicarConvenio: false,
    acessoHabilitarOperarNotaAvulsa: false,
    acessoHabilitarOperarPatioAoVivo: false,
    habilitarVendaAvulsa: false,
    habilitarRemoverProdutoOutroOperador: false,
    acessoPagamentosResumo: false,
    habilitarReimpressaoDeSegundaVia: false,
    accessSmartphone: true,
    acesssCRMContact: true,
    createCRMContact: true
}

class CriarPerfil extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    componentDidMount = async () => {
        const tab = queryString("tab")
        this.changeTab(tab ?? "estacionamento")
    }

    updateProfile = async () => {
        if (this.checkAllFields()) {
            const data = {
                nome: this.state.nome,
                createClient: this.state.createClient,
                createUser: this.state.createUser,
                createPlan: this.state.createPlan,
                createProfile: this.state.createProfile,
                createProduct: this.state.createProduct,
                accessWebSite: this.state.accessWebSite,
                createPartnership: this.state.createPartnership,
                createPaymentTable: this.state.createPaymentTable,
                createWayPayment: this.state.createWayPayment,
                changeParkOfTicket: this.state.changeParkOfTicket,
                changeTypeVehicleOfTicket: this.state.changeTypeVehicleOfTicket,
                changePaymentTableOfTicket: this.state.changePaymentTableOfTicket,
                habilitarFecharCaixa: this.state.habilitarFecharCaixa,
                habilitarAbrirCaixa: this.state.habilitarAbrirCaixa,
                habilitarExtornoPagamento: this.state.habilitarExtornoPagamento,
                habilitarCancelarTicket: this.state.habilitarCancelarTicket,
                notaFiscalVisualizar: this.state.notaFiscalVisualizar,
                notaFiscalExportar: this.state.notaFiscalExportar,
                notaFiscalConfigurar: this.state.notaFiscalConfigurar,
                notaPermitirEmitirNoAplicativo: this.state.notaPermitirEmitirNoAplicativo,
                notaPermitirCancelar: this.state.notaPermitirCancelar,
                habilitarDescontoManual: this.state.habilitarDescontoManual,
                habilitarPagamentoTicket: this.state.habilitarPagamentoTicket,
                fecharCaixaCego: this.state.fecharCaixaCego,
                habilitarRemoverSangria: this.state.habilitarRemoverSangria,
                habilitarRemoverMovimentacaoDeCaixa: this.state.habilitarRemoverMovimentacaoDeCaixa,
                habilitarEmissaoRelatorioCaixasFechados: this.state.habilitarEmissaoRelatorioCaixasFechados,
                habilitarMovimentacoesDeCaixa: this.state.habilitarMovimentacoesDeCaixa,
                habilitarPagamentoMensalista: this.state.habilitarPagamentoMensalista,
                habilitarDataSaida: this.state.habilitarDataSaida,
                habilitarEdicaoPlacaNoTicket: this.state.habilitarEdicaoPlacaNoTicket,
                habilitarDataEntrada: this.state.habilitarDataEntrada,
                habilitarTicketBatch: this.state.habilitarTicketBatch,
                habilitarDescontoManualMensalista: this.state.habilitarDescontoManualMensalista,
                accessSmartphone: this.state.accessSmartphone,
                removeMonthly: this.state.removeMonthly,
                editMonthly: this.state.editMonthly,
                removeMonthlyVehicle: this.state.removeMonthlyVehicle,
                habilitarRotacionarVagaMensalista: this.state.habilitarRotacionarVagaMensalista,
                habilitarTicketAvulsoParaMensalista: this.state.habilitarTicketAvulsoParaMensalista,
                habilitarVendaAvulsa: this.state.habilitarVendaAvulsa,
                habilitarPagamentosDeMultiplasMensalidadesDoMensalista: this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista,
                habilitarProdutosConsumidosNaProximaFatura: this.state.habilitarProdutosConsumidosNaProximaFatura,
                habilitarZerarPrisma: this.state.habilitarZerarPrisma,
                habilitarApagarTicket: this.state.habilitarApagarTicket,
                habilitarCustomizacaoDoValorDeVenda: this.state.habilitarCustomizacaoDoValorDeVenda,
                habilitarAutoAtendimento: this.state.habilitarAutoAtendimento,
                habilitarVisualizacaoDeCaixaAbertoPorOutroOperador: this.state.habilitarVisualizacaoDeCaixaAbertoPorOutroOperador,
                habilitarRemoverProdutoOutroOperador: this.state.habilitarRemoverProdutoOutroOperador,
                acessoPagamentosResumo: this.state.acessoPagamentosResumo,
                acessoHabilitarConfiguracoesCobrancas: this.state.acessoHabilitarConfiguracoesCobrancas,
                habilitarReimpressaoDeSegundaVia: this.state.habilitarReimpressaoDeSegundaVia,
                acessoHabilitarBuscaTickets: this.state.acessoHabilitarBuscaTickets,
                acessoHabilitarBuscaCaixas: this.state.acessoHabilitarBuscaCaixas,
                acessoHabilitarBuscaMensalistas: this.state.acessoHabilitarBuscaMensalistas,
                acessoHabilitarRelatorioVendas: this.state.acessoHabilitarRelatorioVendas,
                acessoHabilitarRelatorioCaixas: this.state.acessoHabilitarRelatorioCaixas,
                acessoHabilitarRelatorioMensalistas: this.state.acessoHabilitarRelatorioMensalistas,
                acessoHabilitarRelatorioAcessos: this.state.acessoHabilitarRelatorioAcessos,
                acessoHabilitarConfiguracoesParametros: this.state.acessoHabilitarConfiguracoesParametros,
                acessoHabilitarConfiguracoesDadosCadastrais: this.state.acessoHabilitarConfiguracoesDadosCadastrais,
                acessoHabilitarConfiguracoesCobrancas: this.state.acessoHabilitarConfiguracoesCobrancas,
                acessoHabilitarConfiguracoesLicenca: this.state.acessoHabilitarConfiguracoesLicenca,
                createSectors: this.state.createSectors,
                createAccredited: this.state.createAccredited,
                acessoHabilitarNotaFiscalVerTodas: this.state.acessoHabilitarNotaFiscalVerTodas,
                acessoHabilitarNotaFiscalSeries: this.state.acessoHabilitarNotaFiscalSeries,
                acessoHabilitarNotaFiscalConfigurar: this.state.acessoHabilitarNotaFiscalConfigurar,
                acessoCancelaTotem: this.state.acessoCancelaTotem,
                acessoCancelaLPR: this.state.acessoCancelaLPR,
                acessoAparelhos: this.state.acessoAparelhos,
                acessoHabilitarOperarAplicarConvenio: this.state.acessoHabilitarOperarAplicarConvenio,
                acessoHabilitarOperarNotaAvulsa: this.state.acessoHabilitarOperarNotaAvulsa,
                acessoHabilitarOperarPatioAoVivo: this.state.acessoHabilitarOperarPatioAoVivo,
                acesssCRMContact: this.state.acesssCRMContact,
                createCRMContact: this.state.createCRMContact,
                habilitarMensalistasCobrancaRegistrarPagamento: this.state.habilitarMensalistasCobrancaRegistrarPagamento,
                habilitarMensalistasCobrancaEditar: this.state.habilitarMensalistasCobrancaEditar,
                habilitarMensalistasCobrancaApagar: this.state.habilitarMensalistasCobrancaApagar,
                habilitarMensalistasCobrancaAdicionar: this.state.habilitarMensalistasCobrancaAdicionar,
                habilitarMensalistasEditarPlano: this.state.habilitarMensalistasEditarPlano,
                habilitarMensalistasEditarVencimento: this.state.habilitarMensalistasEditarVencimento,
            }
            await updateProfile({ id: this.state.profileId, data: data })
            await saveLastUpdate()
            sendClickButton("Perfil de acesso", "Atualizar", true, data)
            reloadWithAlert('Perfil atualizado com sucesso')
        }
    }

    addProfile = async () => {
        if (this.checkAllFields()) {
            let cliente = getCliente()
            const data = {
                nome: this.state.nome,
                clienteId: cliente.id,
                cliente: cliente.nome,
                revendaId: getRevendaIdByPark(),
                createClient: this.state.createClient,
                createPlan: this.state.createPlan,
                createUser: this.state.createUser,
                createProfile: this.state.createProfile,
                createProduct: this.state.createProduct,
                accessWebSite: this.state.accessWebSite,
                createPartnership: this.state.createPartnership,
                createPaymentTable: this.state.createPaymentTable,
                createWayPayment: this.state.createWayPayment,
                changeParkOfTicket: this.state.changeParkOfTicket,
                changeTypeVehicleOfTicket: this.state.changeTypeVehicleOfTicket,
                changePaymentTableOfTicket: this.state.changePaymentTableOfTicket,
                habilitarFecharCaixa: this.state.habilitarFecharCaixa,
                habilitarAbrirCaixa: this.state.habilitarAbrirCaixa,
                habilitarExtornoPagamento: this.state.habilitarExtornoPagamento,
                habilitarCancelarTicket: this.state.habilitarCancelarTicket,
                notaFiscalVisualizar: this.state.notaFiscalVisualizar,
                notaFiscalExportar: this.state.notaFiscalExportar,
                notaFiscalConfigurar: this.state.notaFiscalConfigurar,
                notaPermitirEmitirNoAplicativo: this.state.notaPermitirEmitirNoAplicativo,
                notaPermitirCancelar: this.state.notaPermitirCancelar,
                habilitarDescontoManual: this.state.habilitarDescontoManual,
                habilitarPagamentoTicket: this.state.habilitarPagamentoTicket,
                fecharCaixaCego: this.state.fecharCaixaCego,
                habilitarRemoverSangria: this.state.habilitarRemoverSangria,
                habilitarRemoverMovimentacaoDeCaixa: this.state.habilitarRemoverMovimentacaoDeCaixa,
                habilitarEmissaoRelatorioCaixasFechados: this.state.habilitarEmissaoRelatorioCaixasFechados,
                habilitarMovimentacoesDeCaixa: this.state.habilitarMovimentacoesDeCaixa,
                habilitarPagamentoMensalista: this.state.habilitarPagamentoMensalista,
                habilitarDataSaida: this.state.habilitarDataSaida,
                habilitarEdicaoPlacaNoTicket: this.state.habilitarEdicaoPlacaNoTicket,
                habilitarDataEntrada: this.state.habilitarDataEntrada,
                habilitarTicketBatch: this.state.habilitarTicketBatch,
                habilitarDescontoManualMensalista: this.state.habilitarDescontoManualMensalista,
                accessSmartphone: this.state.accessSmartphone,
                habilitarRotacionarVagaMensalista: this.state.habilitarRotacionarVagaMensalista,
                habilitarTicketAvulsoParaMensalista: this.state.habilitarTicketAvulsoParaMensalista,
                habilitarVendaAvulsa: this.state.habilitarVendaAvulsa,
                removeMonthly: this.state.removeMonthly,
                editMonthly: this.state.editMonthly,
                removeMonthlyVehicle: this.state.removeMonthlyVehicle,
                habilitarPagamentosDeMultiplasMensalidadesDoMensalista: this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista,
                habilitarProdutosConsumidosNaProximaFatura: this.state.habilitarProdutosConsumidosNaProximaFatura,
                habilitarZerarPrisma: this.state.habilitarZerarPrisma,
                habilitarApagarTicket: this.state.habilitarApagarTicket,
                habilitarCustomizacaoDoValorDeVenda: this.state.habilitarCustomizacaoDoValorDeVenda,
                habilitarAutoAtendimento: this.state.habilitarAutoAtendimento,
                habilitarVisualizacaoDeCaixaAbertoPorOutroOperador: this.state.habilitarVisualizacaoDeCaixaAbertoPorOutroOperador,
                habilitarRemoverProdutoOutroOperador: this.state.habilitarRemoverProdutoOutroOperador,
                acessoPagamentosResumo: this.state.acessoPagamentosResumo,
                acessoHabilitarConfiguracoesCobrancas: this.state.acessoHabilitarConfiguracoesCobrancas,
                habilitarReimpressaoDeSegundaVia: this.state.habilitarReimpressaoDeSegundaVia,
                acessoHabilitarBuscaTickets: this.state.acessoHabilitarBuscaTickets,
                acessoHabilitarBuscaCaixas: this.state.acessoHabilitarBuscaCaixas,
                acessoHabilitarBuscaMensalistas: this.state.acessoHabilitarBuscaMensalistas,
                acessoHabilitarRelatorioVendas: this.state.acessoHabilitarRelatorioVendas,
                acessoHabilitarRelatorioCaixas: this.state.acessoHabilitarRelatorioCaixas,
                acessoHabilitarRelatorioMensalistas: this.state.acessoHabilitarRelatorioMensalistas,
                acessoHabilitarRelatorioAcessos: this.state.acessoHabilitarRelatorioAcessos,
                acessoHabilitarConfiguracoesParametros: this.state.acessoHabilitarConfiguracoesParametros,
                acessoHabilitarConfiguracoesDadosCadastrais: this.state.acessoHabilitarConfiguracoesDadosCadastrais,
                acessoHabilitarConfiguracoesLicenca: this.state.acessoHabilitarConfiguracoesLicenca,
                createSectors: this.state.createSectors,
                createAccredited: this.state.createAccredited,
                acessoHabilitarNotaFiscalVerTodas: this.state.acessoHabilitarNotaFiscalVerTodas,
                acessoHabilitarNotaFiscalSeries: this.state.acessoHabilitarNotaFiscalSeries,
                acessoHabilitarNotaFiscalConfigurar: this.state.acessoHabilitarNotaFiscalConfigurar,
                acessoCancelaTotem: this.state.acessoCancelaTotem,
                acessoCancelaLPR: this.state.acessoCancelaLPR,
                acessoAparelhos: this.state.acessoAparelhos,
                acessoHabilitarOperarAplicarConvenio: this.state.acessoHabilitarOperarAplicarConvenio,
                acessoHabilitarOperarNotaAvulsa: this.state.acessoHabilitarOperarNotaAvulsa,
                acessoHabilitarOperarPatioAoVivo: this.state.acessoHabilitarOperarPatioAoVivo,
                acesssCRMContact: this.state.acesssCRMContact,
                createCRMContact: this.state.createCRMContact,
                habilitarMensalistasCobrancaRegistrarPagamento: this.state.habilitarMensalistasCobrancaRegistrarPagamento,
                habilitarMensalistasCobrancaEditar: this.state.habilitarMensalistasCobrancaEditar,
                habilitarMensalistasCobrancaApagar: this.state.habilitarMensalistasCobrancaApagar,
                habilitarMensalistasCobrancaAdicionar: this.state.habilitarMensalistasCobrancaAdicionar,
                habilitarMensalistasEditarPlano: this.state.habilitarMensalistasEditarPlano,
                habilitarMensalistasEditarVencimento: this.state.habilitarMensalistasEditarVencimento,
            }
            await insertProfile({ data: data })
            await saveLastUpdate()
            sendClickButton("Perfil de acesso", "Cadastrar", true, data)
            reloadWithAlert('Perfil adicionado com sucesso')
        }
    }

    changeTab = (tab) => {
        setPathWindow(`/cadastrar/perfil?tab=${tab}`)
        this.setState({ key: tab })
    }

    cancelEdit = () => {
        this.setState({ ...initialState })
    }

    checkAllFields = () => {
        if (this.state.nome === '') {
            toastWarning('Informe o nome do perfil')
            return false
        }
        return true
    }

    onEditProfile = (perfil) => {
        this.setState({ ...perfil, profileId: perfil.id })
        this.setState({ habilitarDescontoManual: perfil.habilitarDescontoManual ?? false })
        this.setState({ habilitarPagamentoTicket: perfil.habilitarPagamentoTicket ?? true })
        this.setState({ habilitarPagamentoMensalista: perfil.habilitarPagamentoMensalista ?? true })
        this.setState({ fecharCaixaCego: perfil.fecharCaixaCego ?? false })
        this.setState({ habilitarRemoverSangria: perfil.habilitarRemoverSangria ?? false })
        this.setState({ habilitarRemoverMovimentacaoDeCaixa: perfil.habilitarRemoverMovimentacaoDeCaixa ?? false })
        this.setState({ habilitarEmissaoRelatorioCaixasFechados: perfil.habilitarEmissaoRelatorioCaixasFechados ?? false })
        this.setState({ habilitarMovimentacoesDeCaixa: perfil.habilitarMovimentacoesDeCaixa ?? true })
        this.setState({ habilitarDataSaida: perfil.habilitarDataSaida ?? false })
        this.setState({ habilitarEdicaoPlacaNoTicket: perfil.habilitarEdicaoPlacaNoTicket ?? false })

        this.setState({ habilitarDescontoManualMensalista: perfil.habilitarDescontoManualMensalista ?? false })
        this.setState({ accessSmartphone: perfil.accessSmartphone ?? true })
        this.setState({ habilitarRotacionarVagaMensalista: perfil.habilitarRotacionarVagaMensalista ?? true })
        this.setState({ habilitarTicketAvulsoParaMensalista: perfil.habilitarTicketAvulsoParaMensalista ?? false })

        this.setState({ habilitarDataEntrada: perfil.habilitarDataEntrada ?? false })
        this.setState({ habilitarVendaAvulsa: perfil.habilitarVendaAvulsa ?? false })
        this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalista: perfil.habilitarPagamentosDeMultiplasMensalidadesDoMensalista ?? false })
        this.setState({ habilitarProdutosConsumidosNaProximaFatura: perfil.habilitarProdutosConsumidosNaProximaFatura ?? false })
        this.setState({ removeMonthly: perfil.removeMonthly ?? false })
        this.setState({ editMonthly: perfil.editMonthly ?? false })
        this.setState({ habilitarZerarPrisma: perfil.habilitarZerarPrisma ?? false })
        this.setState({ habilitarApagarTicket: perfil.habilitarApagarTicket ?? false })
        this.setState({ habilitarCustomizacaoDoValorDeVenda: perfil.habilitarCustomizacaoDoValorDeVenda ?? false })
        this.setState({ habilitarAutoAtendimento: perfil.habilitarAutoAtendimento ?? false })
        this.setState({ habilitarVisualizacaoDeCaixaAbertoPorOutroOperador: perfil.habilitarVisualizacaoDeCaixaAbertoPorOutroOperador ?? false })
        this.setState({ habilitarRemoverProdutoOutroOperador: perfil.habilitarRemoverProdutoOutroOperador ?? true })
        this.setState({ acessoHabilitarConfiguracoesCobrancas: perfil.acessoHabilitarConfiguracoesCobrancas ?? true })
        this.setState({ acessoPagamentosResumo: perfil.acessoPagamentosResumo ?? false })
        this.setState({ habilitarReimpressaoDeSegundaVia: perfil.habilitarReimpressaoDeSegundaVia ?? false })
        this.setState({ acessoHabilitarBuscaTickets: perfil.acessoHabilitarBuscaTickets ?? true })
        this.setState({ acessoHabilitarBuscaCaixas: perfil.acessoHabilitarBuscaCaixas ?? true })
        this.setState({ acessoHabilitarBuscaMensalistas: perfil.acessoHabilitarBuscaMensalistas ?? true })
        this.setState({ acessoHabilitarRelatorioVendas: perfil.acessoHabilitarRelatorioVendas ?? true })
        this.setState({ acessoHabilitarRelatorioCaixas: perfil.acessoHabilitarRelatorioCaixas ?? true })
        this.setState({ acessoHabilitarRelatorioMensalistas: perfil.acessoHabilitarRelatorioMensalistas ?? true })
        this.setState({ acessoHabilitarRelatorioAcessos: perfil.acessoHabilitarRelatorioAcessos ?? true })
        this.setState({ acessoHabilitarConfiguracoesParametros: perfil.acessoHabilitarConfiguracoesParametros ?? true })
        this.setState({ acessoHabilitarConfiguracoesDadosCadastrais: perfil.acessoHabilitarConfiguracoesDadosCadastrais ?? true })
        this.setState({ acessoHabilitarConfiguracoesLicenca: perfil.acessoHabilitarConfiguracoesLicenca ?? true })
        this.setState({ acessoHabilitarNotaFiscalVerTodas: perfil.acessoHabilitarNotaFiscalVerTodas ?? true })
        this.setState({ acessoHabilitarNotaFiscalSeries: perfil.acessoHabilitarNotaFiscalSeries ?? true })
        this.setState({ acessoHabilitarNotaFiscalConfigurar: perfil.acessoHabilitarNotaFiscalConfigurar ?? true })
        this.setState({ acessoCancelaTotem: perfil.acessoCancelaTotem ?? false })
        this.setState({ acessoCancelaLPR: perfil.acessoCancelaLPR ?? false })
        this.setState({ acessoAparelhos: perfil.acessoAparelhos ?? false })
        this.setState({ createClient: perfil.createClient ?? true })
        this.setState({ createUser: perfil.createUser ?? true })
        this.setState({ createProfile: perfil.createProfile ?? true })
        this.setState({ createPlan: perfil.createPlan ?? true })
        this.setState({ createProduct: perfil.createProduct ?? true })
        this.setState({ createPartnership: perfil.createPartnership ?? true })
        this.setState({ accessWebSite: perfil.accessWebSite ?? true })
        this.setState({ createPaymentTable: perfil.createPaymentTable ?? true })
        this.setState({ createWayPayment: perfil.createWayPayment ?? true })
        this.setState({ createSectors: perfil.createSectors ?? true })
        this.setState({ createAccredited: perfil.createAccredited ?? true })
        this.setState({ acessoHabilitarOperarAplicarConvenio: perfil.acessoHabilitarOperarAplicarConvenio ?? true })
        this.setState({ acessoHabilitarOperarNotaAvulsa: perfil.acessoHabilitarOperarNotaAvulsa ?? true })
        this.setState({ acessoHabilitarOperarPatioAoVivo: perfil.acessoHabilitarOperarPatioAoVivo ?? true })
        
        this.setState({ createCRMContact: perfil.createCRMContact ?? true })
        this.setState({ acesssCRMContact: perfil.acesssCRMContact ?? true })

        this.setState({ habilitarMensalistasCobrancaRegistrarPagamento: perfil.habilitarMensalistasCobrancaRegistrarPagamento ?? false })
        this.setState({ habilitarMensalistasCobrancaEditar: perfil.habilitarMensalistasCobrancaEditar ?? false })
        this.setState({ habilitarMensalistasCobrancaApagar: perfil.habilitarMensalistasCobrancaApagar ?? false })
        this.setState({ habilitarMensalistasCobrancaAdicionar: perfil.habilitarMensalistasCobrancaAdicionar ?? false })
        this.setState({ habilitarMensalistasEditarPlano: perfil.habilitarMensalistasEditarPlano ?? false })
        this.setState({ habilitarMensalistasEditarVencimento: perfil.habilitarMensalistasEditarVencimento ?? false })
    }

    onDuplicate = (item) => {
        item.id = null
        this.onEditProfile(item)
    }

    onRemove = async (item) => {
        const allProfiles = await getAllProfilesByClientId({ clientId: this.state.clienteId })
        if (allProfiles.length === 1) {
            toastWarning("Não é possível apagar todos os perfis de acesso, você deve deixar ao menos 1!")
            return
        }
        const users = await getUsersByProfileId({ profileId: item.id })
        if (users.length !== 0) {
            var items = users.map(e => e.data().email + "\n")
            toastWarning("Não é possível remover um perfil de acesso associado a usuários, antes você deve altera-los\n\nUsuários com este perfil de acesso: \n\n" + items)
            return
        }
        var confirm = window.confirm('Deseja mesmo remover o perfil ' + item.nome + '?');
        if (confirm) {
            await deleteProfile({ id: item.id })
            reloadWindow()
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className='col-md-12 col-lg-3'>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Cadastrar perfil de acesso
                                    <HelpButton />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-lg-12'>
                                            <label>Nome do perfil</label>
                                            <input type="text" value={this.state.nome} onChange={(e) => { this.setState({ nome: e.target.value }) }} className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className="btn-group">
                                        {
                                            this.state.profileId !== null &&
                                            <Button onClick={this.cancelEdit} className="btn-secondary text-white" variant="default">Cancelar</Button>
                                        }
                                        <Button onClick={this.state.profileId === null ? this.addProfile : this.updateProfile} variant="primary"> {this.state.id === null ? 'Cadastrar' : 'Atualizar'} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Permissões
                                </div>
                                <div className="card-body">
                                    <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                                        <Tab eventKey="estacionamento" title="Estacionamento">
                                            <div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="changeParkOfTicket" checked={this.state.changeParkOfTicket} onChange={(e) => this.setState({ changeParkOfTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="changeParkOfTicket">
                                                        Editar o estacionamento de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="changeTypeVehicleOfTicket" checked={this.state.changeTypeVehicleOfTicket} onChange={(e) => this.setState({ changeTypeVehicleOfTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="changeTypeVehicleOfTicket">
                                                        Editar o tipo de veiculo de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="changePaymentTableOfTicket" checked={this.state.changePaymentTableOfTicket} onChange={(e) => this.setState({ changePaymentTableOfTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="changePaymentTableOfTicket">
                                                        Alterar tabela de pagamentos de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarExtornoPagamento" checked={this.state.habilitarExtornoPagamento} onChange={(e) => this.setState({ habilitarExtornoPagamento: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarExtornoPagamento">
                                                        Fazer o estorno do pagamento de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarCancelarTicket" checked={this.state.habilitarCancelarTicket} onChange={(e) => this.setState({ habilitarCancelarTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCancelarTicket">
                                                        Fazer o cancelamento de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarDescontoManual" checked={this.state.habilitarDescontoManual} onChange={(e) => this.setState({ habilitarDescontoManual: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarDescontoManual">
                                                        Permitir adicionar desconto manual ( em pagamento de tickets).
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarDescontoManualMensalista" checked={this.state.habilitarDescontoManualMensalista} onChange={(e) => this.setState({ habilitarDescontoManualMensalista: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarDescontoManualMensalista">
                                                        Permitir adicionar desconto manual ( em pagamento de mensalistas ).
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarDataEntrada" checked={this.state.habilitarDataEntrada} onChange={(e) => this.setState({ habilitarDataEntrada: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarDataEntrada">
                                                        Permitir editar o dia e horário de entrada do veículo.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarDataSaida" checked={this.state.habilitarDataSaida} onChange={(e) => this.setState({ habilitarDataSaida: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarDataSaida">
                                                        Permitir editar o dia e horário de saída do veículo.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarEdicaoPlacaNoTicket" checked={this.state.habilitarEdicaoPlacaNoTicket} onChange={(e) => this.setState({ habilitarEdicaoPlacaNoTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEdicaoPlacaNoTicket">
                                                        Permitir editar placa em tickets já lançados.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarTicketBatch" checked={this.state.habilitarTicketBatch} onChange={(e) => this.setState({ habilitarTicketBatch: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarTicketBatch">
                                                        Permitir alterar status de tickets em lote ( mais de um por vez ).
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarZerarPrisma" checked={this.state.habilitarZerarPrisma} onChange={(e) => this.setState({ habilitarZerarPrisma: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarZerarPrisma">
                                                        Permitir zerar o prisma ao fechar um caixa.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAutoAtendimento" checked={this.state.habilitarAutoAtendimento} onChange={(e) => this.setState({ habilitarAutoAtendimento: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAutoAtendimento">
                                                        Permitir ativar o modo de auto atendimento no POS.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarReimpressaoDeSegundaVia" checked={this.state.habilitarReimpressaoDeSegundaVia} onChange={(e) => this.setState({ habilitarReimpressaoDeSegundaVia: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarReimpressaoDeSegundaVia">
                                                        Habilitar permissão para imprimir segunda via de um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarApagarTicket" checked={this.state.habilitarApagarTicket} onChange={(e) => this.setState({ habilitarApagarTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarApagarTicket">
                                                        Permitir apagar um ticket.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarCustomizacaoDoValorDeVenda" checked={this.state.habilitarCustomizacaoDoValorDeVenda} onChange={(e) => this.setState({ habilitarCustomizacaoDoValorDeVenda: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCustomizacaoDoValorDeVenda">
                                                        Permitir customizar o valor do produto na venda.
                                                    </label>
                                                    <span className='mx-2'>
                                                        <span className="badge text-bg-success">Novidade</span>
                                                    </span>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarVisualizacaoDeCaixaAbertoPorOutroOperador" checked={this.state.habilitarVisualizacaoDeCaixaAbertoPorOutroOperador} onChange={(e) => this.setState({ habilitarVisualizacaoDeCaixaAbertoPorOutroOperador: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarVisualizacaoDeCaixaAbertoPorOutroOperador">
                                                        Permitir visualizar caixas de outros usuários.
                                                    </label>
                                                    <a href='/configuracao/parametros?tab=caixa&flag=Tipo de caixa aberto'>
                                                        <span className="badge text-bg-info mx-2 text-white">Para ativar caixas por usuário.
                                                            <i className="fas fa-external-link-square-alt mx-1" />
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="caixa" title="Caixa">
                                            <div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarFecharCaixa" checked={this.state.habilitarFecharCaixa} onChange={(e) => this.setState({ habilitarFecharCaixa: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarFecharCaixa">
                                                        Permitir fechar caixas.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAbrirCaixa" checked={this.state.habilitarAbrirCaixa} onChange={(e) => this.setState({ habilitarAbrirCaixa: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAbrirCaixa">
                                                        Permitir abrir caixas.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarPagamentoTicket" checked={this.state.habilitarPagamentoTicket} onChange={(e) => this.setState({ habilitarPagamentoTicket: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarPagamentoTicket">
                                                        Permitir fazer pagamentos em tickets.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarPagamentoMensalista" checked={this.state.habilitarPagamentoMensalista} onChange={(e) => this.setState({ habilitarPagamentoMensalista: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarPagamentoMensalista">
                                                        Permitir fazer pagamentos em mensalistas.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarRemoverSangria" checked={this.state.habilitarRemoverSangria} onChange={(e) => this.setState({ habilitarRemoverSangria: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarRemoverSangria">
                                                        Permitir remover sangria.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarRemoverMovimentacaoDeCaixa" checked={this.state.habilitarRemoverMovimentacaoDeCaixa} onChange={(e) => this.setState({ habilitarRemoverMovimentacaoDeCaixa: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarRemoverMovimentacaoDeCaixa">
                                                        Permitir remover movimentações.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarEmissaoRelatorioCaixasFechados" checked={this.state.habilitarEmissaoRelatorioCaixasFechados} onChange={(e) => this.setState({ habilitarEmissaoRelatorioCaixasFechados: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoRelatorioCaixasFechados">
                                                        Permitir emitir relatório de caixas fechados
                                                    </label>
                                                    <span className='mx-2'>
                                                        <span className="badge text-bg-success">Novidade</span>
                                                    </span>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="fecharCaixaCego" checked={this.state.fecharCaixaCego} onChange={(e) => this.setState({ fecharCaixaCego: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="fecharCaixaCego">
                                                        Fechar caixa cego.
                                                    </label>
                                                </div>
                                                <div className="alert alert-secondary my-3" role="alert">
                                                    <strong>Fechar caixa habilitado:</strong> <br /> O operador não visualiza o faturamento e informa somente o que tem em mãos. <p />
                                                    <strong>Fechar caixa desabilitado:</strong> <br /> O operador visualiza o faturamento do caixa e informa os valores que tem em mãos.
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="mensalista" title="Mensalista">
                                            <div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarRotacionarVagaMensalista" checked={this.state.habilitarRotacionarVagaMensalista} onChange={(e) => this.setState({ habilitarRotacionarVagaMensalista: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarRotacionarVagaMensalista">
                                                        Permitir rotacionar vaga de mensalista.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarTicketAvulsoParaMensalista" checked={this.state.habilitarTicketAvulsoParaMensalista} onChange={(e) => this.setState({ habilitarTicketAvulsoParaMensalista: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarTicketAvulsoParaMensalista">
                                                        Habilitar opção de associar um ticket avulso a um mensalista.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarPagamentosDeMultiplasMensalidadesDoMensalista" checked={this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista} onChange={(e) => this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalista: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarPagamentosDeMultiplasMensalidadesDoMensalista">
                                                        Permitir pagar mais de um mês de mensalidade em um pagamento.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarProdutosConsumidosNaProximaFatura" checked={this.state.habilitarProdutosConsumidosNaProximaFatura} onChange={(e) => this.setState({ habilitarProdutosConsumidosNaProximaFatura: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarProdutosConsumidosNaProximaFatura">
                                                        Permitir lançar produtos e serviços consumidos na próxima fatura da mensalidade.
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="editMonthly" checked={this.state.editMonthly} onChange={(e) => this.setState({ editMonthly: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="editMonthly">
                                                        Permitir editar um mensalista. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasEditarPlano" checked={this.state.habilitarMensalistasEditarPlano} onChange={(e) => this.setState({ habilitarMensalistasEditarPlano: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasEditarPlano">
                                                        Permitir editar o plano de um mensalista. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasEditarVencimento" checked={this.state.habilitarMensalistasEditarVencimento} onChange={(e) => this.setState({ habilitarMensalistasEditarVencimento: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasEditarVencimento">
                                                        Permitir editar o vencimento de um mensalista. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasCobrancaRegistrarPagamento" checked={this.state.habilitarMensalistasCobrancaRegistrarPagamento} onChange={(e) => this.setState({ habilitarMensalistasCobrancaRegistrarPagamento: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasCobrancaRegistrarPagamento">
                                                        Permitir efetuar pagamento de uma cobrança. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasCobrancaEditar" checked={this.state.habilitarMensalistasCobrancaEditar} onChange={(e) => this.setState({ habilitarMensalistasCobrancaEditar: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasCobrancaEditar">
                                                        Permitir editar uma cobrança. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasCobrancaApagar" checked={this.state.habilitarMensalistasCobrancaApagar} onChange={(e) => this.setState({ habilitarMensalistasCobrancaApagar: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasCobrancaApagar">
                                                        Permitir apagar uma cobrança. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarMensalistasCobrancaAdicionar" checked={this.state.habilitarMensalistasCobrancaAdicionar} onChange={(e) => this.setState({ habilitarMensalistasCobrancaAdicionar: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarMensalistasCobrancaAdicionar">
                                                        Permitir adicionar novas cobranças. <span className="badge text-bg-warning text-white">Administrativo</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="acessos" title="Acessos">
                                            <div className='col-lg-12 mt-2'>
                                                <div className='alert alert-secondary'>
                                                    <h5>Cadastrar</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createClient" checked={this.state.createClient} onChange={(e) => this.setState({ createClient: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createClient">
                                                                Cadastrar
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="editMonthly" checked={this.state.editMonthly} onChange={(e) => this.setState({ editMonthly: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="editMonthly">
                                                                Editar mensalistas.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="removeMonthly" checked={this.state.removeMonthly} onChange={(e) => this.setState({ removeMonthly: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="removeMonthly">
                                                                Apagar mensalistas.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="removeMonthlyVehicle" checked={this.state.removeMonthlyVehicle} onChange={(e) => this.setState({ removeMonthlyVehicle: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="removeMonthlyVehicle">
                                                                Apagar veículo de mensalistas.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createPlan" checked={this.state.createPlan === true || (this.state.createPlan === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ createPlan: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createPlan">
                                                                Planos
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createProduct" checked={this.state.createProduct === true || (this.state.createProduct === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ createProduct: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createProduct">
                                                                Produtos e serviços
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createPartnership" checked={this.state.createPartnership === true || (this.state.createPartnership === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ createPartnership: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createPartnership">
                                                                Convênios
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createProfile" checked={this.state.createProfile} onChange={(e) => this.setState({ createProfile: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createProfile">
                                                                Perfil de acesso
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createUser" checked={this.state.createUser} onChange={(e) => this.setState({ createUser: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createUser">
                                                                Usuário
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createPaymentTable" checked={this.state.createPaymentTable === true || (this.state.createPaymentTable === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ createPaymentTable: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createPaymentTable">
                                                                Tabela de preços
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createWayPayment" checked={this.state.createWayPayment === true || (this.state.createWayPayment === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ createWayPayment: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createWayPayment">
                                                                Formas de pagamento
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createSectors" checked={this.state.createSectors} onChange={(e) => this.setState({ createSectors: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createSectors">
                                                                Setores
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="createAccredited" checked={this.state.createAccredited} onChange={(e) => this.setState({ createAccredited: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="createAccredited">
                                                                Credenciados
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Operação</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarOperarAplicarConvenio" checked={this.state.acessoHabilitarOperarAplicarConvenio} onChange={(e) => this.setState({ acessoHabilitarOperarAplicarConvenio: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarOperarAplicarConvenio">
                                                                Aplicar convênios.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarOperarNotaAvulsa" checked={this.state.acessoHabilitarOperarNotaAvulsa} onChange={(e) => this.setState({ acessoHabilitarOperarNotaAvulsa: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarOperarNotaAvulsa">
                                                                Emitir nota avulsa.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarOperarPatioAoVivo" checked={this.state.acessoHabilitarOperarPatioAoVivo} onChange={(e) => this.setState({ acessoHabilitarOperarPatioAoVivo: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarOperarPatioAoVivo">
                                                                Pátio ao vivo
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Buscar</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarBuscaTickets" checked={this.state.acessoHabilitarBuscaTickets} onChange={(e) => this.setState({ acessoHabilitarBuscaTickets: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarBuscaTickets">
                                                                Tickets
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarBuscaCaixas" checked={this.state.acessoHabilitarBuscaCaixas} onChange={(e) => this.setState({ acessoHabilitarBuscaCaixas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarBuscaCaixas">
                                                                Caixas
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarBuscaMensalistas" checked={this.state.acessoHabilitarBuscaMensalistas} onChange={(e) => this.setState({ acessoHabilitarBuscaMensalistas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarBuscaMensalistas">
                                                                Mensalistas
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Relatórios</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarRelatorioVendas" checked={this.state.acessoHabilitarRelatorioVendas} onChange={(e) => this.setState({ acessoHabilitarRelatorioVendas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarRelatorioVendas">
                                                                Vendas
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarRelatorioCaixas" checked={this.state.acessoHabilitarRelatorioCaixas} onChange={(e) => this.setState({ acessoHabilitarRelatorioCaixas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarRelatorioCaixas">
                                                                Caixas
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarRelatorioMensalistas" checked={this.state.acessoHabilitarRelatorioMensalistas} onChange={(e) => this.setState({ acessoHabilitarRelatorioMensalistas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarRelatorioMensalistas">
                                                                Mensalistas
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarRelatorioAcessos" checked={this.state.acessoHabilitarRelatorioAcessos} onChange={(e) => this.setState({ acessoHabilitarRelatorioAcessos: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarRelatorioAcessos">
                                                                Acessos
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Nota Fiscal</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarNotaFiscalVerTodas" checked={this.state.acessoHabilitarNotaFiscalVerTodas} onChange={(e) => this.setState({ acessoHabilitarNotaFiscalVerTodas: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarNotaFiscalVerTodas">
                                                                Ver todas
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="notaPermitirEmitirNoAplicativo" checked={this.state.notaPermitirEmitirNoAplicativo} onChange={(e) => this.setState({ notaPermitirEmitirNoAplicativo: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="notaPermitirEmitirNoAplicativo">
                                                                Emitir nota <span className="badge text-bg-primary">Aplicativo</span>
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarNotaFiscalSeries" checked={this.state.acessoHabilitarNotaFiscalSeries} onChange={(e) => this.setState({ acessoHabilitarNotaFiscalSeries: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarNotaFiscalSeries">
                                                                Series
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarNotaFiscalConfigurar" checked={this.state.acessoHabilitarNotaFiscalConfigurar} onChange={(e) => this.setState({ acessoHabilitarNotaFiscalConfigurar: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarNotaFiscalConfigurar">
                                                                Configurar
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Cancelas</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoCancelaTotem" checked={this.state.acessoCancelaTotem} onChange={(e) => this.setState({ acessoCancelaTotem: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoCancelaTotem">
                                                                Totens
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoCancelaLPR" checked={this.state.acessoCancelaLPR} onChange={(e) => this.setState({ acessoCancelaLPR: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoCancelaLPR">
                                                                LPR ( Reconhecimento de placa )
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoAparelhos" checked={this.state.acessoAparelhos} onChange={(e) => this.setState({ acessoAparelhos: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoAparelhos">
                                                                Aparelhos
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='alert alert-secondary'>
                                                    <h5>Configurações</h5>
                                                    <div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarConfiguracoesParametros" checked={this.state.acessoHabilitarConfiguracoesParametros} onChange={(e) => this.setState({ acessoHabilitarConfiguracoesParametros: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarConfiguracoesParametros">
                                                                Parametros
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarConfiguracoesDadosCadastrais" checked={this.state.acessoHabilitarConfiguracoesDadosCadastrais} onChange={(e) => this.setState({ acessoHabilitarConfiguracoesDadosCadastrais: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarConfiguracoesDadosCadastrais">
                                                                Dados cadastrais
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoHabilitarConfiguracoesLicenca" checked={this.state.acessoHabilitarConfiguracoesLicenca} onChange={(e) => this.setState({ acessoHabilitarConfiguracoesLicenca: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoHabilitarConfiguracoesLicenca">
                                                                Licença
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-2">
                                                    <AlertDanger message="Deixe habilitado caso permita que este perfil de acesso faça determinados cadatros ou edições." />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="PDV" title="PDV">
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="habilitarVendaAvulsa" checked={this.state.habilitarVendaAvulsa} onChange={(e) => this.setState({ habilitarVendaAvulsa: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarVendaAvulsa">
                                                    Habilitar venda de produtos e serviços de forma avulsa.
                                                </label>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="habilitarRemoverProdutoOutroOperador" checked={this.state.habilitarRemoverProdutoOutroOperador} onChange={(e) => this.setState({ habilitarRemoverProdutoOutroOperador: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" for="habilitarRemoverProdutoOutroOperador">
                                                    Permitir remover um produto lançado por outro operador.
                                                </label>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Pagamentos" title="Pagamentos">
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="acessoPagamentosResumo" checked={this.state.acessoPagamentosResumo} onChange={(e) => this.setState({ acessoPagamentosResumo: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="acessoPagamentosResumo">
                                                    Permitir visualizar resumo de pagamentos.
                                                </label>
                                                <span className='mx-2'>
                                                    <span className="badge text-bg-success">Novidade</span>
                                                </span>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="acessoHabilitarConfiguracoesCobrancas" checked={this.state.acessoHabilitarConfiguracoesCobrancas} onChange={(e) => this.setState({ acessoHabilitarConfiguracoesCobrancas: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="acessoHabilitarConfiguracoesCobrancas">
                                                    Permitir configurar cobranças.
                                                </label>
                                                <span className='mx-2'>
                                                    <span className="badge text-bg-success">Novidade</span>
                                                </span>
                                            </div>
                                        </Tab>
                                        {/* <Tab eventKey="crm" title="CRM">
                                            <div className='mt-2'>
                                                <AlertSuccess tag="Novidade" message="Tenha mais proximidade com o seu cliente." />
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="accessWebSite" checked={this.state.acesssCRMContact} onChange={(e) => this.setState({ acesssCRMContact: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="accessWebSite">
                                                    Permitir visualizar contatos salvos
                                                </label>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="accessWebSite" checked={this.state.createCRMContact} onChange={(e) => this.setState({ createCRMContact: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="accessWebSite">
                                                    Permitir cadastrar novos contatos
                                                </label>
                                            </div>
                                        </Tab> */}
                                        <Tab eventKey="seguranca" title="Segurança">
                                            <div className='mt-2'>
                                                <AlertDanger message="Só permita acesso ao administrativo usuários chaves na sua organização." />
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="accessWebSite" checked={this.state.accessWebSite || (this.state.accessWebSite === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ accessWebSite: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="accessWebSite">
                                                    Permitir que o usuário acesse o administrativo
                                                </label>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="accessSmartphone" checked={this.state.accessSmartphone || (this.state.accessSmartphone === undefined && this.state.profileId !== null)} onChange={(e) => this.setState({ accessSmartphone: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="accessSmartphone">
                                                    Permitir que o usuário acesse o sistema no smartphone
                                                </label>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ListarPerfis onEdit={this.onEditProfile} onRemove={this.onRemove} onDuplicate={this.onDuplicate} />
                </NavBar >
            </>
        )
    }
}

class ListarPerfis extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: getCliente()?.id,
            items: [],
            isLoading: true
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const items = await getAllProfilesByClientId({ clientId: this.state.clienteId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    render() {
        return (
            <div className="card my-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os perfis
                </div>
                {
                    this.state.items.length === 0 && !this.state.isLoading ?
                        <EmptyContent text='Nenhum perfil cadastrado' />
                        :
                        !this.state.isLoading ?
                            <div className='row'>
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th width="90"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.nome}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {
                                                                    this.props.onDuplicate &&
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onEdit &&
                                                                    <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onRemove &&
                                                                    <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}

export { CriarPerfil, ListarPerfis }