import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../components/common/commons'
import { Tabs, Tab } from 'react-bootstrap';
import firebase from '../../config/firebase';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import NumberFormat from 'react-number-format';
import { sendClickButton } from '../../shared/analytics'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import 'firebase/auth';
import 'firebase/storage';
import './index.css'
import { getAllSectores, getAllSectoresByClientId } from '../../store/collections/setoresWorker'
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment'
import { hoursToMin, secondsToDescription, toastSuccess, toastWarning } from '../../shared/utils'
import { getAllRelationshipsByParkId } from '../../store/collections/relationshipWorker'
import { getParameter } from '../../store/collections/parameterWorker'
import Tooltip from '@mui/material/Tooltip';
import { fetchFunction } from '../../shared/function-request'
import { deletePaymentTableById, getAllPaymentTables } from '../../store/collections/paymentTableWorker'
import { AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager';
import Select from 'react-select';
import { getAllUsersActiveByClientId } from '../../store/collections/userWorker';
import { addDoc, updateDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';

const initializeState = {
    key: 'periodos',
    id: '',
    clienteId: getCliente()?.id,
    estacionamentoId: getEstacionamento()?.id,
    revendaId: getRevendaIdByPark(),
    nome: '',
    tipo: 'carro',
    periodos: [],
    isEdicao: false,
    horarioInicio: '00:00',
    horarioFim: '00:00',
    status: 'true',
    obrigarPagamentoEntrada: false,
    cobrarAntecipadoValorPorPermanencia: false,
    obrigarCobrancaCasoUltrapassePrimeiroPeriodo: false,
    baixarTicketAoFazerPagamentoObrigatorioNaEntrada: false,
    imprimirDuasViasDeEntrada: false,
    imprimirDuasViasDePagamento: false,
    permitirPagamentoAntecipado: false,
    habilitarVendaDeDiaria: false,
    cobrarHorasAdicionaisAposPeriodoMaximo: false,
    toleranciaPermanencia: '',
    toleranciaPosPermanencia: '',
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
    isAllDay: false,
    habilitarVinculacaoNoTotem: true,
    manterToleranciaPermanenciaNaSaida: false,
    imprimirToleranciaPermanenciaPosPagamento: false,
    imprimirToleranciaPermanencia: false,
    periodoMaximo: '00:00',
    periodo: '00:00',
    periodoFormat: '##:##',
    periodoMaximoFormat: '##:##',
    setorAssociadoId: "",
    setores: [],
    convenioAssociadoId: "",
    valorMaximo: 0.0,
    valorHoraAdicional: 0.0,
    tipoHoraAdicional: "60",
    permitirSalvarSaida: false,
    direcionarOperadorPara: "DETAILS",
    noLancamentoDirecionarOperadorPara: "DETAILS",
    mensagemTicketAoDarEntrada: "",
    typePayment: "NORMAL_DELIVERY",
    startDate: moment(),
    deliveryDate: new moment().add(1, 'hours'),
    maxPermanencePosPayment: new moment(),
    relationshipId: "",
    relationships: [],
    relationship: null,
    habilitarControleAcessoPorUsuario: false,
    x_api_key: "",
    tablesConflict: []
}

class CriarTabela extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
        this._loadParameters()
        this._loadSectors()
        this._loadRelationships()
    }

    componentDidMount = async () => {
        await this.simulateCalculation()
    }

    _loadParameters = async () => {
        const parameter = await getParameter()
        this.setState({ x_api_key: parameter?.X_API_KEY })
        this.setState({ ...parameter })
    }

    _loadSectors = async () => {
        var allSetores = await getAllSectoresByClientId({ clienteId: this.state.clienteId })
        this.setState({ setores: allSetores });
    }

    _loadRelationships = async () => {
        var list = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ relationships: list });
    }

    reset = () => {
        var estacionamentoId = this.state.estacionamentoId
        this.setState({ ...initializeState })
        this.setState({ estacionamentoId: estacionamentoId })
        this.setState({ resultSimulate: null })
    }

    update = async () => {
        if (!this.checkFields()) { return }
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            revendaId: this.state.revendaId,
            nome: this.state.nome,
            periodos: Object.assign([], this.state.periodos),
            status: this.state.status,
            tipo: this.state.tipo,
            valorMaximo: this.state.valorMaximo,
            obrigarPagamentoEntrada: this.state.obrigarPagamentoEntrada,
            cobrarAntecipadoValorPorPermanencia: this.state.cobrarAntecipadoValorPorPermanencia,
            obrigarCobrancaCasoUltrapassePrimeiroPeriodo: this.state.obrigarCobrancaCasoUltrapassePrimeiroPeriodo,
            baixarTicketAoFazerPagamentoObrigatorioNaEntrada: this.state.baixarTicketAoFazerPagamentoObrigatorioNaEntrada,
            periodoMaximo: this.state.periodoMaximo,
            valorHoraAdicional: this.state.valorHoraAdicional,
            isAllDay: this.state.isAllDay,
            permitirSalvarSaida: this.state.permitirSalvarSaida,
            imprimirDuasViasDeEntrada: this.state.imprimirDuasViasDeEntrada,
            imprimirDuasViasDePagamento: this.state.imprimirDuasViasDePagamento,
            permitirPagamentoAntecipado: this.state.permitirPagamentoAntecipado,
            habilitarVendaDeDiaria: this.state.habilitarVendaDeDiaria,
            toleranciaPermanencia: this.state.toleranciaPermanencia,
            toleranciaPosPermanencia: this.state.toleranciaPosPermanencia,
            cobrarHorasAdicionaisAposPeriodoMaximo: this.state.cobrarHorasAdicionaisAposPeriodoMaximo,
            tipoHoraAdicional: this.state.tipoHoraAdicional,
            setorAssociadoId: this.state.setorAssociadoId,
            convenioAssociadoId: this.state.convenioAssociadoId,
            direcionarOperadorPara: this.state.direcionarOperadorPara,
            noLancamentoDirecionarOperadorPara: this.state.noLancamentoDirecionarOperadorPara,
            mensagemTicketAoDarEntrada: this.state.mensagemTicketAoDarEntrada,
            manterToleranciaPermanenciaNaSaida: String(this.state.manterToleranciaPermanenciaNaSaida).toLowerCase() === "true",
            imprimirToleranciaPermanenciaPosPagamento: String(this.state.imprimirToleranciaPermanenciaPosPagamento).toLowerCase() === "true",
            imprimirToleranciaPermanencia: this.state.imprimirToleranciaPermanencia,
            usersAllowed: this.state.usersAllowed,
            habilitarControleAcessoPorUsuario: this.state.habilitarControleAcessoPorUsuario,
            habilitarVinculacaoNoTotem: this.state.habilitarVinculacaoNoTotem,
            semanas: {
                horarioInicio: this.state.horarioInicio,
                horarioFim: this.state.horarioFim,
                dias: {
                    segunda: this.state.segunda,
                    terca: this.state.terca,
                    quarta: this.state.quarta,
                    quinta: this.state.quinta,
                    sexta: this.state.sexta,
                    sabado: this.state.sabado,
                    domingo: this.state.domingo
                }
            },
            alterAt: new Date(),
            alterBy: getUsuario().email
        }
        await updateDoc({ collection: Collection.PRICE_TABLE, id: this.state.id, data: data })
        await saveLastUpdate()
        toastSuccess('Tabela de preço atualizado com sucesso!');
        sendClickButton("Tabela de preços", "Atualizar", true, data)
        this.setState({ random: Math.random() })
        this.reset()
        this._loadSectors()
    }

    add = async () => {
        if (!this.checkFields()) { return }
        var fields = {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            revendaId: this.state.revendaId,
            nome: this.state.nome,
            periodos: Object.assign([], this.state.periodos),
            tipo: this.state.tipo,
            valorMaximo: this.state.valorMaximo,
            obrigarPagamentoEntrada: this.state.obrigarPagamentoEntrada,
            periodoMaximo: this.state.periodoMaximo,
            valorHoraAdicional: this.state.valorHoraAdicional,
            cobrarAntecipadoValorPorPermanencia: this.state.cobrarAntecipadoValorPorPermanencia,
            obrigarCobrancaCasoUltrapassePrimeiroPeriodo: this.state.obrigarCobrancaCasoUltrapassePrimeiroPeriodo,
            baixarTicketAoFazerPagamentoObrigatorioNaEntrada: this.state.baixarTicketAoFazerPagamentoObrigatorioNaEntrada,
            imprimirDuasViasDeEntrada: this.state.imprimirDuasViasDeEntrada,
            imprimirDuasViasDePagamento: this.state.imprimirDuasViasDePagamento,
            permitirPagamentoAntecipado: this.state.permitirPagamentoAntecipado,
            habilitarVendaDeDiaria: this.state.habilitarVendaDeDiaria,
            toleranciaPermanencia: this.state.toleranciaPermanencia,
            toleranciaPosPermanencia: this.state.toleranciaPosPermanencia,
            cobrarHorasAdicionaisAposPeriodoMaximo: this.state.cobrarHorasAdicionaisAposPeriodoMaximo,
            status: this.state.status,
            isAllDay: this.state.isAllDay,
            permitirSalvarSaida: this.state.permitirSalvarSaida,
            tipoHoraAdicional: this.state.tipoHoraAdicional,
            setorAssociadoId: this.state.setorAssociadoId,
            convenioAssociadoId: this.state.convenioAssociadoId,
            direcionarOperadorPara: this.state.direcionarOperadorPara,
            noLancamentoDirecionarOperadorPara: this.state.noLancamentoDirecionarOperadorPara,
            mensagemTicketAoDarEntrada: this.state.mensagemTicketAoDarEntrada,
            manterToleranciaPermanenciaNaSaida: String(this.state.manterToleranciaPermanenciaNaSaida).toLowerCase() === "true",
            imprimirToleranciaPermanenciaPosPagamento: String(this.state.imprimirToleranciaPermanenciaPosPagamento).toLowerCase() === "true",
            imprimirToleranciaPermanencia: this.state.imprimirToleranciaPermanencia,
            usersAllowed: this.state.usersAllowed,
            habilitarControleAcessoPorUsuario: this.state.habilitarControleAcessoPorUsuario,
            habilitarVinculacaoNoTotem: this.state.habilitarVinculacaoNoTotem,
            semanas: {
                horarioInicio: this.state.horarioInicio,
                horarioFim: this.state.horarioFim,
                dias: {
                    segunda: this.state.segunda,
                    terca: this.state.terca,
                    quarta: this.state.quarta,
                    quinta: this.state.quinta,
                    sexta: this.state.sexta,
                    sabado: this.state.sabado,
                    domingo: this.state.domingo
                }
            },
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        };
        await addDoc({ collection: Collection.PRICE_TABLE, data: fields })
        await saveLastUpdate()
        toastSuccess('Tabela de preços cadastrado com sucesso!');
        sendClickButton("Tabela de preços", "Cadastrar", true, fields)
        this.setState({ random: Math.random() })
        this.reset()
    }

    checkFields = () => {
        if (this.state.estacionamentoId === '') {
            toastWarning("Informe o estacionamento")
            return false
        }
        if (this.state.nome === '') {
            toastWarning("Informe o nome")
            return false
        }
        if (this.state.segunda === false &&
            this.state.terca === false &&
            this.state.quarta === false &&
            this.state.quinta === false &&
            this.state.sexta === false &&
            this.state.sabado === false &&
            this.state.domingo === false) {
            toastWarning("Informe pelo menos um dia da semana para esta tabela de preços")
            this.setState({ key: 'parametros' })
            return false
        }
        if (this.state.periodoMaximo === '') {
            toastWarning("Informe o período máximo")
            this.setState({ key: 'periodos' })
            return false
        }
        if (this.state.isAllDay === false && (parseInt(this.state.horarioInicio.replace(":", "")) === 0 ||
            parseInt(this.state.horarioFim.replace(":", "")) === 0)) {
            toastWarning("Informe o horário início e horário fim")
            this.setState({ key: 'parametros' })
            return false
        }
        return true
    }

    onEdit = (item) => {
        this.setState({ isEdicao: true })
        this._fillItem(item)
    }

    onDuplicate = (item) => {
        item.id = null
        this._fillItem(item)
    }

    _fillItem(item) {
        this.setState({ id: item.id })
        this.setState({ nome: item.nome })
        this.setState({ status: item.status })
        this.setState({ periodos: item.periodos.sort((a, b) => parseInt(a.periodo.replaceAll(":", "").replaceAll("_", "0")) - parseInt(b.periodo.replaceAll(":", "").replaceAll("_", "0"))) })
        this.setState({ periodoMaximo: item.periodoMaximo ?? '00:00' })
        this.setState({ obrigarPagamentoEntrada: item.obrigarPagamentoEntrada })
        this.setState({ cobrarAntecipadoValorPorPermanencia: item.cobrarAntecipadoValorPorPermanencia ?? false })
        this.setState({ obrigarCobrancaCasoUltrapassePrimeiroPeriodo: item.obrigarCobrancaCasoUltrapassePrimeiroPeriodo ?? false })
        this.setState({ cobrarHorasAdicionaisAposPeriodoMaximo: item.cobrarHorasAdicionaisAposPeriodoMaximo ?? false })
        this.setState({ imprimirDuasViasDeEntrada: item.imprimirDuasViasDeEntrada ?? false })
        this.setState({ imprimirDuasViasDePagamento: item.imprimirDuasViasDePagamento ?? false })
        this.setState({ permitirPagamentoAntecipado: item.permitirPagamentoAntecipado ?? false })
        this.setState({ habilitarVendaDeDiaria: item.habilitarVendaDeDiaria ?? false })
        this.setState({ manterToleranciaPermanenciaNaSaida: item.manterToleranciaPermanenciaNaSaida ?? false })
        this.setState({ imprimirToleranciaPermanenciaPosPagamento: item.imprimirToleranciaPermanenciaPosPagamento ?? true })
        this.setState({ baixarTicketAoFazerPagamentoObrigatorioNaEntrada: item.baixarTicketAoFazerPagamentoObrigatorioNaEntrada ?? false })
        this.setState({ habilitarVinculacaoNoTotem: item.habilitarVinculacaoNoTotem ?? true })
        this.setState({ imprimirToleranciaPermanencia: item.imprimirToleranciaPermanencia ?? true })
        this.setState({ toleranciaPermanencia: item.toleranciaPermanencia ?? '' })
        this.setState({ toleranciaPosPermanencia: item.toleranciaPosPermanencia ?? '' })
        this.setState({ tipoHoraAdicional: item.tipoHoraAdicional ?? '60' })
        this.setState({ isAllDay: item.isAllDay ?? false })
        this.setState({ permitirSalvarSaida: item.permitirSalvarSaida ?? false })
        this.setState({ setorAssociadoId: item.setorAssociadoId ?? '' })
        this.setState({ convenioAssociadoId: item.convenioAssociadoId ?? '' })
        this.setState({ valorMaximo: item.valorMaximo })
        this.setState({ tipo: item.tipo })
        this.setState({ valorHoraAdicional: item.valorHoraAdicional })
        this.setState({ horarioInicio: item.semanas.horarioInicio })
        this.setState({ horarioFim: item.semanas.horarioFim })
        this.setState({ segunda: item.semanas.dias.segunda })
        this.setState({ terca: item.semanas.dias.terca })
        this.setState({ quarta: item.semanas.dias.quarta })
        this.setState({ quinta: item.semanas.dias.quinta })
        this.setState({ sexta: item.semanas.dias.sexta })
        this.setState({ sabado: item.semanas.dias.sabado })
        this.setState({ domingo: item.semanas.dias.domingo })
        this.setState({ direcionarOperadorPara: item.direcionarOperadorPara ?? "DETAILS" })
        this.setState({ noLancamentoDirecionarOperadorPara: item.noLancamentoDirecionarOperadorPara ?? "DETAILS" })
        this.setState({ usersAllowed: item.usersAllowed ?? null })
        this.setState({ habilitarControleAcessoPorUsuario: item.habilitarControleAcessoPorUsuario ?? false })
        if (item.habilitarControleAcessoPorUsuario === true) {
            this.loadAllUsersByAllowed()
        }
        this.setState({ mensagemTicketAoDarEntrada: item.mensagemTicketAoDarEntrada ?? "" }, () => {
            this.simulateCalculation()
        })
    }

    //Periodos
    onAddPeriodo = () => {
        if (this.state.periodo === undefined) {
            return
        }
        const period = parseInt(this.state.periodo.replaceAll(":", "").replaceAll("_", "0"))
        if (period <= 0) {
            toastWarning("Informe um período maior que zero.")
            return
        }
        const periodPrice = (this.state.periodoValor ?? 0)
        if (periodPrice <= 0) {
            toastWarning("Informe um valor maior que zero.")
            return
        }
        const newList = this.state.periodos.filter((e) => e.periodo === this.state.periodo)
        if (newList.length > 0) {
            toastWarning("Este período já esta adicionado.")
            return
        }
        var list = this.state.periodos
        list.push({
            id: Math.floor(Math.random() * 1000) + 1,
            valor: this.state.periodoValor ?? 0,
            periodo: this.state.periodo.replaceAll("_", "")
        })
        this.setState({ periodos: list.sort((a, b) => parseInt(a.periodo.replaceAll(":", "").replaceAll("_", "0")) - parseInt(b.periodo.replaceAll(":", "").replaceAll("_", "0"))) }, () => {
            this.simulateCalculation()
        })
        this.setState({ periodo: this.state.periodoFormat === "##:##" ? "00:00" : "000:00" })
        this.setState({ periodoValor: 0.0 })
    }


    changeType = (e) => {
        const type = e.target.value
        this.setState({ periodoFormat: type })
        this.setState({ periodo: type === "##:##" ? "00:00" : "000:00" })
    }

    changePeriodoMaximoType = (e) => {
        const type = e.target.value
        this.setState({ periodoMaximoFormat: type })
        this.setState({ periodoMaximo: type === "##:##" ? "00:00" : "000:00" })
    }

    onRemovePeriodo = (periodo) => {
        var list = this.state.periodos
        list = list.filter((item) => { return item.id !== periodo.id })
        this.setState({ periodos: list.sort((a, b) => parseInt(a.periodo.replaceAll(":", "").replaceAll("_", "0")) - parseInt(b.periodo.replaceAll(":", "").replaceAll("_", "0"))) }, () => {
            this.simulateCalculation()
        })
    }

    onChangeRelationship = (e) => {
        const id = e.target.value
        if (id === "") {
            this.setState({ relationshipId: "" }, () => {
                this.setState({ relationship: null }, () => {
                    this.simulateCalculation()
                })
            })
        } else {
            this.setState({ relationshipId: id }, () => {
                const relationship = this.state.relationships.filter(f => f.id === id)[0]
                this.setState({ relationship: relationship }, () => {
                    this.simulateCalculation()
                })
            })
        }
    }

    handleStartSimulateDateChange = (newValue) => {
        this.setState({ startDate: newValue }, () => {
            this.simulateCalculation()
        })
    };

    handleEndSimulateDateChange = (newValue) => {
        this.setState({ deliveryDate: newValue }, () => {
            this.simulateCalculation()
        })
    };

    onChangePaymentInTicket = (e) => {
        this.setState({ obrigarPagamentoEntrada: e.target.checked }, () => {
            this.setState({ typePayment: "EARLY_DELIVERY" }, () => {
                this.simulateCalculation()
            })
        })
    }

    onChangePaymentAllowPaymentInTicket = (e) => {
        this.setState({ permitirPagamentoAntecipado: e.target.checked }, () => {
            if (!this.state.obrigarPagamentoEntrada) {
                this.setState({ typePayment: "NORMAL_DELIVERY" }, () => {
                    this.simulateCalculation()
                })
            } else {
                this.simulateCalculation()
            }
        })
    }

    onChangeTypePayment = (e) => {
        this.setState({ typePayment: e.target.value }, () => {
            this.simulateCalculation()
        })
    }

    updateStartDate = () => {
        this.setState({ startDate: moment() }, () => {
            this.simulateCalculation()
        })
    }

    onChangeAdditionalHours = (e) => {
        this.setState({ cobrarHorasAdicionaisAposPeriodoMaximo: e.target.checked }, () => {
            this.simulateCalculation()
        })
    }

    onLoaded = (tables) => {
        this.setState({ tables: tables }, () => {
            this.validateTableConflict()
        })
    }

    validateTableConflict = () => {
        const tables = this.state.tables
        const horarioInicio = hoursToMin(this.state.horarioInicio)
        const horarioFim = hoursToMin(this.state.horarioFim)
        var tablesConflict = tables.filter(e =>
            e.id !== this.state.id &&
            e.tipo === this.state.tipo &&
            (e.isAllDay == true || (horarioInicio >= hoursToMin(e.semanas.horarioInicio) && horarioFim <= hoursToMin(e.semanas.horarioFim)))
        )
        if (!(this.state.segunda && tablesConflict.filter(e => e.semanas.dias.segunda).length > 0 ||
            this.state.terca && tablesConflict.filter(e => e.semanas.dias.terca).length > 0 ||
            this.state.quarta && tablesConflict.filter(e => e.semanas.dias.quarta).length > 0 ||
            this.state.quinta && tablesConflict.filter(e => e.semanas.dias.quinta).length > 0 ||
            this.state.sexta && tablesConflict.filter(e => e.semanas.dias.sexta).length > 0 ||
            this.state.sabado && tablesConflict.filter(e => e.semanas.dias.sabado).length > 0 ||
            this.state.domingo && tablesConflict.filter(e => e.semanas.dias.domingo).length > 0)) {
            tablesConflict = []
        }
        this.setState({ tablesConflict: tablesConflict })
    }

    updateTipo = (e) => {
        this.setState({ tipo: e.target.value }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekSegunda = (e) => {
        this.setState({ segunda: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekTerca = (e) => {
        this.setState({ terca: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekQuarta = (e) => {
        this.setState({ quarta: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekQuinta = (e) => {
        this.setState({ quinta: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekSexta = (e) => {
        this.setState({ sexta: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekSabado = (e) => {
        this.setState({ sabado: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekDomingo = (e) => {
        this.setState({ domingo: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekIsAllDay = (e) => {
        if (e.target.checked) {
            this.setState({ horarioInicio: "00:00" })
            this.setState({ horarioFim: "00:00" })
        }
        this.setState({ isAllDay: e.target.checked }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekHorarioInicio = (e) => {
        this.setState({ horarioInicio: e.target.value }, () => {
            this.validateTableConflict()
        })
    }

    updateWeekHorarioFim = (e) => {
        this.setState({ horarioFim: e.target.value }, () => {
            this.validateTableConflict()
        })
    }

    simulateCalculation = async () => {
        var body = {
            type: this.state.typePayment,
            startDate: this.state.startDate.format("YYYY-MM-DD[T]HH:mm:ss"),
            endDate: this.state.deliveryDate.format("YYYY-MM-DD[T]HH:mm:ss"),
            ticket: {
                status: "Estacionado"
            },
            table: {
                maximumPeriod: this.state.periodoMaximo,
                maximumPrice: this.state.valorMaximo,
                additionalHourPrice: this.state.valorHoraAdicional,
                tolerance: this.state.toleranciaPermanencia,
                tolerancePosPayment: this.state.toleranciaPosPermanencia,
                chargeAdditionalHoursAfterMaximumPeriod: this.state.cobrarHorasAdicionaisAposPeriodoMaximo,
                chargeEarlyValuePermanence: this.state.cobrarAntecipadoValorPorPermanencia,
                periods: this.state.periodos.map(e => {
                    return {
                        period: e.periodo,
                        price: e.valor
                    }
                })
            },
        }
        if (this.state.relationship) {
            body.partnership = {
                tolerance: this.state.relationship.tolerancia,
                keepDiscount: this.state.relationship.manterDesconto === "Sim",
                type: this.state.relationship.tipo === "Valor" ? "price" : "percentage",
                price: this.state.relationship.valor,
                percentage: this.state.relationship.porcentagem
            }
        }
        this.setState({ isSimulateLoading: true })
        const options = {
            method: 'POST',
            body: body
        };
        const result = await fetchFunction("/table/simulate-calculation", options)
        if (result.success) {
            this.setState({ limitPermanenceInDate: moment(result.data.limitPermanenceInDate) })
            this.setState({ resultSimulate: result })
        }
        this.setState({ isSimulateLoading: false })
    }

    loadAllUsersByAllowed = async () => {
        const users = await getAllUsersActiveByClientId({ clienteId: this.state.clienteId })
        var mapped = users.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allUsers: mapped })
        this.setState({ randomUserAllowed: Math.random() })
    }

    enableUserAllowed = async (e) => {
        this.setState({ habilitarControleAcessoPorUsuario: e.target.checked })
        if (e.target.checked && !this.state.allUsers) {
            this.loadAllUsersByAllowed()
        }
    }

    handleUsers = (selected) => {
        this.setState({ usersAllowed: selected })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            {this.state.isEdicao === true ? 'Atualizar tabela de preços' : 'Cadastrar tabela de preços'}
                            <HelpButton />
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className='col-lg-12'>
                                        <div className='row'>
                                            <div className="col-lg-3">
                                                <label>Status</label>
                                                <div className="input-group mb-3">
                                                    <select className="form-select" onChange={(e) => this.setState({ status: e.target.value })} value={this.state.status}>
                                                        <option value="true">Ativo</option>
                                                        <option value="false">Inativo</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Tipo</label>
                                                <div className="input-group mb-3">
                                                    <select className="form-select" onChange={this.updateTipo} value={this.state.tipo}>
                                                        <option value="carro">Carro</option>
                                                        <option value="carro_grande">Carro Grande</option>
                                                        <option value="moto">Moto</option>
                                                        <option value="bicicleta">Bicicleta</option>
                                                        <option value="onibus">Ônibus</option>
                                                        <option value="caminhao">Caminhão</option>
                                                        <option value="van_furgao">Van/Furgão</option>
                                                        <option value="outros">Outros</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Nome</label>
                                                <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome && this.state.nome} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                                            <Tab eventKey="periodos" title="Períodos">
                                                <div className="row">
                                                    <div className='col-lg-12 my-2'>
                                                        <div className='mt-2'>
                                                            <AlertSecondary message="Valor máximo é utilizado para pagamentos antecipados ( normalmente na entrada do veículo )." />
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-2">
                                                                <label>Formato</label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={this.changePeriodoMaximoType} value={this.state.periodoMaximoFormat}>
                                                                        <option value="##:##">00:00</option>
                                                                        <option value="###:##">000:00</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <label>Período máximo</label>
                                                                <div>
                                                                    <NumberFormat className="form-control" value={this.state.periodoMaximo} mask='_' onBlur={this.simulateCalculation} onChange={(e) => this.setState({ periodoMaximo: e.target.value })} format={this.state.periodoMaximoFormat} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Valor máximo</label>
                                                                <CurrencyInput prefix="R$" value={this.state.valorMaximo && this.state.valorMaximo} onBlur={this.simulateCalculation} className='form-control' onChangeEvent={(event, maskedvalue, floatvalue) => { this.setState({ valorMaximo: floatvalue }) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 my-2">
                                                        <div className='row'>
                                                            <div className="col-lg-2">
                                                                <label>Formato</label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={this.changeType} value={this.state.periodoFormat}>
                                                                        <option value="##:##">00:00</option>
                                                                        <option value="###:##">000:00</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Período</label>
                                                                <NumberFormat className="form-control" value={this.state.periodo} mask='_' onChange={(e) => this.setState({ periodo: e.target.value })} format={this.state.periodoFormat} />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Valor</label>
                                                                <CurrencyInput prefix="R$" value={this.state.periodoValor && this.state.periodoValor} className='form-control' onChangeEvent={(event, maskedvalue, floatvalue) => { this.setState({ periodoValor: floatvalue }) }} />
                                                            </div>
                                                            <div className='col-lg-3 my-4'>
                                                                <button type="button" onClick={(e) => { this.onAddPeriodo() }} className="btn btn-primary">
                                                                    <i className="fas fa-plus mx-2" /> Adicionar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        this.state.periodos.length !== 0 ?
                                                            <div className='table-responsive'>
                                                                <table className="table table-striped ">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Período</th>
                                                                            <th scope="col">Valor</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.periodos.map(periodo =>
                                                                                <tr key={periodo.id}>
                                                                                    <td>{periodo.periodo}</td>
                                                                                    <td>
                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={periodo.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                    </td>
                                                                                    <td width='10'>
                                                                                        <button type="button" onClick={(e) => { this.onRemovePeriodo(periodo) }} className="btn btn-danger">
                                                                                            <i className="fas fa-trash" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : <EmptyContent text='Nenhum período adicionado' />
                                                    }
                                                </div>
                                            </Tab>
                                            <Tab eventKey="horas_adicionais" title={this.state.tipoHoraAdicional === '1' ? "Minutos adicionais" : "Horas adicionais"}>
                                                <div className="row">
                                                    <div className="col-lg-12 my-2">
                                                        <div className='mt-2'>
                                                            {
                                                                this.state.tipoHoraAdicional === '1' ?
                                                                    <AlertSecondary message="Exemplo: O período de 1 minuto custa R$ 0,20. A partir de 1 minuto adicional custa um acréscimo de R$ 0,50, o veiculo ficando estacionado por 30 minutos ficaria um total de R$ 15,20." />
                                                                    :
                                                                    <AlertSecondary message="Exemplo: O período de 1 hora custa R$ 5,00. A partir de 1 hora adicional custa um acréscimo de R$ 3,00, o veiculo ficando estacionado por 3 horas ficaria um total de R$ 11,00." />
                                                            }
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                                <label>Valor da hora adicional</label>
                                                                <CurrencyInput prefix="R$" onBlur={this.simulateCalculation} value={this.state.valorHoraAdicional && this.state.valorHoraAdicional} className='form-control' onChangeEvent={(event, maskedvalue, floatvalue) => { this.setState({ valorHoraAdicional: floatvalue }) }} />
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" id="cobrarHorasAdicionaisAposPeriodoMaximo" checked={this.state.cobrarHorasAdicionaisAposPeriodoMaximo} onChange={this.onChangeAdditionalHours} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="cobrarHorasAdicionaisAposPeriodoMaximo">
                                                                        Cobrar por hora adicional após ultrapassar o período máximo
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                {
                                                                    this.state.cobrarHorasAdicionaisAposPeriodoMaximo === true ?
                                                                        <AlertSecondary message="Exemplo: Se tiver um período máximo de 12 horas por 15 reais, o valor de horas adicionais por 5 reais e o cliente esta há 15 horas, o valor cobrado será: 30 reais" />
                                                                        :
                                                                        <AlertSecondary message="Exemplo: Se tiver um período máximo de 12 horas por 15 reais, com o cliente por 20 horas, será cobrado o período de 12h e o calculo será feito novamente para calcular as 8 horas faltantes." />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="parametros" title="Parâmetros">
                                                <div className="row">
                                                    <div className="col-lg-12 my-2">
                                                        {
                                                            this.state.tablesConflict.length > 0 &&
                                                            <div className='col-lg-12'>
                                                                <div className={`alert alert-danger`}>
                                                                    <span className={`badge text-bg-danger`}>Atenção</span> <br />
                                                                    Este horário ou dia da semana já esta sendo utilizado nas tabelas a seguir: <br />
                                                                    <strong>{this.state.tablesConflict.map(e => e.nome).join(", ")}</strong>. <p />
                                                                    Em locais com entrada automática de veículo será selecionada sempre primeira tabela criada.
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='col-lg-12 mt-2'>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="segunda" checked={this.state.segunda} onChange={this.updateWeekSegunda} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="segunda">
                                                                    Segunda-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="terca" checked={this.state.terca} onChange={this.updateWeekTerca} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="terca">
                                                                    Terça-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="quarta" checked={this.state.quarta} onChange={this.updateWeekQuarta} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="quarta">
                                                                    Quarta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="quinta" checked={this.state.quinta} onChange={this.updateWeekQuinta} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="quinta">
                                                                    Quinta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="sexta" checked={this.state.sexta} onChange={this.updateWeekSexta} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="sexta">
                                                                    Sexta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="sabado" checked={this.state.sabado} onChange={this.updateWeekSabado} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="sabado">
                                                                    Sábado
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="domingo" checked={this.state.domingo} onChange={this.updateWeekDomingo} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="domingo">
                                                                    Domingo
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div className="form-check form-check-inline mt-3">
                                                                    <input className="form-check-input" id="isAllDay" checked={this.state.isAllDay} onChange={this.updateWeekIsAllDay} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="isAllDay" >
                                                                        Esta tabela é 24 horas.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.isAllDay === false &&
                                                            <div className='row'>
                                                                <div className='col-lg-3'>
                                                                    <label>Horário inicial</label>
                                                                    <NumberFormat className="form-control" value={this.state.horarioInicio} mask='_' onChange={this.updateWeekHorarioInicio} format="##:##" />
                                                                </div>
                                                                <div className='col-lg-3'>
                                                                    <label>Horário final</label>
                                                                    <NumberFormat className="form-control" value={this.state.horarioFim} mask='_' onChange={this.updateWeekHorarioFim} format="##:##" />
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="form-check form-check-inline mt-3">
                                                            <input className="form-check-input" id="permitirPagamentoAntecipado" checked={this.state.permitirPagamentoAntecipado} onChange={this.onChangePaymentAllowPaymentInTicket} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="permitirPagamentoAntecipado">
                                                                Permitir pagamento antecipado ( a primeira cobrança será o <strong>valor máximo do período.</strong> )
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.permitirPagamentoAntecipado === true &&
                                                            <div className=" mt-3">
                                                                <AlertSuccess tag="Novidade" message="Será considerado o campo período máximo para multiplicar a quantidade de diárias.">
                                                                    <div>
                                                                        <div className="form-check form-check-inline mt-3">
                                                                            <input className="form-check-input" id="habilitarVendaDeDiaria" checked={this.state.habilitarVendaDeDiaria} onChange={(e) => this.setState({ habilitarVendaDeDiaria: e.target.checked })} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarVendaDeDiaria">
                                                                                Permitir vender pacote de diarias
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </AlertSuccess>
                                                            </div>
                                                        }
                                                        <div className="form-check form-check-inline mt-3">
                                                            <input className="form-check-input" id="obrigarPagamentoEntrada" checked={this.state.obrigarPagamentoEntrada} onChange={this.onChangePaymentInTicket} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="obrigarPagamentoEntrada">
                                                                Obrigar pagamento na entrada do veículo.
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.obrigarPagamentoEntrada === true &&
                                                            <>
                                                                <div className="form-check form-check-inline mt-3">
                                                                    <input className="form-check-input" id="baixarTicketAoFazerPagamentoObrigatorioNaEntrada" checked={this.state.baixarTicketAoFazerPagamentoObrigatorioNaEntrada} onChange={(e) => this.setState({ baixarTicketAoFazerPagamentoObrigatorioNaEntrada: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="baixarTicketAoFazerPagamentoObrigatorioNaEntrada">
                                                                        Dar baixa no ticket ( Pago e Entregue ) ao fazer o pagamento na entrada.
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="alert alert-secondary mt-3" role="alert">
                                                            O cliente deve pagar sempre que der entrada no veículo, normalmente utilizado em dia de eventos.
                                                        </div>
                                                        {
                                                            this.state.obrigarPagamentoEntrada === true &&
                                                            <>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" id="cobrarAntecipadoValorPorPermanencia" checked={this.state.cobrarAntecipadoValorPorPermanencia} onChange={(e) => this.setState({ cobrarAntecipadoValorPorPermanencia: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="cobrarAntecipadoValorPorPermanencia">
                                                                        Cobrar por pagamento antecipado o valor de permanência <strong> no lançamento do veículo </strong> ou <strong>no botão "Pagar" nos detalhes de ticket.</strong>
                                                                    </label>
                                                                </div>
                                                                {
                                                                    this.state.cobrarAntecipadoValorPorPermanencia === false ?
                                                                        <div className="alert alert-danger mt-3" role="alert">
                                                                            O valor pago no momento da entrada do veículo será o <strong>valor máximo do período.</strong>
                                                                        </div> :
                                                                        <div className="alert alert-danger mt-3" role="alert">
                                                                            O valor pago no momento da entrada do veículo sera o <strong>valor do primeiro período</strong> e caso ultrapasse no momento da entrega do veículo será cobrado novamente caso seja obrigatório.
                                                                        </div>
                                                                }
                                                            </>
                                                        }
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" id="obrigarCobrancaCasoUltrapassePrimeiroPeriodo" checked={this.state.obrigarCobrancaCasoUltrapassePrimeiroPeriodo} onChange={(e) => this.setState({ obrigarCobrancaCasoUltrapassePrimeiroPeriodo: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="obrigarCobrancaCasoUltrapassePrimeiroPeriodo">
                                                                Obrigar o pagamento da diferença caso ultrapasse o tempo máximo de permanência do primeiro período.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline my-2">
                                                            <input className="form-check-input" id="permitirSalvarSaida" checked={this.state.permitirSalvarSaida} onChange={(e) => this.setState({ permitirSalvarSaida: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="permitirSalvarSaida">
                                                                Permitir que o veículo saía e volte para o estacionamento sem dar baixa no ticket.
                                                            </label>
                                                        </div>
                                                        <div className="alert alert-secondary my-2" role="alert">
                                                            Permite fazer o pagamento e a entrega de um veículo sem dar baixa no pátio, quando o veículo voltar irá computar as horas desde a primeira entrada ( normalmente usado em hoteis quando um cliente deseja sair por um tempo e depois voltar )
                                                        </div>
                                                        <div className="form-check form-check-inline my-2">
                                                            <input className="form-check-input" id="imprimirDuasViasDeEntrada" checked={this.state.imprimirDuasViasDeEntrada} onChange={(e) => this.setState({ imprimirDuasViasDeEntrada: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="imprimirDuasViasDeEntrada">
                                                                Imprimir duas vias quando der entrada em um veículo.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline my-2">
                                                            <input className="form-check-input" id="imprimirDuasViasDePagamento" checked={this.state.imprimirDuasViasDePagamento} onChange={(e) => this.setState({ imprimirDuasViasDePagamento: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="imprimirDuasViasDePagamento">
                                                                Imprimir duas vias do recibo de pagamento do ticket.
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.obrigarPagamentoEntrada === false &&
                                                            <div className="my-2">
                                                                <label className="form-check-label" htmlFor="direcionarOperadorPara">
                                                                    Ao buscar o ticket pelo número redirecionar o operador para:
                                                                </label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={(e) => this.setState({ direcionarOperadorPara: e.target.value })} value={this.state.direcionarOperadorPara}>
                                                                        <option value="DETAILS">Detalhes do veículo lançado</option>
                                                                        <option value="PAYMENT">Tela de pagamento e entrega do veículo</option>
                                                                    </select>
                                                                </div>
                                                                {
                                                                    this.state.direcionarOperadorPara === "PAYMENT" &&
                                                                    <div className="alert alert-secondary my-2" role="alert">
                                                                        Sera apresentada a tela de pagamento caso o ticket já lançado esteja com o status <strong>Estacionado</strong>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="my-2">
                                                            <label className="form-check-label" htmlFor="noLancamentoDirecionarOperadorPara">
                                                                Ao fazer o lançamento de um ticket redirecionar o operador para:
                                                            </label>
                                                            <div className="input-group mb-3">
                                                                <select className="form-select" onChange={(e) => this.setState({ noLancamentoDirecionarOperadorPara: e.target.value })} value={this.state.noLancamentoDirecionarOperadorPara}>
                                                                    <option value="DETAILS">Detalhes do ticket para impressão</option>
                                                                    <option value="PRINT">Imprimir ao confirmar o lançamento</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="my-2">
                                                            <label className="form-check-label" htmlFor="mensagemTicketAoDarEntrada">
                                                                Imprimir mensagem no ticket de entrada do veículo.
                                                            </label>
                                                            <input className="form-control" id="mensagemTicketAoDarEntrada" placeholder='Exemplo: Primeira hora R$ 10.00, demais horas R$ 2.00 e R$ 20.00 período de 12 horas.' value={this.state.mensagemTicketAoDarEntrada && this.state.mensagemTicketAoDarEntrada} onChange={(e) => this.setState({ mensagemTicketAoDarEntrada: e.target.value })} type="text" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <label>Tipo de tolerância</label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={(e) => this.setState({ manterToleranciaPermanenciaNaSaida: e.target.value })} value={this.state.manterToleranciaPermanenciaNaSaida}>
                                                                        <option value={false}>Dar tolerância de permanencia apenas no primeiro período.</option>
                                                                        <option value={true}>Dar tolerância de permanencia no primeiro período e no último.</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Tolerância de permanência.</label>
                                                                <NumberFormat className="form-control" onBlur={this.simulateCalculation} value={this.state.toleranciaPermanencia} mask='_' onChange={(e) => this.setState({ toleranciaPermanencia: e.target.value })} format="##:##" />
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="imprimirToleranciaPermanencia" checked={this.state.imprimirToleranciaPermanencia} onChange={(e) => this.setState({ imprimirToleranciaPermanencia: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" for="imprimirToleranciaPermanencia">
                                                                        Imprimir no ticket
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Tolerância após pagamento.</label>
                                                                <NumberFormat className="form-control" onBlur={this.simulateCalculation} value={this.state.toleranciaPosPermanencia} mask='_' onChange={(e) => this.setState({ toleranciaPosPermanencia: e.target.value })} format="##:##" />
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="imprimirToleranciaPermanenciaPosPagamento" checked={this.state.imprimirToleranciaPermanenciaPosPagamento} onChange={(e) => this.setState({ imprimirToleranciaPermanenciaPosPagamento: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" for="imprimirToleranciaPermanenciaPosPagamento">
                                                                        Imprimir no ticket
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <AlertSecondary tag="Setor" message="Ao selecionar esta tabela de preço o setor é associado automaticamente ao ticket.">
                                                                    <div className="input-group mb-3">
                                                                        <select className="form-select" value={this.state.setorAssociadoId && this.state.setorAssociadoId} onChange={(e) => this.setState({ setorAssociadoId: e.target.value })} >
                                                                            <option key='1' value=''>Selecione</option>
                                                                            {
                                                                                this.state.setores.map(item => <option key={item.id} value={item.id}>{item.nome}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </AlertSecondary>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <AlertSecondary tag="Convênio" message="Ao selecionar esta tabela de preço o convênio é associado automaticamente ao ticket.">
                                                                    <div className="input-group mb-3">
                                                                        <select className="form-select" value={this.state.convenioAssociadoId && this.state.convenioAssociadoId} onChange={(e) => this.setState({ convenioAssociadoId: e.target.value })} >
                                                                            <option key='1' value=''>Selecione</option>
                                                                            {
                                                                                this.state.relationships.map(item => <option key={item.id} value={item.id}>{item.nome}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </AlertSecondary>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <AlertSecondary tag="Totem" message="Esta tabela de preço será atribuida via cancela caso esteja dentro do período acima.">
                                                                    <div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" id="habilitarVinculacaoNoTotem" checked={this.state.habilitarVinculacaoNoTotem} onChange={(e) => this.setState({ habilitarVinculacaoNoTotem: e.target.checked })} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarVinculacaoNoTotem">
                                                                                Habilitar vinculação da tabela de preço.
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </AlertSecondary>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="acessos" title="Acessos">
                                                <div className='col-lg-12 mt-3'>
                                                    <AlertWarning message="Defina quais usuários terão permissão para lançar esta tabela de preço.">
                                                        <div className="form-check form-switch mt-2">
                                                            <input className="form-check-input" id="habilitarControleAcessoPorUsuario" checked={this.state.habilitarControleAcessoPorUsuario} onChange={(e) => this.enableUserAllowed(e)} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarControleAcessoPorUsuario">
                                                                Habilitar controle de acesso por usuário.
                                                            </label>
                                                        </div>
                                                    </AlertWarning>
                                                    {this.state.habilitarControleAcessoPorUsuario &&
                                                        <div className='mt-2'>
                                                            <h5>Usuários</h5>
                                                            <div className="col-lg-12">
                                                                <Select
                                                                    key={this.state.randomUserAllowed}
                                                                    isMulti
                                                                    name="parks"
                                                                    defaultValue={this.state.usersAllowed}
                                                                    options={this.state.allUsers}
                                                                    onChange={this.handleUsers}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="Selecionar os usuários" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='col-lg-12'>
                                        <AlertSuccess tag="Dica" message="Você pode simular a configuração da tabela de preço com base no horário de saída do veículo.">
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <MobileDateTimePicker
                                                        label="Data e hora de entrada"
                                                        openTo="hours"
                                                        value={this.state.startDate}
                                                        onChange={this.handleStartSimulateDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                    />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <MobileDateTimePicker
                                                        label="Data e hora de saída"
                                                        openTo="hours"
                                                        value={this.state.deliveryDate}
                                                        onChange={this.handleEndSimulateDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.relationships.length > 0 &&
                                                <Tooltip role="button" title="Selecione um convênio para simular o desconto aplicado." placement="bottom">
                                                    <div className='col-lg-12 mt-2'>
                                                        <label>Selecionar convênio</label>
                                                        <select className='form-select' value={this.state.relationshipId} onChange={this.onChangeRelationship}>
                                                            <option value="">Selecione</option>
                                                            {
                                                                this.state.relationships?.map(item => {
                                                                    return <option value={item.id}>{item.nome}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </Tooltip>
                                            }
                                            {
                                                (this.state.permitirPagamentoAntecipado || this.state.obrigarPagamentoEntrada) &&
                                                <>
                                                    {
                                                        this.state.obrigarPagamentoEntrada ?
                                                            <div className='col-lg-12 mt-2'>
                                                                <label>Momento do pagamento</label>
                                                                <select className='form-select' value={this.state.typePayment} onChange={this.onChangeTypePayment}>
                                                                    <option value='EARLY_DELIVERY'>Pagamento na entrada do veículo</option>
                                                                </select>
                                                            </div> :
                                                            <div className='col-lg-12 mt-2'>
                                                                <label>Momento do pagamento</label>
                                                                <select className='form-select' value={this.state.typePayment} onChange={this.onChangeTypePayment}>
                                                                    <option value='NORMAL_DELIVERY'>Pagamento na entrega do veículo</option>
                                                                    <option value='EARLY_DELIVERY'>Pagamento na entrada do veículo</option>
                                                                </select>
                                                            </div>
                                                    }
                                                </>
                                            }
                                            <div>
                                                {
                                                    this.state.isSimulateLoading && <Loading />
                                                }
                                                {
                                                    !this.state.isSimulateLoading && this.state.resultSimulate &&
                                                    <div className='mt-4'>
                                                        <h5>Resultado</h5>
                                                        <table className='table'>
                                                            {
                                                                this.state.resultSimulate.data.tolerance &&
                                                                <tr>
                                                                    <td>Tolerância</td>
                                                                    <td align='right'>
                                                                        <span>{this.state.resultSimulate.data.tolerance}</span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td>Permanência</td>
                                                                <td align='right'>
                                                                    <span>{secondsToDescription((this.state.resultSimulate.data.permanence * 60))}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Permanência máxima</td>
                                                                <td align='right'>
                                                                    <span>{this.state.limitPermanenceInDate.format("DD/MM/YYYY [às] HH:mm")}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Valor estacionamento</td>
                                                                <td align='right'>
                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resultSimulate.data.parkingValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Valor desconto</td>
                                                                <td align='right'>
                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resultSimulate.data.discountValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Valor cobrado</td>
                                                                <td align='right'>
                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resultSimulate.data.totalValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                        </AlertSuccess>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className="btn-group">
                                {
                                    this.state.isEdicao === true && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                                }
                                <button type="button" onClick={this.state.isEdicao === false ? this.add : this.update} className="btn btn-primary">{this.state.isEdicao === true ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </div>
                    </div>
                    <ListagemTabelas key={this.state.random}
                        estacionamentoId={this.state.estacionamentoId}
                        onLoaded={this.onLoaded}
                        onEdit={this.onEdit}
                        onDuplicate={this.onDuplicate} />
                </NavBar >
            </>
        )
    }
}

class ListagemTabelas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: 1
        }
        this.onLoad()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onRemove = async (item) => {
        var confirm = window.confirm('Deseja mesmo remover a tabela de preços ' + item.nome + '?');
        if (confirm) {
            await deletePaymentTableById({ id: item.id })
            sendClickButton("Tabela de preços", "Deletar", true, item)
            await saveLastUpdate()
            this.onLoad()
        }
    }

    async onLoad() {
        this.setState({ loading: 1 });
        const paymentTables = await getAllPaymentTables({ estacionamentoId: this.props.estacionamentoId })
        if (this.props.onLoaded) {
            this.props.onLoaded(paymentTables)
        }
        this.setState({ items: paymentTables })
        this.setState({ loading: 0 });
    }

    render() {
        return (
            <>
                <div className="card mt-3">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todas as tabelas de preços
                    </div>
                    {
                        this.state.items.length === 0 && this.state.loading === 0 ?
                            <EmptyContent text='Nenhuma tabela de preços cadastrada' />
                            :
                            this.state.loading === 0 ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Período</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Status</th>
                                                <th width="90"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.nome}</td>
                                                        <td className="d-none d-sm-table-cell">R${item.valorMaximo}</td>
                                                        <td className="d-none d-sm-table-cell">{item.status === 'true' ? 'Ativo' : 'Inativo'}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {this.props.onDuplicate &&
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                }
                                                                {this.props.onEdit &&
                                                                    <button type="button" onClick={(e) => this.onEdit(item)} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                <button type="button" onClick={(e) => this.onRemove(item)} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </>
        );
    }
}

export { CriarTabela, ListagemTabelas };