import React from 'react'
import { NavBar } from '../../components/navbar'
import firebase from '../../config/firebase';
import NumberFormat from 'react-number-format';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'
import { Loading, EmptyContent } from '../../components/common/commons'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import 'firebase/auth';
import 'firebase/storage';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { getAllParksByClientId, getPark, updateParkById } from '../../store/collections/parkWorker';
import { searchCEP } from '../../shared/cepWorker';
import { getAllLicencasByClienteId } from '../../store/collections/licencaWorker';
import { ListarLicencas } from '../licenca';
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { fetchFunction } from '../../shared/function-request';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import { queryString, reloadWithAlert, reloadWithAlertError, toastWarning } from '../../shared/utils';
import { AlertSecondary } from '../../shared/alert-manager';
import { addDoc, updateDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';

const initialState = {
    msgShowing: 0,
    msgDescription: '',
    estacionamentoId: getEstacionamento()?.id,
    id: null,
    nome: '',
    documento: '',
    tipoDocumento: 'CNPJ',
    horarios: '',
    representante: '',
    celular: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    endereco: {}
};

class CadastrarEstacionamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    reset = () => {
        this.setState({ ...initialState })
    }

    updateEstacionamento = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        const data = {
            nome: this.state.nome,
            documento: this.state.documento,
            tipoDocumento: this.state.tipoDocumento,
            clienteId: getCliente().id,
            representante: this.state.representante,
            celular: this.state.celular,
            horarios: this.state.horarios,
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            isDeleted: false,
        }
        await updateDoc({ collection: Collection.PARK, id: this.state.id, data: data })
        reloadWithAlert("Estacionamento atualizado com sucesso!")
    }

    addEstacionamento = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }

        const data = {
            nome: this.state.nome,
            documento: this.state.documento,
            revendaId: getRevendaIdByPark(),
            tipoDocumento: this.state.tipoDocumento,
            clienteId: getCliente().id,
            representante: this.state.representante,
            horarios: this.state.horarios,
            celular: this.state.celular,
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.PARK, data: data })
        reloadWithAlert("Estacionamento cadastrada com sucesso!")
    }

    fetchCEP = (value) => {
        this.setState({ cep: value })
        if (value.replace(' ', '').length === 9) {
            fetch("https://viacep.com.br/ws/" + value + "/json/").then(res => res.json()).then((result) => {
                this.setState({ cep: result.cep })
                this.setState({ logradouro: result.logradouro })
                this.setState({ complemento: result.complemento })
                this.setState({ bairro: result.bairro })
                this.setState({ cidade: result.localidade })
                this.setState({ estado: result.uf })
            }, (error) => {
            })
        }
    }

    addParametros = async (id) => {
        const data = {
            clienteId: getCliente().id,
            revendaId: getRevendaIdByPark(),
            estacionamentoId: id,
            trocoInicialCaixa: false,
            prismaEstacionamento: false,
            fecharCaixaParcial: false,
            periodoFecharCaixaApartirDeUmHorarioEmHoras: "06:00",
            isDeleted: false,
        }
        await addDoc({ collection: Collection.PARAMETER, data: data })
    }

    addLicenca = async (id) => {
        var today = new Date();
        var nextMonth = new Date();
        nextMonth.setDate(today.getDate() + 30);
        const data = {
            clienteId: getCliente().id,
            revendaId: getRevendaIdByPark(),
            estacionamentoId: id,
            vencimento: nextMonth,
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.LICENCE, data: data })
    }

    onEditPark = (park) => {
        this.setState({ id: park.id })
        this.setState({ nome: park.nome })
        this.setState({ documento: park.documento })
        this.setState({ tipoDocumento: park.tipoDocumento })
        this.setState({ representante: park.representante })
        this.setState({ horarios: park.horarios })
        this.setState({ celular: park.celular })
        this.setState({ email: park.email })
        this.setState({ cep: park.endereco.cep })
        this.setState({ logradouro: park.endereco.logradouro })
        this.setState({ numero: park.endereco.numero })
        this.setState({ bairro: park.endereco.bairro })
        this.setState({ cidade: park.endereco.cidade })
        this.setState({ estado: park.endereco.estado })
        this.setState({ complemento: park.endereco.complemento })
    }

    checkFields = () => {
        return (this.state.nome === '' ||
            this.state.documento === '' ||
            this.state.tipoDocumento === '' ||
            this.state.representante === '' ||
            this.state.celular === '' ||
            this.state.cep === '' ||
            this.state.logradouro === '' ||
            this.state.horarios === '' ||
            this.state.numero === '' ||
            this.state.bairro === '' ||
            this.state.cidade === '' ||
            this.state.estado === '')
    }

    render() {
        return (
            <NavBar>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Cadastrar Estacionamento
                            </div>
                            <form ref='form'  >
                                <div className="card-body">
                                    {
                                        this.state.msgShowing === 1 &&
                                        <div className="alert alert-secondary" role="alert">
                                            {this.state.msgDescription}
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className="col-lg-9">
                                            <label>Representante</label>
                                            <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" required />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>WhatsApp/Telefone</label>
                                            <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <label>Nome do estacionamento</label>
                                            <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" required />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Horários</label>
                                            <input type="text" placeholder="Ex: das 07h às 22h" onChange={(e) => this.setState({ horarios: e.target.value })} value={this.state.horarios} className="form-control" required />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Tipo documento</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                    <option value='CNPJ'>CNPJ</option>
                                                    <option value='CPF'>CPF</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Número documento</label>
                                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <h4 className='my-3'>Endereço</h4>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-3">
                                            <label>CEP</label>
                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Logradouro</label>
                                            <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                                        </div>
                                        <div className="col-lg-2">
                                            <label>Número</label>
                                            <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                                        </div>
                                        <div className="col-lg-4">
                                            <label>Complemento</label>
                                            <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Bairro</label>
                                            <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Cidade</label>
                                            <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Estado</label>
                                            <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                                        </div>
                                    </div>
                                    <button type="button" onClick={(e) => { this.state.id ? this.updateEstacionamento() : this.addEstacionamento() }} className="btn btn-primary my-3">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                                    {
                                        this.state.id ?
                                            <button type="button" onClick={this.reset} className="btn btn-secondary my-3">Cancelar</button>
                                            : null
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <ListarEstacionamentos key={this.state.loading} onEditPark={this.onEditPark} />
                    </div>
                </div>
            </NavBar>
        )
    }
}

class ListarEstacionamentos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentos: [],
            loading: 1
        }
    }

    componentDidMount() {
        const e = queryString("e")
        this.setState({ selectedParkId: e })
        this.carregarEstacionamentos()
    }

    onEditPark = (park) => {
        this.props.onEditPark(park)
    }

    onForceRefresh = (park) => {
        this.props.onForceRefresh(park)
    }

    onShowImportModal = (park) => {
        this.props.onShowImportModal(park)
    }

    onLoadedPark = (parks) => {
        if (this.props.onLoadedPark !== undefined) {
            this.props.onLoadedPark(parks)
        }
    }

    OnDetailButton = (park) => {
        this.props.OnDetailButton(park)
    }

    onShowLicence = (park) => {
        this.props.onShowLicence(park)
    }

    carregarEstacionamentos = async () => {
        this.setState({ loading: 1 })
        const listEstacionamentos = await getAllParksByClientId({ clientId: this.props.clienteId })
        var licences = []
        if (this.props.onShowLicence) {
            licences = await getAllLicencasByClienteId({ clienteId: this.props.clienteId })
        }
        listEstacionamentos.forEach(e => {
            e.isChecked = this.props.selecionados ? this.props.selecionados.filter(item => item.id === e.id).length > 0 : false
            e.isSigner = licences.filter(e => e.estacionamentoId === e.id && e.groupId).length > 0
        })
        this.setState({ estacionamentos: listEstacionamentos });
        this.onLoadedPark(listEstacionamentos)
        this.setState({ loading: 0 })
    }

    onCheckPark = (isChecked, park, index) => {
        park.isChecked = isChecked
        let estacionamentos = this.state.estacionamentos
        estacionamentos[index].isChecked = isChecked
        this.setState({ estacionamentos: estacionamentos })
        this.props.onCheckPark(isChecked, park)
    }

    onSelectPark = async (park) => {
        this.props.onSelectPark(park)
    }

    onSelectBillsAndNotes = async (park) => {
        this.setState({ isOpenFinanceDataModal: true })
        this.setState({ currentPark: park })
        this.setState({ isEnabled: park.finance?.isEnabled === true })
        if (park.finance?.isEnabled == true) {
            this.setState({ razaoSocial: park.finance?.razaoSocial })
            this.setState({ tipoDocumento: park.finance?.tipoDocumento })
            this.setState({ documento: park.finance?.documento })
            this.setState({ representante: park.finance?.representante })
            this.setState({ celular: park.finance?.celular })
            this.setState({ email: park.finance?.email })
            this.setState({ cep: park.finance?.endereco?.cep })
            this.setState({ logradouro: park.finance?.endereco?.logradouro })
            this.setState({ numero: park.finance?.endereco?.numero })
            this.setState({ complemento: park.finance?.endereco?.complemento })
            this.setState({ bairro: park.finance?.endereco?.bairro })
            this.setState({ cidade: park.finance?.endereco?.cidade })
            this.setState({ estado: park.finance?.endereco?.estado })
            this.setState({ ibge: park.endereco?.ibge })
        } else {
            this.setState({ razaoSocial: park.nome })
            this.setState({ tipoDocumento: park.tipoDocumento })
            this.setState({ documento: park.documento })
            this.setState({ representante: park.representante })
            this.setState({ celular: park.celular })
            this.setState({ email: park.email })
            this.setState({ cep: park.endereco?.cep })
            this.setState({ logradouro: park.endereco?.logradouro })
            this.setState({ numero: park.endereco?.numero })
            this.setState({ complemento: park.endereco?.complemento })
            this.setState({ bairro: park.endereco?.bairro })
            this.setState({ cidade: park.endereco?.cidade })
            this.setState({ estado: park.endereco?.estado })
            this.setState({ ibge: park.endereco?.ibge })
        }
    }

    saveFinanceData = async () => {
        var currentPark = this.state.currentPark
        currentPark.finance = {
            isEnabled: this.state.isEnabled,
            razaoSocial: this.state.razaoSocial,
            tipoDocumento: this.state.tipoDocumento,
            documento: this.state.documento,
            representante: this.state.representante,
            celular: this.state.celular,
            email: this.state.email,
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                complemento: this.state.complemento,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                ibge: this.state.ibge
            }
        }
        await updateParkById({ id: currentPark.id, data: currentPark })
        toastWarning("Dados atualizados com sucesso.")
        this.setState({ isOpenFinanceDataModal: false })
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result?.cep) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
            this.setState({ ibge: result.ibge })
        } else {
            this.setState({ cep: null })
            this.setState({ allAddress: "" })
        }
    }

    render() {
        return (
            <>

                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todos os estabelecimentos
                        <div className='btn-group'>
                            {
                                this.props.onShowImportModal &&
                                <button type="button" onClick={() => { this.onShowImportModal() }} className="btn btn-sm btn-success">
                                    <i className="fas fa-plus mx-2" />
                                    Cadastrar novo
                                </button>
                            }
                        </div>
                    </div>
                    {
                        this.state.estacionamentos.length === 0 && this.state.loading === 0 ?
                            <EmptyContent text='Nenhum estacionamento cadastrado' />
                            :
                            this.state.loading === 0 ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Celular</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.estacionamentos.map((estacionamento, index) =>
                                                    <tr key={index} className={estacionamento.id === this.state.selectedParkId && "table-success"}>
                                                        {
                                                            isAdministrador() ?
                                                                <Tooltip title={estacionamento.id} placement='left'>
                                                                    {
                                                                        estacionamento.nome === estacionamento.nomeInterno ?
                                                                            <td>{estacionamento.nomeInterno}</td> :
                                                                            <td>{estacionamento.nome} | {estacionamento.nomeInterno}</td>
                                                                    }
                                                                </Tooltip> :
                                                                estacionamento.nome === estacionamento.nomeInterno ?
                                                                    <td>{estacionamento.nomeInterno}</td> :
                                                                    <td>{estacionamento.nome} | {estacionamento.nomeInterno}</td>
                                                        }
                                                        <td>{estacionamento.celular ?? "Não informado"}</td>
                                                        <td width={10} >
                                                            <div className='btn-group'>
                                                                {
                                                                    this.props.onSelectPark &&
                                                                    <Tooltip title="Selecionar estacionamento" placement='top'>
                                                                        <button type="button" onClick={() => { this.onSelectPark(estacionamento) }} className="btn btn-success">
                                                                            <i className="fas fa-location" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.onForceRefresh &&
                                                                    <Tooltip title="Atualizar tabelas nos aparelhos" placement='top'>
                                                                        <button type="button" onClick={() => { this.onForceRefresh(estacionamento) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-sync text-white" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.onShowLicence && estacionamento.isSigner &&
                                                                    <Tooltip title="Visualizar licença" placement='top'>
                                                                        <button type="button" onClick={() => { this.onShowLicence(estacionamento) }} className="btn btn-success">
                                                                            <i className="fas fa-file-signature" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.onShowSelectBillsAndNotes &&
                                                                    <Tooltip title="Dados financeiros" placement='top'>
                                                                        <button type="button" onClick={() => { this.onSelectBillsAndNotes(estacionamento) }} className="btn btn-success">
                                                                            <i className="fas fa-money-check-alt" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.onEditPark &&
                                                                    <Tooltip title="Editar dados do estabelecimento" placement='top'>
                                                                        <button type="button" onClick={() => { this.onEditPark(estacionamento) }} className="btn btn-secondary">
                                                                            <i className="fas fa-edit" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.ShowCheckButton &&
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" checked={estacionamento.isChecked} onClick={(e) => this.onCheckPark(e.target.checked, estacionamento, index)} type="checkbox" />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.props.OnDetailButton &&
                                                                    <Tooltip title="Mostrar detalhes abaixo" placement='top'>
                                                                        <button type="button" onClick={(e) => this.OnDetailButton(estacionamento)} className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <Loading />
                    }
                    {
                        this.props.ShowCheckButton &&
                        <div className='col-lg-12 '>
                            <div className="alert alert-secondary mx-3" >
                                Selecione os estacionamentos que este usuário pode gerênciar.
                            </div>
                        </div>
                    }
                </div>

                <Dialog maxWidth='sm' fullWidth open={this.state.isOpenFinanceDataModal} onClose={(e) => this.setState({ isOpenFinanceDataModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Dados financeiros
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <AlertSecondary message="Escolha quais dados deseja usar para emissão de notas e envio de cobranças.">
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="isEnabled" checked={!this.state.isEnabled} onChange={(e) => this.setState({ isEnabled: !(e.target.checked) })} type="checkbox" />
                                <label className="form-check-label" htmlFor="isEnabled">
                                    Usar os dados da matriz.
                                </label>
                            </div>
                        </AlertSecondary>
                        {
                            this.state.isEnabled && <>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label>Razão social</label>
                                        <input type="text" onChange={(e) => this.setState({ razaoSocial: e.target.value })} value={this.state.razaoSocial} className="form-control" required />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Tipo documento</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                <option value='CNPJ'>CNPJ</option>
                                                <option value='CPF'>CPF</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Número documento</label>
                                        <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Representante</label>
                                        <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" required />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Telefone</label>
                                        <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>E-mail</label>
                                        <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                    </div>
                                </div>
                                <div className='form-group mt-3'>
                                    <h5>Endereço</h5>
                                </div>
                                <div className='row mt-2'>
                                    <div className="col-lg-3">
                                        <label>CEP</label>
                                        <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                    </div>
                                    <div className="col-lg-9">
                                        <label>Logradouro</label>
                                        <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                                    </div>
                                    <div className="col-lg-5">
                                        <label>Número</label>
                                        <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                                    </div>
                                    <div className="col-lg-7">
                                        <label>Complemento</label>
                                        <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <label>Bairro</label>
                                        <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Cidade</label>
                                        <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Estado</label>
                                        <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                                    </div>
                                </div>
                            </>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isOpenFinanceDataModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={(e) => this.saveFinanceData()} className='btn btn-success'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class EditarEstacionamentoBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    reset = () => {
        this.setState({ ...initialState })
    }

    componentDidMount = async () => {
        const result = await getPark({ id: this.props.estacionamentoId })
        if (result.success) {
            const park = result.data
            this.setState({ id: park.id })
            this.setState({ clienteId: park.clienteId })
            this.setState({ nome: park.nome })
            this.setState({ documento: park.documento })
            this.setState({ tipoDocumento: park.tipoDocumento })
            this.setState({ representante: park.representante })
            this.setState({ nomeInterno: park.nomeInterno })
            this.setState({ horarios: park.horarios })
            this.setState({ celular: park.celular })
            this.setState({ email: park.email })
            this.setState({ cep: park.endereco.cep })
            this.setState({ logradouro: park.endereco.logradouro })
            this.setState({ numero: park.endereco.numero })
            this.setState({ bairro: park.endereco.bairro })
            this.setState({ cidade: park.endereco.cidade })
            this.setState({ estado: park.endereco.estado })
            this.setState({ ibge: park.endereco.ibge })
            this.setState({ complemento: park.endereco.complemento })
        }
    }

    updateEstacionamento = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        const data = {
            nome: this.state.nome,
            nomeInterno: this.state.nomeInterno,
            documento: this.state.documento,
            tipoDocumento: this.state.tipoDocumento,
            clienteId: this.state.clienteId,
            representante: this.state.representante,
            celular: this.state.celular,
            horarios: this.state.horarios,
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                ibge: this.state.ibge,
                complemento: this.state.complemento
            }
        }
        await updateDoc({ collection: Collection.PARK, id: this.state.estacionamentoId, data: data })
        await saveLastUpdate()
        reloadWithAlert("Estacionamento atualizado com sucesso!")
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ ...result })
        }
    }

    checkFields = () => {
        return (this.state.nome === '' ||
            this.state.documento === '' ||
            this.state.tipoDocumento === '' ||
            this.state.representante === '' ||
            this.state.celular === '' ||
            this.state.cep === '' ||
            this.state.logradouro === '' ||
            this.state.horarios === '' ||
            this.state.numero === '' ||
            this.state.bairro === '' ||
            this.state.cidade === '' ||
            this.state.estado === '')
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Atualizar dados do estabelecimento
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-4">
                            <label>Representante</label>
                            <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" required />
                        </div>
                        <div className="col-lg-4">
                            <label>Nome do estabelecimento</label>
                            <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" required />
                        </div>
                        <div className="col-lg-4">
                            <label>Nome interno</label>
                            <input type="text" onChange={(e) => this.setState({ nomeInterno: e.target.value })} value={this.state.nomeInterno} className="form-control" required />
                        </div>
                        <div className="col-lg-3">
                            <label>Telefone</label>
                            <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                        </div>
                        <div className="col-lg-3">
                            <label>Horários</label>
                            <input type="text" placeholder="Ex: das 07h às 22h" onChange={(e) => this.setState({ horarios: e.target.value })} value={this.state.horarios} className="form-control" required />
                        </div>
                        <div className="col-lg-3">
                            <label>Tipo documento</label>
                            <div className="input-group mb-3">
                                <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                    <option value='CNPJ'>CNPJ</option>
                                    <option value='CPF'>CPF</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <label>Número documento</label>
                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group'>
                        <h4 className='my-3'>Endereço</h4>
                    </div>
                    <div className='row'>
                        <div className="col-lg-3">
                            <label>CEP</label>
                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                        </div>
                        <div className="col-lg-3">
                            <label>Logradouro</label>
                            <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                        </div>
                        <div className="col-lg-2">
                            <label>Número</label>
                            <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                        </div>
                        <div className="col-lg-4">
                            <label>Complemento</label>
                            <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-lg-6">
                            <label>Bairro</label>
                            <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                        </div>
                        <div className="col-lg-3">
                            <label>Cidade</label>
                            <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                        </div>
                        <div className="col-lg-3">
                            <label>Estado</label>
                            <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <button type="button" onClick={(e) => { this.updateEstacionamento() }} className="btn btn-primary">Atualizar dados</button>
                </div>
            </div>
        )
    }
}

class EditarEstacionamento extends React.Component {

    render() {
        return (
            <NavBar>
                <EditarEstacionamentoBody estacionamentoId={getEstacionamento()?.id} />
            </NavBar>
        )
    }
}

class VisualizarEstacionamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.estacionamentoId,
            nome: "",
            documento: "",
            tipoDocumento: "",
            representante: "",
            nomeInterno: "",
            horarios: "",
            celular: "",
            email: "",
            cep: "",
            logradouro: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            complemento: "",
            lastUpdateId: "",
            lastUpdateDate: "",
            lastUpdateUser: "",
            isLoading: true,
            isOpenModalLog: false
        }
    }

    componentDidMount = async () => {
        this.loadPark()
        this.loadLastUpdate()
        this.setState({ isLoading: false })
    }

    loadPark = async () => {
        const resultPark = await firebase.firestore().collection('estacionamentos').doc(this.props.estacionamentoId).get()
        const park = { id: resultPark.id, ...resultPark.data() }
        this.setState({ id: park.id })
        this.setState({ nome: park.nome })
        this.setState({ documento: park.documento })
        this.setState({ tipoDocumento: park.tipoDocumento })
        this.setState({ representante: park.representante })
        this.setState({ nomeInterno: park.nomeInterno })
        this.setState({ horarios: park.horarios })
        this.setState({ celular: park.celular })
        this.setState({ email: park.email })
        this.setState({ cep: park.endereco?.cep })
        this.setState({ logradouro: park.endereco?.logradouro })
        this.setState({ numero: park.endereco?.numero })
        this.setState({ bairro: park.endereco?.bairro })
        this.setState({ cidade: park.endereco?.cidade })
        this.setState({ estado: park.endereco?.estado })
        this.setState({ complemento: park.endereco?.complemento })
    }

    openModalLog = () => {
        this.setState({ isOpenModalLog: true })
    }

    loadLastUpdate = async () => {
        const resultLastUpdate = await firebase.firestore().collection('last-update').where("estacionamentoId", "==", this.props.estacionamentoId).get()
        if (!resultLastUpdate.empty) {
            const lastUpdate = { id: resultLastUpdate.docs[0].id, ...resultLastUpdate.docs[0].data() }
            this.setState({ lastUpdateId: lastUpdate.id })
            this.setState({ lastUpdateDate: lastUpdate.lastUpdate })
            this.setState({ lastUpdateUser: lastUpdate.alterBy.name })
        }
    }

    render() {
        return (
            <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Dados do estabelecimento
                </div>
                <div className="card-body">
                    {
                        this.state.isLoading === false ?
                            <>
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <label>Estabelecimento</label>
                                        {
                                            this.state.nome === this.state.nomeInterno ?
                                                <h6>{this.state.nomeInterno}</h6> :
                                                <h6>{this.state.nome} {this.state.nomeInterno}</h6>
                                        }
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Representante</label>
                                        <Tooltip title={this.state.id} placement="left">
                                            <h6>{this.state.representante}</h6>
                                        </Tooltip>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Celular</label>
                                        <h6>{this.state.celular?.replaceAll("_", "") ?? "Não informado"}</h6>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <label>Endereço</label>
                                        <h6>{this.state.logradouro}, {this.state.numero} - {this.state.cep} | {this.state.cidade}</h6>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Última atualização das tabelas</label>
                                        {
                                            this.state.lastUpdateDate !== "" ?
                                                <Tooltip title={this.state.lastUpdateId} placement="left">
                                                    <h6>{Moment(this.state.lastUpdateDate.seconds * 1000).format("DD/MM/YYYY hh:mm:ss")} por {this.state.lastUpdateUser}</h6>
                                                </Tooltip>
                                                : <h6>Nunca</h6>
                                        }
                                    </div>
                                    <div className="col-lg-4">
                                        <label>E-mail</label>
                                        <h6>{this.state.email ?? "Não informado"}</h6>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <ListarLicencas showRemoveLicence={true} estacionamentoId={this.state.id} />
                                    </div>
                                </div>
                            </> :
                            <Loading />
                    }
                </div>
            </div>
        )
    }
}

class AnalyticsPark extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: props.estacionamentoId,
            monthiesAgo: 3,
            isLoading: true,
            analyticsHistory: []
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })
        const result = await this.loadMonthliesAgo(1)
        if (result.success) {
            const analytics = result.data[0]
            this.setState({ analytics: analytics })
            this.setState({ alertClass: this.alertClass(analytics) })
            this.setState({ alertMessageTickets: this.alertMessageTickets(analytics) })
            this.setState({ alertMessageNotes: this.alertMessageNotes(analytics) })
            this.setState({ buttonClass: this.buttonClass(analytics) })
            this.setState({ alertBadge: this.badgeMessage(analytics) })
            this.setState({ isLoading: false })
        }
    }

    loadMonthliesAgo = async (ago) => {
        const option = {
            method: 'POST',
            body: {
                monthiesAgo: ago
            }
        }
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        return await fetchFunction(`/analytics/use`, option, parameter?.X_API_KEY)
    }

    alertClass = (analytics) => {
        const average = analytics?.sum?.average ?? 0
        if (average === "LOW") {
            return "alert alert-success"
        }
        if (average === "MEDIUM") {
            return "alert alert-warning"
        }
        return "alert alert-danger"
    }

    buttonClass = (analytics) => {
        const average = analytics?.sum?.average ?? 0
        if (average === "LOW") {
            return "btn btn-success btn-sm mt-2"
        }
        if (average === "MEDIUM") {
            return "btn btn-warning btn-sm text-white mt-2"
        }
        return "btn btn-danger btn-sm text-white mt-2"
    }

    alertMessageTickets = (analytics) => {
        const percentage = analytics?.sum?.tickets?.percentage ?? 0
        const average = analytics?.sum?.tickets?.average ?? 0
        if (average === "LOW") {
            return `Baixo de ${percentage.toFixed(2)}%`
        }
        if (average === "MEDIUM") {
            return `Médio de ${percentage.toFixed(2)}%`
        }
        if (average === "HIGH") {
            return `Alto de ${percentage.toFixed(2)}%`
        }
        return `${percentage.toFixed(2)}% de tickets acima do esperado do plano atual.`
    }

    alertMessageNotes = (analytics) => {
        const percentage = analytics?.sum?.notes?.percentage ?? 0
        const average = analytics?.sum?.notes?.average ?? 0
        if (average === "LOW") {
            return `Baixo de ${percentage.toFixed(2)}%`
        }
        if (average === "MEDIUM") {
            return `Médio de ${percentage.toFixed(2)}%`
        }
        if (average === "HIGH") {
            return `Alto de ${percentage.toFixed(2)}%`
        }
        return `${percentage.toFixed(2)}% de notas acima do esperado do plano atual.`
    }

    badgeMessage = (analytics) => {
        const average = analytics?.sum?.average ?? 0
        if (average === "LOW") {
            return "badge text-bg-success"
        }
        if (average === "MEDIUM") {
            return "badge text-bg-warning"
        }
        return "badge text-bg-danger"
    }

    showHistory = () => {
        this.setState({ isShowingHistoryAnalytics: true })
        this.loadHistory()
    }

    loadHistory = async () => {
        this.setState({ isLoadingHistory: true })
        const result = await this.loadMonthliesAgo(this.state.monthiesAgo)
        this.setState({ analyticsHistory: result.data[0].analytics })
        this.setState({ isLoadingHistory: false })
    }

    changeMonthiesAgo = (e) => {
        this.setState({ monthiesAgo: parseInt(e.target.value) }, () => {
            this.loadHistory()
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <Loading /> :
                        <div className={this.state.alertClass}>
                            <span className={this.state.alertBadge}>Consumo do plano</span> <br />
                            <strong>{this.state.alertMessageTickets}</strong> <br />
                            <small>{this.state.analytics?.sum?.tickets?.quantity} veículos lançados no mês atual de {this.state.analytics?.plan?.quantity?.vehicles ?? 1500} do plano.</small><br />
                            <strong>{this.state.alertMessageNotes}</strong> <br />
                            <small>{this.state.analytics?.sum?.notes?.quantity} notas geradas no mês atual de {this.state.analytics?.plan?.quantity?.notes ?? 1500} do plano.</small><br />
                            <button type="button" onClick={this.showHistory} className={this.state.buttonClass}>
                                <i className="fas fa-chart-line mx-2" />
                                Visualizar histórico
                            </button>
                        </div>
                }
                <Dialog fullWidth maxWidth={"md"} open={this.state.isShowingHistoryAnalytics}>
                    {
                        this.state.isLoadingHistory && <Loading />
                    }
                    {
                        !this.state.isLoadingHistory && <div>
                            <MuiDialogTitle className='text-center'>
                                Histórico de consumo
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-lg-12'>
                                    <div className='col-lg-4'>
                                        <select className='form-select form-select-sm' value={this.state.monthiesAgo} onChange={this.changeMonthiesAgo}>
                                            <option value="1">Mês atual</option>
                                            <option value="2">2 meses atrás</option>
                                            <option value="3">3 meses atrás</option>
                                            <option value="4">4 meses atrás</option>
                                            <option value="5">5 meses atrás</option>
                                            <option value="6">6 meses atrás</option>
                                            <option value="7">7 meses atrás</option>
                                            <option value="8">8 meses atrás</option>
                                            <option value="9">9 meses atrás</option>
                                            <option value="10">10 meses atrás</option>
                                            <option value="11">11 meses atrás</option>
                                            <option value="12">12 meses atrás</option>
                                        </select>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Tickets</strong></td>
                                                    <td><strong>Notas</strong></td>
                                                    <td align='center'><strong>Período</strong></td>
                                                    <td align='center'><strong>Consumo</strong></td>
                                                </tr>
                                                {
                                                    this.state.analyticsHistory.map((item, index) =>
                                                        <tr key={"history_analytics_" + index}>
                                                            <td>{item.tickets.quantity} tickets de {item.tickets.remaining + item.tickets.quantity}</td>
                                                            <td>{item.notes.quantity} notas de {item.notes.remaining + item.notes.quantity}</td>
                                                            <td align='center'>De {moment(item.startDate).format("DD/MM")} Até {moment(item.endDate).format("DD/MM")}</td>
                                                            <td align='center'>
                                                                {item.tickets.average === "LOW" && <h6><span className="badge text-bg-success text-white w-100">Baixo</span></h6>}
                                                                {item.tickets.average === "MEDIUM" && <h6><span className="badge text-bg-warning text-white w-100">Médio</span></h6>}
                                                                {(item.tickets.average === "HIGH") && <h6><span className="badge text-bg-danger text-white w-100">Alto</span></h6>}
                                                                {(item.tickets.average === "CRITICAL") && <h6><span className="badge text-bg-danger text-white w-100">Crítico</span></h6>}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isShowingHistoryAnalytics: false })} className='btn btn-secondary'>Fechar</button>
                                </div>
                            </MuiDialogActions>
                        </div>
                    }
                </Dialog>
            </div>
        )
    }
}

export { CadastrarEstacionamento, ListarEstacionamentos, EditarEstacionamento, VisualizarEstacionamento, EditarEstacionamentoBody, AnalyticsPark };