
import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFFooterComponent, PDFHeaderComponent } from './pdf-shared-report';
import moment from 'moment';
import { isNullOrEmpty, toCurrency } from '../utils';
import RubikMedium from '../../files/fonts/Rubik/Rubik-Medium.ttf';
import RubikRegular from '../../files/fonts/Rubik/Rubik-Regular.ttf';

Font.register({
    family: 'Rubik-Regular',
    fonts: [
        {
            src: RubikRegular
        }
    ],
})

Font.register({
    family: 'Rubik-Medium',
    fonts: [
        {
            src: RubikMedium
        }
    ],
})

const styles = StyleSheet.create({
    page: {
        padding: 10,
        backgroundColor: '#FFFFFF',
    },
    section: {
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    subSection: {
        fontSize: 15,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    normal: {
        fontSize: 12,
        fontFamily: 'Rubik-Regular',
        marginTop: 5
    },
    small: {
        fontSize: 9
    },
    redColor: {
        color: "#FF0000"
    },
    table: {
        display: 'table',
        width: 'auto',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableColHeader: {
        width: '25%',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        textAlign: "center",
        fontFamily: 'Rubik-Medium',
        borderTopWidth: 0,
        fontSize: 12,
        padding: 5
    },
    tableCol: {
        width: '25%',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        textAlign: "center",
        fontFamily: 'Rubik-Regular',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
        fontSize: 10
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    subtotal: {
        alignSelf: 'flex-end',
    },
    left: {
        flexShrink: 1,
        flexGrow: 2,
    },
    right: {
        padding: 5,
        flexShrink: 1,
        flexGrow: 2,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
    },
});

class CashierReportDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado,
            impressaoCaixaTotais: props.impressaoCaixaTotais,
            impressaoListagemMensalistasCaixas: props.impressaoListagemMensalistasCaixas,
            impressaoTabelasUtilizadasCaixas: props.impressaoTabelasUtilizadasCaixas,
            impressaoConveniosCaixas: props.impressaoConveniosCaixas,
            impressaoDescontosCaixas: props.impressaoDescontosCaixas,
            impressaoMovimentacoesCaixas: props.impressaoMovimentacoesCaixas,
            impressaoRetiradasCaixas: props.impressaoRetiradasCaixas,
            impressaoCancelamentosCaixas: props.impressaoCancelamentosCaixas
        }
    }

    render() {
        return (
            <Document>
                <Page size="A4" wrap={false} style={styles.page} orientation={"landscape"}>
                    <PDFHeaderComponent />
                    <CashierReportHeaderDocument resultado={this.state.resultado} />
                    {
                        this.state.impressaoCaixaTotais && <>
                            <CashierReportTotalsDocument resultado={this.state.resultado} />
                            <CashierReportFormasDePagamentosCaixas resultado={this.state.resultado} />
                        </>
                    }
                    {
                        this.state.impressaoListagemMensalistasCaixas && <CashierReportListagemMensalistasCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoTabelasUtilizadasCaixas && <CashierReportTabelasUtilizadasCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoConveniosCaixas && <CashierReportConveniosCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoDescontosCaixas && <CashierReportDescontosCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoMovimentacoesCaixas && <CashierReportMovimentacoesCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoRetiradasCaixas && <CashierReportRetiradasCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoCancelamentosCaixas && <CashierReportCancelamentosCaixas resultado={this.state.resultado} />
                    }
                    {
                        this.state.impressaoCaixaTotais &&
                        <CashierReportCaixasAnalisados resultado={this.state.resultado} />
                    }
                    <PDFFooterComponent />
                </Page>
            </Document>
        )
    }
}

class CashierReportHeaderDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado,
            quantidadeCaixas: props.resultado.caixas.length,
        }
    }

    render() {
        return (
            <>
                <Text style={[styles.section, { marginTop: 30 }]}>Relatório de caixa</Text>
                {
                    this.state.quantidadeCaixas > 1 ?
                        <Text style={styles.normal}>{moment(this.state.resultado.period.start).format("[De] DD/MM/YYYY")} {moment(this.state.resultado.period.end).format("[até] DD/MM/YYYY")}</Text>
                        : <Text style={styles.normal}>{moment(this.state.resultado.period.start).format("[Data] DD/MM/YYYY")}</Text>
                }
            </>
        )
    }
}

class CashierReportTotalsDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                <View>
                    <Text style={[styles.section, { marginTop: 20 }]}>Faturamento</Text>
                    <View style={[styles.table, { marginTop: 12 }]}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableColHeader, { width: "50%" }]}></Text>
                            <Text style={[styles.tableColHeader, { width: "50%", textAlign: "right" }]}>Valor</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "left" }]}>Estacionamento</Text>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "left" }]}>Mensalistas</Text>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.monthly)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "left" }]}>Produtos e serviços</Text>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.tickets.valorProdutos)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "left" }]}>Convênios</Text>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "right", color: "red" }]}>{toCurrency(this.state.resultado.totais.convenios)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "left" }]}>Descontos manuais</Text>
                            <Text style={[styles.tableCol, { width: "50%", textAlign: "right", color: "red" }]}>{toCurrency(this.state.resultado.totais.descontoManual)}</Text>
                        </View>
                    </View>
                    <Text style={[styles.section, { fontSize: 12, textAlign: "right", color: "green" }]}>Valor bruto: {toCurrency(this.state.resultado.totais.faturamento)}</Text>
                    <Text style={[styles.section, { textAlign: "right", color: "green" }]}>Valor líquido: {toCurrency(this.state.resultado.totais.faturamentoLiquido)}</Text>
                </View>
            </>
        )
    }
}

class CashierReportFormasDePagamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado?.totais?.tickets?.byWayPayment.length > 0 &&
                    <View>
                        <Text style={[styles.section, { marginTop: 20 }]}>Formas de pagamentos</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "50%" }]}>Tipo</Text>
                                <Text style={[styles.tableColHeader, { width: "50%", textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado?.totais?.tickets?.byWayPayment.map((item, index) =>
                                    <View style={styles.tableRow} key={`way_payment_${index}`}>
                                        <Text style={[styles.tableCol, { width: "50%" }]}>{item.wayPayment?.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "50%", textAlign: "right" }]}>{toCurrency(item.total)}</Text>
                                    </View>
                                )
                            }
                        </View>
                    </View>
                }
            </>
        )
    }
}

class CashierReportListagemMensalistasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.mensalistas.length > 0 &&
                    <View>
                        <Text style={[styles.section, { marginTop: 20 }]}>Pagamentos de mensalistas</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColHeader}>Nome</Text>
                                <Text style={styles.tableColHeader}>Plano</Text>
                                <Text style={styles.tableColHeader}>Forma pagamento</Text>
                                <Text style={[styles.tableColHeader, { textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.mensalistas.map((item, index) => (
                                    <View style={styles.tableRow} key={`row_${index}`}>
                                        <Text style={[styles.tableCol, { textAlign: "left" }]}>{item.clienteNome}</Text>
                                        <Text style={styles.tableCol}>{item.plan.nome}</Text>
                                        <Text style={styles.tableCol}>{item.payment?.formaPagamento?.nome ?? "-"}</Text>
                                        <Text style={[styles.tableCol, { textAlign: "right" }]}>{toCurrency(item.payment?.total)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.section, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.monthly)}</Text>
                    </View>
                }
            </>
        )
    }
}

class CashierReportTabelasUtilizadasCaixas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.tabelasUseds.length > 0 &&
                    <View wrap>
                        <Text style={[styles.section, { marginTop: 20 }]}>Tabelas de preços</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColHeader}>Tipo</Text>
                                <Text style={styles.tableColHeader}>Nome</Text>
                                <Text style={styles.tableColHeader}>Vezes usada</Text>
                                <Text style={[styles.tableColHeader, { textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.tabelasUseds.map((item, index) => (
                                    <View style={styles.tableRow} key={`row_${index}`}>
                                        <Text style={styles.tableCol}>{item.tableSelected.resumo.tabelaPreco.tipo.replace("_", " ").toUpperCase()}</Text>
                                        <Text style={styles.tableCol}>{item.tableSelected.resumo.tabelaPreco.nome}</Text>
                                        <Text style={styles.tableCol}>{item.quantityUsed}</Text>
                                        <Text style={[styles.tableCol, { textAlign: "right" }]}>{toCurrency(item.total)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.section, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</Text>
                    </View>
                }
            </>
        );
    }
}

class CashierReportConveniosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.convenios.length > 0 &&
                    <View style={styles.section}>
                        <Text>Convênios</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "33%" }]}>Nome</Text>
                                <Text style={[styles.tableColHeader, { width: "33%" }]}>Vezes usado</Text>
                                <Text style={[styles.tableColHeader, { width: "33%", textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.convenios.map((item, index) => (
                                    <View style={styles.tableRow} key={`convenios_${index}`}>
                                        <Text style={[styles.tableCol, { width: "33%" }]}>{item.convenio.resumo.convenio.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "33%" }]}>{item.quantityUsed}</Text>
                                        <Text style={[styles.tableCol, { width: "33%", textAlign: "right" }]}>{toCurrency(item.total)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.subtotal, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.convenios)}</Text>
                    </View>
                }
            </>
        )
    }
}

class CashierReportDescontosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.descontoManual.length > 0 &&
                    <View style={styles.section}>
                        <Text>Descontos manuais</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Data</Text>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Responsável</Text>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Motivo</Text>
                                <Text style={[styles.tableColHeader, { width: "25%", textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.descontoManual.map((item, index) => (
                                    <View style={styles.tableRow} key={`descontos_manuais_${index}`}>
                                        {console.log(item.descontoManual.discount)}
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{item.descontoManual.user.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{item.descontoManual.reason}</Text>
                                        <Text style={[styles.tableCol, { width: "25%", textAlign: "right", color: "red" }]}>-R$ {item.descontoManual.discount.toFixed(2)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.subtotal, { textAlign: "right" }]}>-R$ {this.state.resultado.totais.descontoManual.toFixed(2)}</Text>
                    </View>
                }
            </>
        )
    }
}

class CashierReportCaixasAnalisados extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                <View style={styles.section}>
                    <Text>Caixas analisados</Text>
                    <View style={[styles.table, { marginTop: 12 }]}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableColHeader, { width: "20%" }]}>Número</Text>
                            <Text style={[styles.tableColHeader, { width: "20%" }]}>Responsável</Text>
                            <Text style={[styles.tableColHeader, { width: "20%" }]}>Abertura</Text>
                            <Text style={[styles.tableColHeader, { width: "20%" }]}>Fechamento</Text>
                            <Text style={[styles.tableColHeader, { width: "20%", textAlign: "right" }]}>Valor</Text>
                        </View>
                        {
                            this.state.resultado.caixas.map((item, index) => (
                                <View style={styles.tableRow} key={`tabelas_usadas_${index}`}>
                                    <Text style={[styles.tableCol, { width: "20%" }]}>{item.sequence}</Text>
                                    <Text style={[styles.tableCol, { width: "20%" }]}>{item.openBy.nome}</Text>
                                    <Text style={[styles.tableCol, { width: "20%" }]}>{moment(item.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                    <Text style={[styles.tableCol, { width: "20%" }]}>{isNullOrEmpty(item.closeAt) ? "Caixa em aberto" : moment(item.closeAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                    <Text style={[styles.tableCol, { width: "20%", textAlign: "right" }]}>{toCurrency(item.totais.faturamento)}</Text>
                                </View>
                            ))
                        }
                    </View>
                    <Text style={[styles.section, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.faturamento)}</Text>
                </View>
            </>
        )
    }
}

class CashierReportMovimentacoesCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.movements.length > 0 &&
                    <View style={styles.section}>
                        <Text>Movimentações</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Data</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Tipo</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Categoria</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Responsável</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Observação</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%", textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.movements.map((item, index) => (
                                    <View style={styles.tableRow} key={`movements_${index}`}>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.type}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.category ?? "-"}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.createdBy.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.comment ?? "-"}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%", textAlign: "right", color: item.type === "SAIDA" ? "red" : "black" }]}>{toCurrency(item.price)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <View style={[styles.subSection, { textAlign: "right" }]}>
                            <Text style={[styles.small, { color: "green" }]}>Entradas +{toCurrency(this.state.resultado.totais.movements.earn)}</Text>
                            <Text style={[styles.small, { color: "red" }]}>Saídas -{toCurrency(this.state.resultado.totais.movements.spends)}</Text>
                            <Text style={[styles.section, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.movements.total)}</Text>
                        </View>
                    </View>
                }
            </>
        )
    }
}

class CashierReportRetiradasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.retiradas.length > 0 &&
                    <View style={styles.section}>
                        <Text>Retiradas ( sangrias )</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Data</Text>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Responsável</Text>
                                <Text style={[styles.tableColHeader, { width: "25%" }]}>Observação</Text>
                                <Text style={[styles.tableColHeader, { width: "25%", textAlign: "right" }]}>Valor</Text>
                            </View>
                            {
                                this.state.resultado.retiradas.map((item, index) => (
                                    <View style={styles.tableRow} key={`retiradas_${index}`}>
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{item.user?.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "25%" }]}>{item.comment ?? "-"}</Text>
                                        <Text style={[styles.tableCol, { width: "25%", textAlign: "right", color: "red" }]}>{toCurrency(item.price)}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.section, { textAlign: "right" }]}>{toCurrency(this.state.resultado.totais.retiradas)}</Text>
                    </View>
                }
            </>
        )
    }
}

class CashierReportCancelamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.ticketsCanceled.length > 0 &&
                    <View style={styles.section}>
                        <Text>Tickets cancelados</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "20%" }]}>Data</Text>
                                <Text style={[styles.tableColHeader, { width: "20%" }]}>Placa</Text>
                                <Text style={[styles.tableColHeader, { width: "20%" }]}>Ticket</Text>
                                <Text style={[styles.tableColHeader, { width: "20%" }]}>Responsável</Text>
                                <Text style={[styles.tableColHeader, { width: "20%" }]}>Observação</Text>
                            </View>
                            {
                                this.state.resultado.ticketsCanceled.map((item, index) => (
                                    <View style={styles.tableRow} key={`retiradas_${index}`}>
                                        <Text style={[styles.tableCol, { width: "20%" }]}>{moment(item.statusLogged.filter(e => e.status === "Cancelado")[0].createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                        <Text style={[styles.tableCol, { width: "20%" }]}>{item.placa ?? "-"}</Text>
                                        <Text style={[styles.tableCol, { width: "20%" }]}>{item.ticket}</Text>
                                        <Text style={[styles.tableCol, { width: "20%" }]}>{item.statusLogged.filter(e => e.status === "Cancelado")[0].createBy.nome}</Text>
                                        <Text style={[styles.tableCol, { width: "20%" }]}>{item.statusLogged.filter(e => e.status === "Cancelado")[0].reason}</Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={[styles.section, { textAlign: "right" }]}>{this.state.resultado.ticketsCanceled.length}</Text>
                    </View>
                }
            </>
        )
    }
}

export { CashierReportDocument }