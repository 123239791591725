import { getEstacionamento, getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { generateXAPIKey } from '../../shared/utils';
import { docsToItem, docsToItems } from '../transform.docs';
import { addDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const addParameter = async ({ clienteId, estacionamentoId, revendaId }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        trocoInicialCaixa: true,
        prismaEstacionamento: false,
        setorEstacionamento: false,
        fecharCaixaParcial: true,
        habilitarCobrancaMensalista: false,
        habilitarTarifaAposVencimento: false,
        tarifaAposVencimenoDias: "1",
        habilitarMensagemInadimplente: false,
        habilitarDescontoAntesDoVencimento: false,
        descontoAntesDoVencimentoDias: "1",
        habilitarAcrecimoAposVencimento: false,
        habilitarObrigatoriedadeModeloCorDoVeiculo: true,
        habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto: true,
        acrecimoAposVencimentoDias: "1",
        habilitarEdicaoAvaliacaoInternaNoTicket: true,
        habilitarEdicaoAvaliacaoExternaNoTicket: true,
        habilitarObrigatoriedadeAvaliacaoExterna: false,
        habilitarImprimirTabelaPrecoNoTicket: false,
        habilitarTicketResumido: false,
        habilitarRetiradaDeCaixaAcima: false,
        obrigarPagamentoEntrada: false,
        habilitarFecharCaixaPorPeriodo: false,
        habilitarMensagemImpressaNoTicket: false,
        mensagemLancamentoTicketLocal: "comeco",
        habilitarMensagemQuandoSelecionadaNoTicket: false,
        habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo: false,
        habilitarDescontoManual: false,
        privadoHabilitarPermitirEnviarSMS: false,
        printCustomImage: false,
        tituloQuandoSelecionadaNoTicketTamanho: "normal",
        mensagemQuandoSelecionadaNoTicketLocal: "comeco",
        periodoFecharCaixaApartirDeUmHorarioEmHoras: "06:00",
        periodoFecharCaixaHoras: "0",
        descontoAntesDoVencimentoPorcentagem: "0",
        mensagemMensalistaInadimplentes: "",
        acrecimoAposVencimentoPorcentagem: "0",
        habilitarControlarVagasQuantidade: 0,
        habilitarEmissaoNotaV2: false,
        revendaId: revendaId ?? getRevenda()?.id,
        X_API_KEY: generateXAPIKey(),
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PARAMETER, data: data })
    return doc.id;
}

export const getParameter = async () => {
    const parkId = getEstacionamento()?.id
    return getParameterByParkId({ estacionamentoId: parkId })
}

export const getAllParametersByClienteId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.PARAMETER, clienteId: clienteId })
}

export const getParameterByParkId = async ({ estacionamentoId }) => {
    if (estacionamentoId) {
        const results = await firebase.firestore().collection(Collection.PARAMETER).where('estacionamentoId', '==', estacionamentoId).get()
        const parameter = docsToItem(results)
        if (!parameter.X_API_KEY) {
            parameter.X_API_KEY = generateXAPIKey()
            await updateParameterById({ id: parameter.id, data: parameter })
        }
        return parameter
    }
    return null
}

export const getParameterByXAPIKey = async ({ X_API_KEY }) => {
    if (X_API_KEY) {
        const results = await firebase.firestore().collection(Collection.PARAMETER).where('X_API_KEY', '==', X_API_KEY).get()
        return docsToItem(results)
    }
    return null
}

export const getAllParameters = async () => {
    const results = await firebase.firestore().collection(Collection.PARAMETER).get()
    return docsToItems(results)
}

export const updateParameterById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PARAMETER, id: id, data: data })
}